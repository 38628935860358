import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyA2_UEpdttC0BsaDVTED3WXiJWWUMIf0Pc",
  authDomain: "indoljpk-a6b75.firebaseapp.com",
  databaseURL: "https://indoljpk-a6b75-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "indoljpk-a6b75",
  storageBucket: "indoljpk-a6b75.appspot.com",
  messagingSenderId: "1032654674695",
  appId: "1:1032654674695:web:163833efd24a483b7103a2", 
  measurementId: "G-E2WT922MVL"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);

