import React, { useContext, useEffect, useRef, useState } from "react";
import { RestaurantContext } from "../../../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../../../contexts/Settings";
import { FoodContext } from "../../../../../../contexts/Food";
import moment from "moment";
import { Flip, toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../../../../../BaseUrl";
import { useTranslation } from "react-i18next";
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings
} from "../../../../../../functions/Functions";
import { UserContext } from "../../../../../../contexts/User";

import { useHistory, useParams } from "react-router-dom";


const SettleOrder = ({
  managerId,
  showSettle,
  setSelectedKot,
  selectedDeptTag,
  cartTotal,
  cartSubTotal,
  selectedParty,
  selectedDeliveryType,
  newOrder,
  cartItems,
  buttonDisabled,
  setButtonDisabled,
  activeItemInOrder,
  setActiveItemInOrder,
  taxType,
  deptCommission,
  foodItem,
  setFoodItem,
  tokenNumber,
  paymentTypeAvailable,
  returnMoneyUsd,
  setReturnMoneyUsd,
  serviceCharges,
  paidMoney,
  setPaidMoney,
  orderDetails,
  totalsettle,
  setTotalSettle,
  theVat,
  theTax,
  setTheVatSettle,
  discount,
  theVatSettle,
  showPriceOfEachOrderItem,
  setOrderDetails,
  setShowSettle,
  setNewSettings,
  deliveryCharges,
  newSettings,
  isSettle,
  setSrbInvoiceNum,
  setFbrInvoiceNum,
  fbrInvoiceNum,
  srbInvoiceNum,
  handleLocalPrint,
  customer,
  staffSearch,
  token_No
}) => {
  const history = useHistory();
  const { order_id } = useParams();
  const { t } = useTranslation();
  const { workPeriodListForBranch,setWorkPeriodListForBranch } =  useContext(RestaurantContext);
  const { propertyGroupForSearch, getFoodGroupForPOS } = useContext(FoodContext);
  const {
    //common
    loading,
    setLoading,
    generalSettings,
    // showManageStock,
  } = useContext(SettingsContext);
  const { authUserInfo, adminStaffForSearch,getCustomer } = useContext(UserContext);
 
  //vat
  const [theTaxSettle, setTheTaxSettle] = useState(0);
  // tax difference
  const [taxDiff,setTaxDiff] = useState(false);
  const cardAdd = useRef();
  const [cardNumber,setCardNumber] = useState({
    first: "",
    middle:"******",
    last:""
  });
  const inputRefs = {
    first: useRef(),
    middle: useRef(),
    last: useRef(),
    paid: useRef(),
  };

   // check internet
   let networkStatus = true;

   // check internet connection
 const checkNetworkStatus = async () => {
   try {
     const currencyUrl = BASE_URL + "/check-net";
     const response = await axios.get(currencyUrl,{
      timeout: 3000, 
    });
     if(response.data == 1){
      networkStatus = true
     }else{
      networkStatus = false
     }
   } catch (error) {
     networkStatus = false;
   }
 }

  const handleCardSubmit = (e) =>{
    e.preventDefault();

    if(cardNumber.first?.length < 6){
     toast.error("Please enter valid card number");
     return false
    }else if(cardNumber.last?.length < 4){
      toast.error("Please enter valid card number");
      return false
    }
    console.log("abcd44",)
    const cardNumberFinal = cardNumber.first + cardNumber.middle + cardNumber.last;
    setOrderDetails({
      ...orderDetails,
      card_number_settle: cardNumberFinal
    })
    
    cardAdd.current.classList.remove("showing");
  }
  
  const handleCardChange = (part, value) => {
    const numericValue = value.replace(/\D/g, '');
    setCardNumber((prev) => ({
      ...prev,
      [part]: numericValue,
    }));
   
    if(part === "first" && value?.length === 6){
      inputRefs.last.current.focus();
     }
  };

  useEffect(()=>{
    if(showSettle){
      inputRefs.paid.current.focus();
    }
  },[showSettle])

  // LOCAL FUNCTIONS
  function bracketReplace(id) {
    let property_group_id = id.replace(/[\[\]']+/g, "");
    return property_group_id;
  }

  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  //payment type pos 2
  const handleSetpaymentTypeSingle = (payment_type) => {
    let b_tax = orderDetails.branch?.payment_tax[payment_type.name];
    if(payment_type?.view_card === 1){
      cardAdd.current.classList += " showing";
      inputRefs.first.current.focus();
    }

    let inclusive_vat = 0;
    let previous_amount = 0;
    let new_amount = 0;
    let check = 0;
    let totalSettle2 = parseFloat(cartTotal);
    if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 || orderDetails.branch.fbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
      totalSettle2 += 1
    }
   
    if (b_tax && b_tax >= 0) {
      setTheTaxSettle(b_tax);
      let vatCalculate = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after" ? (parseFloat(cartSubTotal) - discount) : parseFloat(cartSubTotal);
      inclusive_vat = vatCalculate * (parseFloat(b_tax) / 100);
      previous_amount = parseFloat(cartTotal) - parseFloat(theVat);

      new_amount = previous_amount + inclusive_vat;
      if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 || orderDetails.branch.fbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
        new_amount += 1
      }
      totalSettle2 = new_amount;

      setTheVatSettle(parseFloat(inclusive_vat.toFixed(2)));
      // setTotalSettle(Math.round(new_amount), 2);
      // setPaidMoney(Math.round(new_amount), 2);
      setTotalSettle(parseFloat(new_amount.toFixed(2)));
      setPaidMoney(parseFloat(new_amount.toFixed(2)));
      check = 1;
    }

    if(taxType === "inclusive" && !isNaN(b_tax) && orderDetails.branch?.branch_tax !== b_tax){
      setTaxDiff(true);
    }else{
      setTaxDiff(false);
    }

    console.log(
      "st",
      "tax",
      theVat,
      "TB",
      cartTotal,
      "new tax",
      b_tax,
      "inc",
      inclusive_vat,
      "prev",
      previous_amount,
      "new",
      new_amount
    );

    //setTotalPayable(50);
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let theUsdPaid = (new_amount ? new_amount : paidMoney)  / localCurrency?.rate;
  
    if (check === 0) {
      if (parseFloat(theUsdPaid.toFixed(2)) < parseFloat(totalSettle2.toFixed(2))) {
        setReturnMoneyUsd(0);
        toast.error(
          "Please enter paid amount atleast equal to the total bill amount",
          toastObj
        );
        setTheVatSettle(theVat);
        setTotalSettle(parseFloat(totalSettle2.toFixed(2)));
        setOrderDetails({
          ...orderDetails,
          payment_type: [payment_type],
        });
      } else {
        setTheVatSettle(theVat);
        // setTotalSettle(Math.round(totalSettle2), 2);
        // setPaidMoney(Math.round(totalSettle2), 2);
        setTotalSettle(parseFloat(totalSettle2.toFixed(2)));
        //setPaidMoney(parseFloat(totalSettle2.toFixed(2)));
        setOrderDetails({
          ...orderDetails,
          payment_type: [payment_type],
        });
        let theReturnMoney = theUsdPaid - totalSettle2;
        setReturnMoneyUsd(theReturnMoney);
      }
    } else {
      setOrderDetails({
        ...orderDetails,
        payment_type: [payment_type],
      });
      let theReturnMoney = parseFloat(theUsdPaid.toFixed(2)) - parseFloat(totalSettle2.toFixed(2));
      setReturnMoneyUsd(theReturnMoney);
    }
  };
  
  // useEffect(() => {
  //     if(paidMoney > 0 && orderDetails.payment_type && orderDetails.payment_type?.length > 0){
  //      if(paidMoney < totalsettle){

  //      }else{
  //       console.log("abcd44",paidMoney,totalsettle)
  //        let theReturnMoney = paidMoney - totalsettle;
  //        setReturnMoneyUsd(theReturnMoney);
  //      }
  //     }
  // }, [paidMoney]);

  //handle settle order
  const handleSettleOrder = (e) => {
    e.preventDefault();
    // const staffWithCallCenter = adminStaffForSearch && adminStaffForSearch.find(staff => staff.user_type === "call_center");
    // if (staffWithCallCenter) {
    //   staffSearch.current = true;
    // }
   if (
     staffSearch.current &&
     (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") &&
     selectedParty?.for_cc
   ) {
     toast.error(`${_t(t("Invalid party for staff user"))}`, toastObj);
     return false;
   }

   if(orderDetails?.payment_type && orderDetails.payment_type[0]?.view_card === 1 && (orderDetails.card_number_settle === null || orderDetails.card_number_settle ==="")){
    toast.error(`${_t(t("Please Enter Card Number"))}`, toastObj);
    cardAdd.current.classList += "showing";
    return false;
   }
   
   if (
    (!orderDetails.token?.id) || typeof(orderDetails.token) !== 'object'
  ) {
    tokenNumber();
    return false;
  }

   if (
    // authUserInfo.details.user_type === "call_center" &&
     selectedParty?.name.match(/food(.*)panda/i) &&
     parseInt(selectedParty.show_ref_no) === 1 &&
     customer.refNo?.length < selectedParty.ref_min && customer.is_confirm
  ) {
    toast.error(`${_t(t(`Please enter ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits`))}`, toastObj);
    return false;
  }
 
    if (newOrder && newOrder.length > 0) {

      if(isNaN(totalsettle)){
        toast.error(`${_t(_t("Total amount can't be NaN"))}`, toastObj);
        return false;
      }

      if (paidMoney < totalsettle) {
        toast.error(
          "Please enter paid amount atleast equal to the total bill amount",
          toastObj
        );
      } else {
        if (orderDetails.payment_type === null) {
          toast.error("Please Select The Payment Type", toastObj);
        } else {
          setNewSettings({
            ...newSettings,
            vat:
              theTax > 0 ? theTax : orderDetails.branch.branch_tax,
          });
          setButtonDisabled(true);
          setReturnMoneyUsd(paidMoney - totalsettle)
          axiosRequestForSettle();
        }
      }
    } else {
      toast.error("Please add items in order list", toastObj);
    }
  };

  // sync order
const syncSettleToLocalOffline = async (url) => {
  let local = JSON.parse(localStorage.getItem("ordersettle"));
  if (local != null) {
    for (let i = 0; i < local.length; i++) {
      (function (i) {
        setTimeout(async () => {
          await new Promise(resolve => setTimeout(resolve, i * 3000));
          try {
            await createSettleOrder(url, local[i]);
          } catch (error) {
            console.error("Error:", error);
          }
        }, 7000);
      })(i);
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    localStorage.removeItem("ordersettle");
  }
};


  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };
  const saveOrderToLocalOffline = (formData) => {
    formData.local = true;
    let localData = getDataFromLocal("ordersettle");
    if (localData != null) {
      localData.push(formData);
      setDataToLocal("ordersettle", localData);
    } else {
      let data = [];
      data.push(formData);
      setDataToLocal("ordersettle", data);
    }
    setLoading(false);
  };

  const createSettleOrder = (url, formData) => {
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(async (res) => {
        if(res.data === "exists"){
          toast.error(`${_t(_t("Order already settle, You have click settle button again & again"))}`, toastObj);
          setSelectedKot({});
          history.push('/dashboard/pos');
          setButtonDisabled(false);
          setLoading(false);
          return false
        }
        if (formData.local) {
          handleCreateSettleOrder(res, formData.local);
        } else {
          handleCreateSettleOrder(res);
        }
      })
      .catch((error) => {
        if (!networkStatus && parseInt(getSystemSettings(generalSettings, "offline_enable")) === 1) {
          let formLocal = formData?.local ? formData?.local : false;
          saveOrderToLocalOffline(formData);
          setLoading(false);
          let newRes = {};
          // let workPeriod = await getWorkPeriod();
          let workperiod = [];
          workperiod.push(workPeriodListForBranch);
          newRes.data = workperiod;
          handleCreateSettleOrder(newRes,formLocal);
          toast.success(`${_t(t("Order Settle Saved To Local"))}`, toastObj);
        } else {
          
          let data = [];
          data.push(workPeriodListForBranch);
          //set work period
          let theWorkPeriod = null;
          if (
            orderDetails.branch !== undefined &&
            orderDetails.branch !== null &&
            authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager"
          ) {
            theWorkPeriod =
              data[0] &&
              data[0].find((tempWorkPeriod) => {
                return (
                  (orderDetails.branch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null
                );
              });
          } else {
            theWorkPeriod = data && data[0][0];
          }
          setTimeout(() => {
            setNewSettings({
              ...newSettings,
              vat: orderDetails.branch.branch_tax,
              workPeriod:
                theWorkPeriod !== undefined ? theWorkPeriod : null,
            });
          }, 500);
          setLoading(false);
          if(networkStatus){
            toast.error(`${_t(_t("Please contact support"))}`, toastObj);
          }
          else{
            toast.error(`${_t(_t("Please check your internet connection"))}`, toastObj);
          }
          setButtonDisabled(false);
        }
      });
  };
  
  //axios request for settlement
  const axiosRequestForSettle = async() => {
    let api =  order_id ? "/settings/settle-submitted-order" : "/settings/settle-order" ;
    let url = BASE_URL + api;
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
     await checkNetworkStatus();
   
    if (networkStatus) {
      syncSettleToLocalOffline(url);
    }
    let partyFinal = {
      partyId: selectedParty && selectedParty.id,
      partyName: selectedParty && selectedParty.name,
      partyPercent: selectedParty && selectedParty.receivable_amount,
      partyRefNo: selectedParty && selectedParty.show_ref_no,
     }
  
     let orderTypeFinal = {
      orderTypeId: selectedDeliveryType && selectedDeliveryType.id,
      orderTypeName: selectedDeliveryType && selectedDeliveryType.name,
    }

    if (
      (!orderTypeFinal && !orderTypeFinal?.orderTypeId) ||
      (!partyFinal && !partyFinal?.partyId)
    ) {
      let error = "";
      if (
        (!selectedParty || !selectedParty.id) ||
        (!partyFinal && !partyFinal.partyId)
      )
        error = "party";
      if (
        (!selectedDeliveryType || !selectedDeliveryType.id) ||
        (!orderTypeFinal && !orderTypeFinal?.orderTypeId)
      )
        error = "delivery type";
      if (error) {
        toast.error(`Please select ${error} first.`);
        return false
      }
    }
    
    // let formData;
    // if(order_id){
    //   formData = {
    //     localCurrency: localCurrency,
    //     order_group_id: parseInt(order_id),
    //     paidMoney: paidMoney,
    //     payment_type:orderDetails.payment_type,
    //     tax_percent: theTaxSettle > 0 ? theTaxSettle : theTax,
    //     total_payable: totalsettle,
    //     vat: theVatSettle > 0 ? theVatSettle : theVat,
    //   }
     
    // }else{
    // }
    let formData = {
     branch: orderDetails.branch,
     customer: orderDetails.customer,
     manager_id: managerId ? managerId : null,
     card_number: orderDetails.card_number,
     payment_card_number: orderDetails.card_number_settle,
     table: orderDetails.table,
     waiter: orderDetails.waiter,
     dept_tag: selectedDeptTag,
     payment_type: orderDetails.payment_type,
     payment_amount: orderDetails.payment_amount,
     newCustomer: orderDetails.newCustomer ||
     (orderDetails.customer && !orderDetails.customer?.id) ? 1 : 0,
     newCustomerInfo: orderDetails.newCustomerInfo,
     note_to_rider: orderDetails.note_to_rider,
     token: orderDetails.token,
     total_guest: orderDetails.total_guest,
     orderItems: newOrder,
     serviceCharge: deliveryCharges,
     scheme: orderDetails.scheme ? {
       ...orderDetails.scheme,
       scheme_id: orderDetails?.scheme && orderDetails.scheme.scheme_id
         ? orderDetails.scheme.scheme_id
         : null,
       discount_amount: discount ? discount : 0,
     } : null,
     discount: orderDetails.discount,
     orderTypeDetails: orderTypeFinal?.orderTypeId ? orderTypeFinal : orderDetails.orderTypeDetails,
     partyDetails: partyFinal?.partyId ? partyFinal : orderDetails.partyDetails,
     service_amount: serviceCharges,
     subTotal: cartSubTotal,
     totalPayable: totalsettle,
     paidMoney: paidMoney,
     theVat: theVatSettle > 0 ? theVatSettle : theVat,
     tax_percent: theTaxSettle > 0 ? theTaxSettle : theTax,
     dept_commission: deptCommission,
     localCurrency: localCurrency,
     workPeriod: newSettings.workPeriod,
   };
    
    setLoading(true);
    createSettleOrder(url,formData)
    // axios
    // .post(url, formData, {
    //   headers: { Authorization: `Bearer ${getCookie()}` },
    // })
    // .then((res) => {
    //   if (res.data !== "ended") {
    //     if (res.data !== "paymentIssue") {
    //       setTheVat(0);
    //       setIsSettle(1);
    //       setOrderDetails({
    //         ...orderDetails,
    //         token : {
    //           ...orderDetails.token,
    //           id :res.data[1]
    //         }
    //       })
    //       setFbrInvoiceNum(res.data[2]);
    //       setSrbInvoiceNum(res.data[3]);
    //       getFoodGroup();
    //       //setCustomerForSearch(res.data[0][1]);
    //       // setWorkPeriodList(res.data[0][0]);
    //       res.data[0][1] && handleLocalPrint(false);

    //       setWorkPeriodListForBranch(res.data[0][1]);
              
    //         // let tempCustomers =
    //         //   res.data[0][1] !== null &&
    //         //   res.data[0][1].filter((eachCustomer) => {
    //           //     return (
    //             //       parseInt(eachCustomer.branch_id) === orderDetails.branch.id
    //             //     );
    //             //   });
    //             // setGetTable({
    //               //   ...orderDetailUsers,
    //               //   theCustomers: tempCustomers,
    //               // });
    //               //set work period
    //               let theWorkPeriod = null;
    //               if (
    //                 orderDetails.branch !== undefined &&
    //                 orderDetails.branch !== null &&
    //                 authUserInfo.details.user_type !== "staff"
    //                 ) {
    //           theWorkPeriod =
    //             res.data[0][1] &&
    //             res.data[0][1].filter((tempWorkPeriod) => {
    //               return (
    //                 orderDetails.branch.id ===
    //                 parseInt(tempWorkPeriod.branch_id)
    //               );
    //             });
    //         } else {
    //           theWorkPeriod = res.data[0][1];
    //         }

    //         //set work period here
    //         setNewSettings({
    //           ...newSettings,
    //           // vat:
    //           //   generalSettings &&
    //           //   getSystemSettings(generalSettings, "type_vat"),
    //           vat:
    //             theTax > 0 ? theTax : orderDetails.branch.branch_tax,
    //           workPeriod:
    //             theWorkPeriod[0] !== undefined ? theWorkPeriod[0] : null,
    //         });
    //         //setShowSettle(false);
    //           // customer api hit if customer change 
    //         if(customerApi && res.data[0][1]){
    //           getCustomer();
    //         }
            //set token
            //  if(!order_id){
            //    if (localStorage.getItem("order_id")) {
            //      localStorage.setItem(
            //        "order_id",
            //        parseInt(localStorage.getItem("order_id")) + 1
            //      );
            //    } else {
            //      localStorage.setItem("order_id", newSettings.workPeriod.token);
            //    }
            //  }
            //  if(selectedDeliveryType && (selectedDeliveryType.name.match(/dine(.*)in/i))){
            //   getTableWithStatus1();
            // }
            // setOrderDetails({
            //   ...orderDetails,
            //   payment_type: null,
            // });
            //toast.success("Order has been settled successfully.", toastObj);
            
            //setLoading(false)
      //     } else {
      //       setButtonDisabled(false);
      //       setLoading(false);
      //       toast.error(
      //         "Please enter paid amount atleast equal to the total bill amount",
      //         toastObj
      //       );
      //     }
      //   } else {
      //     setButtonDisabled(false);
      //     setLoading(false);
      //     toast.error("Please restart the work period", toastObj);
      //   }
      // })
      
  };

// save to local if offline
  const handleCreateSettleOrder =(res, local = false)=>{
    if (res.data !== "ended") {
      if (res.data !== "paymentIssue") {
        isSettle.current = 1;
        // setFbrInvoiceNum(res.data[2]);
        // setSrbInvoiceNum(res.data[3]);
        fbrInvoiceNum.current = res.data[2];
        srbInvoiceNum.current = res.data[3];
        getFoodGroupForPOS();
        //getFoodGroup();
        if(local == false){
          let formatTime = moment(res.data[5]).format('DD-MMM-YYYY hh:mm A');
          token_No.current = parseInt(getSystemSettings(generalSettings, "order_no")) === 1 ? {id:res.data[4] ? res.data[4] : orderDetails.token?.id, time:formatTime} : {id:res.data[1] ? res.data[1] : orderDetails.token?.id, time:formatTime};
          setOrderDetails({
            ...orderDetails,
            token : {
              ...orderDetails.token,
              id: res.data[1] ? res.data[1] : orderDetails.token?.id,
            },
            order_No : res.data[4] ? res.data[4] : orderDetails.token?.id,
          })
        }
        //setCustomerForSearch(res.data[0][1]);
        // setWorkPeriodList(res.data[0][0]);
        setWorkPeriodListForBranch(res.data[0]);
        if(res.data[0] && local == false){
          handleLocalPrint(local)
        };
          
        //set work period
        let theWorkPeriod = null;
        if (
          orderDetails.branch !== undefined &&
          orderDetails.branch !== null &&
          authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager"
          ) {
         theWorkPeriod =
          res.data[0] &&
          res.data[0].find((tempWorkPeriod) => {
            return (
              orderDetails.branch.id ===
              parseInt(tempWorkPeriod.branch_id) && tempWorkPeriod.ended_at === null
            );
          });
          } else {
            theWorkPeriod = res.data[0][0];
          }

          //set work period here
          setNewSettings({
            ...newSettings,
            vat:
              theTax > 0 ? theTax : orderDetails.branch.branch_tax,
            workPeriod:
              theWorkPeriod !== undefined ? theWorkPeriod : null,
          });
          // if(customerApi && res.data[0]){
          //   getCustomer();
          // }
          //  if(selectedDeliveryType && (selectedDeliveryType.name.match(/dine(.*)in/i))){
          //   getTableWithStatus1();
          // }
          // setOrderDetails({
          //   ...orderDetails,
          //   payment_type: null,
          // });
          //toast.success("Order has been settled successfully.", toastObj);
          setCardNumber({
            first:"",
            middle:"******",
            last:""
          })
          
          setLoading(false)
          //check sales limit target
          // if(authUserInfo.details.user_type === "staff" && authUserInfo?.details?.sales_limit > 0){
          //   if(res?.data[6] > authUserInfo?.details?.sales_limit){
          //     toast.success('Congratulations🎉, you have achieved your target!', {
          //       position: "top-left",
          //       autoClose: 7000,
          //       hideProgressBar: false,
          //       closeOnClick: true,
          //       pauseOnHover: true,
          //       hideProgressBar: true,
          //       // draggable: true,
          //       // progress: undefined,
          //       transition: Flip,
          //       style:{backgroundColor:"#ed8153"}
          //       });
          //   }
          // }
      } else {
        setLoading(false);
        setButtonDisabled(false);
        toast.error(
          `${_t(
            t(
              "Please enter paid amount atleast equal to the total bill amount"
            )
          )}`,
          toastObj
        );
      }
    } else {
      setLoading(false);
      setButtonDisabled(false);
      toast.error(`${_t(t("Please restart the work period"))}`, toastObj);
    }
    
  }


  return (
    <>
      {/* show settle  */}
      <div
        className={`fk-settle-container d-flex flex-column justify-content-center ${
          showSettle ? "" : "d-none"
        }`}
      >
      <div className={`${(buttonDisabled) && "loadingBlur"}`}></div>
      <div className={`${(buttonDisabled) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      
        <div className="fk-settle">
          <div className="container-fluid">
            <div className="row gx-3">
              <div className="col-lg-6 d-none d-lg-block">
                <div className="d-flex justify-content-between px-2">
                <div className="sm-text d-block text-capitalize font-weight-bold py-3">
                 New Order
                </div>
                <div className="sm-text d-block text-capitalize font-weight-bold py-3">
                  {orderDetails && orderDetails?.branch?.name}
                </div>
                </div>
                <div
                  className="fk-settle__products d-flex flex-column"
                  data-simplebar
                >
                  <div className="container-fluid">
                    <div className="row gx-3">
                      <div className="col-12">
                        {/* POS Product list will be here  */}
                        <div className="fk-price-table__body t-mt-10">
                          <div className="fk-price-table__body-top">
                            <div className="fk-table">
                            <div className="d-flex justify-content-between px-3 fk-addons-table__head">
                            <div className="mr-3">
                            {_t(t("order token"))}: #
                            {orderDetails?.token &&
                              orderDetails.token?.id}
                             </div>
                             <div>
                              {
                                `${selectedDeliveryType && selectedDeliveryType?.name} - ${selectedParty && selectedParty?.name}`
                              }
                             </div>
                             </div>
                              <div className="fk-table__head">
                                <div className="row g-0 border">
                                  <div className="col-1 text-center border-right">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"S/L"}
                                    </span>
                                  </div>
                                  <div className="col-6 text-center border-right">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"food item"}
                                    </span>
                                  </div>
                                  <div className="col-2 text-center border-right">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"QTY"}
                                    </span>
                                  </div>
                                  <div className="col-3 text-center">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"price"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="t-pb-30">
                                <div className="sky-is-blue reverse-this">
                                  {/* loop through order list items */}
                                  {cartItems &&
                                  Object.keys(cartItems)?.length > 0 ? (
                                    Object.values(cartItems).map(
                                      (orderListItem, orderListItemIndex) => {
                                        console.log(
                                          "21 order",
                                          orderListItem,
                                          orderListItemIndex
                                        );
                                        return (
                                          <>
                                            <div
                                              className={`fk-table-container-order ${
                                                orderListItemIndex ===
                                                  activeItemInOrder && "active"
                                              } `}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                //orderListItem's group wise all items
                                                // let tempItems =
                                                //   foodForPOS &&
                                                //   foodForPOS.filter(
                                                //     (tempItem) => {
                                                //       return (
                                                //         tempItem.food_group_id ===
                                                //         orderListItem.item
                                                //           .food_group_id
                                                //       );
                                                //     }
                                                //   );

                                                //orderListItem's group
                                                // let foodGroup =
                                                //   showFilterFoodGroupsByDept &&
                                                //   showFilterFoodGroupsByDept.find(
                                                //     (groupItem) => {
                                                //       return (
                                                //         groupItem.id ===
                                                //         parseInt(
                                                //           orderListItem.item
                                                //             .food_group_id
                                                //         )
                                                //       );
                                                //     }
                                                //   );

                                                // // selected pos item
                                                // let selectedItemTemp =
                                                //   tempItems &&
                                                //   tempItems.find(
                                                //     (tempSelectedItem) => {
                                                //       return (
                                                //         tempSelectedItem.id ===
                                                //         orderListItem.item.id
                                                //       );
                                                //     }
                                                //   );

                                                // // Set variations, properties, selected item
                                                // setFoodItem({
                                                //   ...foodItem,
                                                //   foodGroup: foodGroup,
                                                //   items: tempItems,
                                                //   selectedItem:
                                                //     selectedItemTemp,
                                                //   variations:
                                                //     selectedItemTemp &&
                                                //     parseInt(
                                                //       selectedItemTemp.has_variation
                                                //     ) === 1
                                                //       ? selectedItemTemp.variations
                                                //       : null,

                                                //   properties:
                                                //     selectedItemTemp &&
                                                //     parseInt(
                                                //       selectedItemTemp.has_property
                                                //     ) === 1
                                                //       ? selectedItemTemp.properties
                                                //       : null,
                                                // });

                                                //set active order list index for background color of selected
                                                setActiveItemInOrder(
                                                  orderListItemIndex
                                                );
                                              }}
                                            >
                                              <div
                                                className={`row g-0 border-top-0 border-bottom `}
                                              >
                                                <div className="col-1 text-center border-left d-flex justify-content-center align-items-center">
                                                  {newOrder.length -
                                                    orderListItemIndex}
                                                </div>
                                                <div
                                                  className={`col-6 border-left border-right py-2`}
                                                >
                                                  <div className="d-flex justify-content-between">
                                                    <span className="text-capitalize d-block t-pt-5 t-pb-5 t-pl-5 t-pr-5 sm-text font-weight-bold t-mr-8">
                                                      {orderListItem.item.name}
                                                    </span>
                                                  </div>
                                                  <div className="row g-0">
                                                    {/* if item has variations show the selected in order list */}
                                                    {parseInt(
                                                      orderListItem.item
                                                        .has_variation
                                                    ) === 1 && (
                                                      <div className="col-12">
                                                        <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                        variation :
                                                        </span>
                                                        <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md">
                                                        {orderListItem.variation &&
                                                          orderListItem
                                                            .variation
                                                            .variation_name
                                                            ? orderListItem
                                                            .variation
                                                            .variation_name
                                                            : "-"}
                                                        </span>
                                                      </div>
                                                    )}
                                                    {/* if item has properties show the selected in order list, loop here  */ }
                                                    {orderListItem.properties &&
                                                      Object.values(orderListItem.properties)
                                                        .length > 0 &&
                                                      orderListItem.properties !==
                                                        undefined &&
                                                      orderListItem.item.property_groups
                                                      ?.map((thisIsGroup) => {
                                                        
                                                        let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return thisIsGroup === (theItem?.id) ;
                                                            
                                                          }
                                                          );
                                                          
                                                          let propertWithGroup = Object.keys(orderListItem.properties).includes(thisIsGroup.toString()) && Object.values(orderListItem.properties[thisIsGroup]);
                                                          //let IsGroup = Object.keys(orderListItem.properties);; 
                                                          // let abc = propertWithGroup && propertWithGroup?.forEach((property)=>{
                                                          //   const propertyGroup =  parseInt(bracketReplace(
                                                          //     property
                                                          //         .item
                                                          //         .property_group_id
                                                          //         )
                                                          //         )
                                                          //   if(!(IsGroup.includes(propertyGroup))){
                                                          //     IsGroup.push(propertyGroup)
                                                              
                                                          //   }
                                                          // })

                                                        return (
                                                          <div className="col-12">
                                                          {
                                                            //IsGroup && IsGroup?.length > 0 && IsGroup.includes(theGroup?.id.toString()) ?
                                                            propertWithGroup && theGroup &&
                                                            <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                              {theGroup &&
                                                                theGroup.name + " :"}
                                                               
                                                              </span>
                                                              // :
                                                              // ""
                                                          }
                                                            {propertWithGroup && propertWithGroup?.map(
                                                              (
                                                                propertyName
                                                              ) => {
                                                                console.log(
                                                                  "15 here",
                                                                  propertyName
                                                                );
                                                                
                                                                if (
                                                                  orderListItem.properties &&
                                                                  parseInt(
                                                                    bracketReplace(
                                                                      propertyName
                                                                        .item
                                                                        .property_group_id
                                                                    )
                                                                  ) ===
                                                                    theGroup?.id
                                                                ) {
                                                                  return (
                                                                    <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md mr-1">
                                                                      {
                                                                        propertyName
                                                                          .item
                                                                          .name
                                                                      }{" "}
                                                                      <span>
                                                                        {" "}
                                                                        {propertyName.quantity >
                                                                          1 &&
                                                                          "(" +
                                                                            propertyName.quantity +
                                                                            ")"}
                                                                      </span>
                                                                    </span>
                                                                  );
                                                                }
                                                                else {
                                                                  return true;
                                                                }
                                                              }
                                                            )}
                                                          </div>
                                                        );
                                                      })}

                                                    {/* if item has properties show the selected in order list  */}
                                                  </div>
                                                </div>
                                                {/* Quantity */}
                                                <div className="col-2 text-center border-right d-flex justify-content-center align-items-center">
                                                  <div className="fk-qty t-pt-5 t-pb-5 justify-content-center">
                                                    <input
                                                      type="text"
                                                      value={
                                                        orderListItem.quantity
                                                      }
                                                      className="fk-qty__input t-bg-clear"
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>
                                                {/* Quantity */}

                                                {/* Price */}
                                                <div className="col-3 text-center border-right d-flex justify-content-center align-items-center">
                                                  <div className="text-capitalize sm-text font-weight-bold t-pt-5 t-pb-5">
                                                    {console.log(orderListItem)}
                                                    {parseInt(
                                                      orderListItem.item
                                                        .has_variation
                                                    ) === 1 ? (
                                                      <>
                                                        {currencySymbolLeft()}

                                                        {showPriceOfEachOrderItem(
                                                          orderListItem
                                                        )}
                                                        {currencySymbolRight()}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {currencySymbolLeft()}
                                                        {showPriceOfEachOrderItem(
                                                          orderListItem
                                                        )}
                                                        {currencySymbolRight()}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="text-primary text-center font-weight-bold pt-5 xsm-text text-uppercase">
                                      {"Select food item to add to the list"}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row gx-3">
                  <div className="col-md-9">
                    {returnMoneyUsd > 0 ? (
                      <div
                        className="alert alert-danger text-center"
                        style={{ height: "56px", marginTop: "16px" }}
                      >
                        Return Amount: {formatPrice(returnMoneyUsd)}
                      </div>
                    ) : (
                      <>
                        <div className="fk-settle-group d-flex t-mt-15">
                          <label
                            htmlFor="settle-total"
                            className="text-capitalize w-50 fk-settle-group__label font-weight-bold"
                          >
                            total:
                          </label>
                          <div
                            id="settle-total"
                            className="w-50 fk-settle-group__input text-right pr-2 font-weight-bold"
                          >
                            {formatPrice(totalsettle)}
                          </div>
                        </div>
                        <div className="fk-settle-group d-flex t-mt-10 t-mb-15">
                          <label
                            htmlFor="settle-paid-amount"
                            className="text-capitalize w-50 fk-settle-group__label font-weight-bold"
                          >
                            paid amount:
                          </label>
                          {/*<div
                            id="settle-total"
                            className="w-50 fk-settle-group__input text-right pr-2 font-weight-bold"
                           >
                            {paidMoney}
                          </div>*/}
                          <input
                            ref={inputRefs.paid}
                            type="number"
                            onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                            onWheel={(e) => e.target.blur()}  
                            id="settle-total"
                            style={{minHeight:"1rem",borderRadius:"0",color:"#121053"}}
                            className="form-control w-50 fk-settle-group__input text-right p-0 pr-2 font-weight-bold"
                            autoFocus
                            value={paidMoney > 0 ? paidMoney : ""}
                            onChange={(e) => setPaidMoney(e.target.value ? parseFloat(e.target.value) : 0)}
                          />
                        </div>
                      </>
                    )}
                    <div className="fk-settle-cal container-fluid">
                      <div className="row h-100 g-2 mt-1">
                        <div className="col-2">
                          <div className="row g-2 h-100">
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0) {
                                    setPaidMoney(paidMoney + 10);
                                  }
                                }}
                              >
                                10
                              </button>
                            </div>
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0 ) {
                                    setPaidMoney(paidMoney + 20);
                                  }
                                }}
                              >
                                20
                              </button>
                            </div>
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0 ) {
                                    setPaidMoney(paidMoney + 50);
                                  }
                                }}
                              >
                                50
                              </button>
                            </div>
                            <div className="col-12 mb-2">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0 ) {
                                    setPaidMoney(paidMoney + 100);
                                  }
                                }}
                              >
                                100
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="row g-2 h-100">
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 1);
                                      }
                                    }}
                                  >
                                    1
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 4);
                                      }
                                    }}
                                  >
                                    4
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 7);
                                      }
                                    }}
                                  >
                                    7
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-p t-text-white"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 500);
                                      }
                                    }}
                                  >
                                    500
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 2);
                                      }
                                    }}
                                  >
                                    2
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 5);
                                      }
                                    }}
                                  >
                                    5
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 8);
                                      }
                                    }}
                                  >
                                    8
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-p t-text-white"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 1000);
                                      }
                                    }}
                                  >
                                    1000
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 3);
                                      }
                                    }}
                                  >
                                    3
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 6);
                                      }
                                    }}
                                  >
                                    6
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0 ) {
                                        setPaidMoney(paidMoney + 9);
                                      }
                                    }}
                                  >
                                    9
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn  t-bg-d t-text-white"
                                    onClick={() => {
                                      setPaidMoney(0);
                                      setReturnMoneyUsd(0);
                                      setOrderDetails({
                                        ...orderDetails,
                                        payment_type: null,
                                      });
                                    }}
                                  >
                                    C
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      let theP = parseFloat(
                                        formatPrice(totalsettle)
                                      );
                                      setPaidMoney(theP);
                                    }}
                                  >
                                    all
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        let theP = formatPrice(
                                          parseFloat(totalsettle) / 2
                                        );
                                        setPaidMoney(parseFloat(theP));
                                      }
                                    }}
                                  >
                                    1/2
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        let theP = formatPrice(
                                          parseFloat(totalsettle) / 3
                                        );
                                        setPaidMoney(parseFloat(theP));
                                      }
                                    }}
                                  >
                                    1/3
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    disabled={buttonDisabled}
                                    className="fk-settle-cal-btn bg-primary t-text-white t-bg-r text-capitalize hover-effect"
                                    onClick={!loading && handleSettleOrder}
                                  >
                                    {!loading ? "settle" : "wait"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex flex-column justify-content-center t-mt-15">
                      <div className="fk-settle__pay" data-simplebar>
                        <div className="row gx-3">
                          <div className="col-12">
                            {/* POS Navigation will ber here */}
                            <ul className="t-list fk-pos-nav list-group">
                              <li className="fk-pos-nav__list">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowSettle(false);
                                    setTotalSettle(0);
                                    setPaidMoney(0);
                                    setTheVatSettle(theVat);
                                    setReturnMoneyUsd(0);
                                    setOrderDetails({
                                      ...orderDetails,
                                      payment_type: null,
                                    });
                                  }}
                                  className="w-100 t-text-dark t-heading-font btn alert alert-danger font-weight-bold text-uppercase py-3 mb-3"
                                >
                                  Go back
                                </button>
                              </li>
                              {/* paymentTypes */}
                              {paymentTypeAvailable &&
                                paymentTypeAvailable.map(
                                  (groupItem, groupIndex) => {
                                    return (
                                      <li
                                        className="fk-pos-nav__list"
                                        key={groupIndex}
                                      >
                                      
                                        <button
                                          type="button"
                                          //set active or !
                                          className={`w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase py-3 hover-effect ${
                                            orderDetails &&
                                            orderDetails.payment_type !==
                                              null && orderDetails.payment_type[0]?.id ===
                                              groupItem.id
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            handleSetpaymentTypeSingle(
                                              groupItem
                                            );
                                          }}
                                        >
                                          {groupItem.name}
                                        </button>
                                      </li>
                                    );
                                  }
                                )}
                              {/* paymentTypes */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Card Modal */}
      <div
        className="modal fade"
        ref={cardAdd}
        id="cardAdd"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content" style={{ borderRadius: "1rem" }}>
            {/* <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="modal-body py-4">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete="off" 
                 onSubmit={(e)=>{
                  handleCardSubmit(e)
                 }}
                >
                  <div>
                    <div className="addons-list__item mt-1 mx-1">
                      <label
                        htmlFor="name"
                        className="form-label d-flex justify-content-between"
                      >
                        <span>{_t(t("Enter Card Number"))} </span>
                        <span
                          onClick={(e) => {
                            cardAdd.current.classList.remove("showing")
                          }}
                        >
                          <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                        </span>
                      </label>
                      <input
                        type="text"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}  
                        min="0"
                        className="form-control-card"
                        placeholder="Card first 6 number"
                        autoComplete="off"
                        maxLength={6}
                        ref={inputRefs.first}
                        value={cardNumber.first}
                        onChange={(e) => handleCardChange('first', e.target.value)}                      
                        required
                      />
                     
                      <input
                      type="text"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onWheel={(e) => e.target.blur()}  
                      min="0"
                      className="form-control-card"
                      placeholder="Card last 4 number"
                      autoComplete="off"
                      maxLength={4}
                      ref={inputRefs.last}
                      value={cardNumber.last}
                      onChange={(e) => handleCardChange('last', e.target.value)}                     
                      required
                    />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="row">
                    <div className="col-9">
                    </div>
                      <div className="col-3 px-3">
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bold w-100 sm-text text-uppercase t-width-max"
                          
                        >
                          {_t(t("Add Card"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default SettleOrder;
