
import React, { useState, useEffect,useContext } from "react";

import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { useParams,useHistory } from "react-router-dom";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as FullTable } from "../../../../public/Full Table.svg";
import { ReactComponent as LeftHalfTable } from "../../../../public/Left Table.svg";
import { ReactComponent as RightHalfTable } from "../../../../public/Right Table.svg";
import { RestaurantContext } from "../../../../../../contexts/Restaurant";
import {UserContext} from "../../../../../../contexts/User"


const TableModal = ({
  modal10Show,
  setModal10Show,
  setOrderDetails,
  orderDetails,
  selectedDeliveryType,
  selectTable,
  setSelectTable,
  waiterSelected,
  setwaiterSelected,
  guestSelected,
  setGuestSelected,
  tableId,
  setTableId,
  selectedParty,
  selectedDeptTag,
  buttonDisabled,
}) => {
  const {
    //waiter
    waiterForSearch,
   
  } = useContext(UserContext);
  const {
  
    tableForSearch,
    floorForSearch,
    //pagination
  } = useContext(RestaurantContext);
  let history = useHistory();
  const { order_id } = useParams();
  const colorPallete = ["#f3eabc","#ffc7c7","#c0ebe9d1","#edf93278","#C1E6FD","#14ffff4d","#ca26ff63","#f9ad006b"];
  const [remainingPallete,setRemainingPallete] = useState([]);
  const [waiter, setWaiter] = useState();
  const [multiSelect, setMultiSelect] = useState(false);
  const [tablefloor, setTableFloor] = useState();
  const [getTableFloor,setGetTableFloor] = useState([]);
  const [tables,setTables] = useState([]);
  const [tablesApi,setTableApi] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  

  // get floor api
  useEffect(()=>{
     let getFloor = floorForSearch && floorForSearch.filter((floorSearch)=>{
      return floorSearch.status === "1"
    });

    setGetTableFloor(getFloor);
    getFloor && setTableFloor(getFloor[0]);

  if(order_id){
    if( orderDetails.table && orderDetails.table?.length > 0){
      let floor = getFloor && getFloor.find((floor)=>{
        return floor.id === orderDetails.table[0].floor_id
      });
      setTableFloor(floor)
    }
  }

  },[floorForSearch, orderDetails?.branch]);

  //get table with api
  useEffect(()=>{
    let getTableStatus = tableForSearch && tableForSearch.filter((tableSearch)=>{
      return parseInt(tableSearch.branch_id) === orderDetails.branch?.id && tableSearch.status === "1"
    });

    if(!buttonDisabled){
      setTables(getTableStatus);
      setTableApi(getTableStatus);
    }

    getTableStatus && getTableStatus.map((table)=>{
      const index = colorPallete.findIndex((color) => color === (table.is_color));
      if(index !== -1){
         return colorPallete.splice(index, 1);
      }
      setRemainingPallete(colorPallete)
    });

    if(order_id && getTableStatus){
      const newTable = [...getTableStatus]; 
      // orderDetails.table && orderDetails.table[0] && startDate(orderDetails.table[0]?.date);
      // orderDetails.table && orderDetails.table[0] && startTime(orderDetails.table[0]?.start_time);
      // orderDetails.table && orderDetails.table[0] && endTime(orderDetails.table[0]?.end_time);
      setTables( newTable );
      setTableApi(newTable);
      let selectedTable = orderDetails.table && orderDetails.table?.map((table)=>{  
        if(getTableStatus && getTableStatus?.length > 0){
        const index = getTableStatus.findIndex((tableIndex) => table?.id === tableIndex.id);
        if(newTable[index].is_reserved === 2 && newTable[index].is_split === 1){
          newTable[index] = {...newTable[index],is_reserved : 1};
        }else{
          newTable[index] = {...newTable[index],is_reserved : 0};
        }
        
        setSelectTable((curr)=>{
          const newTable = {
            ...curr,
            [table.id] : {
              ...table,
              ...((table.is_reserved === 2 && table.is_split === 1) ? {is_reserved : 1} : {is_reserved : 0})
            }
          }
         return newTable
        });
       } 
      });
    }
  },[tableForSearch, orderDetails?.branch]);
  
  
  const [newtable,setNewTable] = useState(null);

  // multi select option
  useEffect(() => {
    const index = tables && tables.findIndex((table) => selectTable && Object.values(selectTable)[0]?.id === (table.id));
    if(!buttonDisabled && tablesApi && tablesApi[index]?.is_split === 0 && Object.values(selectTable)[0]?.is_merge === 0){
    if (index !== -1) {
        const newTable = [...tables];
        [newTable[index]] = [ {...newTable[index],is_split : 0}];
        setTables(newTable);
        setOrderDetails({
          ...orderDetails,
          table:null,
          table_id: null,
          table_name: null
        })
    }
  }else if(Object.values(selectTable)[0]?.is_merge && !buttonDisabled){
    const newTable = [...tables];
       const indexd = Object.values(selectTable).map((multiTable)=>{
        let index = tables.findIndex((table) => multiTable.id === (table.id));
        [newTable[index]] = [ {...newTable[index],is_merge : 0}];
       });
       setTables(newTable);
       setOrderDetails({
         ...orderDetails,
         table:null,
         table_id: null,
         table_name: null
       })
  }
  if(!buttonDisabled){
    setSelectTable({});
    setOrderDetails({
     ...orderDetails,
     table: null,
     waiter: waiterSelected ? waiterSelected : null,
     total_guest: guestSelected ? guestSelected : 1,
     table_id: null,
     table_name: null,
   });
   
  }
  }, [multiSelect,tablefloor,selectedDeliveryType,orderDetails?.branch,selectedDeptTag,selectedParty]);

  useEffect(() => {
    // search waiter acc to branch
    if (orderDetails.branch) {
      let tempWaiters =
        waiterForSearch !== null &&
        waiterForSearch.filter((eachWaiter) => {
          return parseInt(eachWaiter.branch_id) === orderDetails?.branch.id;
        });
      setWaiter(tempWaiters);
    }

    if (order_id) {
    setwaiterSelected(orderDetails?.waiter ? orderDetails?.waiter : "");
    setGuestSelected(
      orderDetails?.total_guest ? parseInt(orderDetails?.total_guest) : 1
    );
    if(history.location?.search?.match(/(.*)table(.*)edit_merge/i) || (orderDetails.table && orderDetails.table?.length > 0 && orderDetails.table[0].is_merge)){
      setMultiSelect(true);
    } 
  }
  }, [selectedDeliveryType, orderDetails?.branch]);

  //selected tables in state for final playload
  useEffect(()=>{
    if(selectTable){
      //let table_Id = [];
      let tables = Object.values(selectTable)
     // let tableid = Object.keys(selectTable)?.map(id => table_Id.push(parseInt(id)));
      let tableid = Object.keys(selectTable)?.map(id => parseInt(id));
      setNewTable(tables);
      setTableId(tableid);
    }
  },[selectTable]);

  const handleTableSubmit = (e) => {
    e.preventDefault();
    if (selectTable && Object.keys(selectTable)?.length > 0 ) {
      if(Object.values(selectTable)[0].is_reserved && Object.values(selectTable)[0].is_split === 0){
        toast.error("Table is already reserved")
      }else{
        setOrderDetails({
          ...orderDetails,
          table: newtable ? newtable : null,
          waiter: waiterSelected ? waiterSelected : null,
          total_guest: guestSelected ? guestSelected : 1,
          table_id: tableId,
          table_name : Object.values(selectTable)[0]?.name,
        });
        setModal10Show(false);
      }
    } else {
      toast.error("Please select the table first");
    }
  };

  const handleTableSelect = (table) => {
    if(selectTable && Object.values(selectTable)[0]?.is_split === 1 && Object.values(selectTable)[0]?.is_reserved === 0){
      toast.error("Please un split the selected split table");
    }
    else{
      if(Object.keys(selectTable).includes(table.id.toString())){
        setSelectTable((curr) => {
          const tabel = {
            ...curr,
          };
          delete tabel[table.id]
          return tabel;
        });
      
      }else{
        if(table.is_split && table.is_reserved){
          setSelectTable({
            [table.id]:{
              ...table,
            name: table?.name + "-B",
            is_color:"",
            is_merge:0
            }
          });
        
        }else{
          if(table.is_reserved){
            setSelectTable({
              [table.id]: {
                ...table,
                is_split:0,
                is_merge:0,
                is_color:"",
              },
            });
          }else{
            setSelectTable({
              [table.id]: {
                ...table,
                is_split:0,
                is_reserved: 0,
                is_merge:0,
                is_color:"",
              },
            });
          }
        }
      }
    }
  };

  const getReseredTime = (time) =>{
    let date = new Date().getTime();
    let Time = new Date(time).getTime()
    let actualTime = date - Time;
    let min = (Math.floor(actualTime / 60000) % 60);;
    let hour = (Math.floor(actualTime / 3600000) % 24);
     if(hour  > 0){
      return `${hour} hr: ${min} min` 
     }else{
       return `${min} mins` 
     }

  }

  // multiple table select
  const handleTableMulitpleSelect = (table,ind) => {
    if(table.is_split === 1 || table.is_reserved){
      toast.error("You can't select split/reserve table on multiselect");
    }else{
        if(Object.keys(selectTable).includes(table.id.toString())){
          const newTable = [...tables];
            if (ind !== -1) {
              [newTable[ind]] = [ {...newTable[ind],is_merge : 0,is_color:""}];
            }
          setTables(newTable);
          setSelectTable((curr) => {
            const tabel = {
              ...curr,
            };
            delete tabel[table.id]
            return tabel;
          });         
      }
      /*else{
        if(Object.keys(selectTable)?.length === 0){
          setSelectTable((curr) => {
                const tabel = {
                  ...curr,
                  [table.id]: {
                    ...table,
                     is_merge: 0,
                     is_split:0,
                     is_color:"",
                     is_reserved:0,
                  },
                };
                return tabel;
              });     
        }*/else {
         // let selectedTable = [table,...Object?.values(selectTable)];
          const newTable = [...tables];
          [newTable[ind]] = [ {...newTable[ind],is_merge : 1}];
          setSelectTable((curr) => {
            const mergetabel = {
              ...curr,
              [newTable[ind].id]: {
                ...newTable[ind],
                is_color: remainingPallete[0],
                is_merge : 1,
                is_reserved:0,
                is_split:0
              },
            };
            return mergetabel;
          });
          // const indexd = selectedTable?.map((multiTable)=>{
          //     let index = tables.findIndex((table) => multiTable.id === (table.id));
             
          // })
          setTables(newTable);
   
      }
      
    }
  };

  // split table
  const handleSplit = () => {
    if (Object.keys(selectTable)?.length > 0) {
      if(multiSelect === true){

         toast.error("Please turn off multi select option")
      }else{
        const index2 = tables.findIndex((table) => Object.values(selectTable)[0].id === (table.id));
        if(tables[index2]?.is_split === 1 && tables[index2]?.is_reserved === 0){
        if (index2 !== -1) {
          const newTable = [...tables];
          [newTable[index2]] = [ {...newTable[index2],is_split : 0}];
          setTables(newTable);
        }
         setSelectTable({});
        }else if( tablesApi[index2]?.is_split === 1 && tablesApi[index2]?.is_reserved === 1){
          toast.error("Already Split");
          setSelectTable({});
        }else if(tables[index2]?.is_reserved === 1){
          if(tables[index2]?.id === Object.values(selectTable)[0]?.id && Object.values(selectTable)[0]?.is_split){
            if (index2 !== -1) {
              const newTable = [...tables];
              [newTable[index2]] = [ {...newTable[index2],is_split : 0,}];
              setTables(newTable);
            }
            setSelectTable({});
          }else{
            if (index2 !== -1) {
              const newTable = [...tables];
              [newTable[index2]] = [ {...newTable[index2],is_split : 1,}];
              setTables(newTable);
            }
    
              setSelectTable({
                [Object.values(selectTable)[0].id]: {
                  ...Object.values(selectTable)[0],
                  name: Object.values(selectTable)[0].name + "-B",
                  is_color:"",
                  is_merge:0,
                  is_split:1,
                  is_reserved:1
                },
              });
          }
        }else{
        
        if (index2 !== -1) {
          const newTable = [...tables];
          [newTable[index2]] = [ {...newTable[index2],is_split : 1, is_reserved: 0}];
          setTables(newTable);
        }

          setSelectTable({
            [Object.values(selectTable)[0].id]: {
              ...Object.values(selectTable)[0],
              name: Object.values(selectTable)[0].name + "-A",
              is_color:"",
              is_merge:0,
              is_split:1,
              is_reserved:0
            },
          });
        }

      }
      
    } else {
      toast.error("Please Select Table");
    }
  };

  // const handleTranfer = () =>{
  //   if (Object.keys(selectTable)?.length > 1) {
  //     if(multiSelect === true){
  //       const index1 = tables.findIndex((table) => Object.keys(selectTable)[0] === (table.id));
  //       const index2 = tables.findIndex((table) => Object.keys(selectTable)[1] === (table.id));
  //       if (index1 !== -1 && index2 !== -1) {
  //         const newTable = [...tables];
  //         [newTable[index1], newTable[index2]] = [newTable[index2], newTable[index1]];
  //         setTables(newTable);
  //       }
  //     }else{
  //       toast.error("Please select table from multiselect option")
  //     }
  //   }else{
  //     toast.error("Please select two tables to tranfer")
  //   }
  // }

  return (
    <Modal
      show={modal10Show}
      onHide={() => setModal10Show(false)}
      size="table"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {/* Modal Body */}
        <div style={{padding:"0.5rem"}}>
          <form onSubmit={handleTableSubmit} autoComplete="off">
            <div className="main row">
              <div className="col-8">
                <h4 className="font-weight bold  table-header">Select Table</h4>
              </div>
              <div className="col-8">
                <div className="flex-row d-flex justify-content-between border-bottom pb-0 align-items-center">
                  <h6 className="font-weight bold  table-header">
                    Tables List
                  </h6>
                  <Switch
                    checked={multiSelect}
                    name="status"
                    onChange={(e) => {
                      setMultiSelect(!multiSelect);
                    }}
                    uncheckedIcon={
                      <label
                        className="switchIcon switchUnchecked"
                        style={{ paddingRight: 5 }}
                      >
                        Select One
                      </label>
                    }
                    checkedIcon={
                      <label className="switchIcon" style={{ paddingLeft: 5 }}>
                        Multi Select
                      </label>
                    }
                    height={22}
                    width={85}
                    offColor="#939393"
                    className={`float-left mb-1 ${multiSelect ? "switch-btn" : "switch-btn2"}`}
                  />
                </div>
                <ul style={{ padding: 0, listStyle: "none" }}>
                  <div className="d-flex align-items-center border-bottom px-1 py-2">
                  {getTableFloor &&
                      getTableFloor.map((floor,index) => (
                        <li key={index}
                          className={`floorTable mr-4
                          ${
                            tablefloor && tablefloor?.id === floor?.id
                              ? "floorActive"
                              : ""
                          }
                        `}
                          onClick={(e) => setTableFloor(floor)}
                        >
                          <span className="table-floor" key={floor.id}>
                             {floor.name}
                          </span>
                        </li>
                      ))}
                  </div>
                  <div className="res-table row my-2 mx-1">
                   {
                    tables && tables?.length > 0 && tables.map(
                      (table,index)=>
                      tablefloor &&
                      table.floor_id === parseInt(tablefloor.id) &&
                      (
                        //if table split
                       table.is_split === 1 ?
                       <div key={index}
                       className="col-3 d-flex align-items-center justify-content-center my-1 mb-2 "
                     >
                       <li
                         className={`d-flex align-items-center justify-content-center modal--table-item ${table.is_reserved === 2 && "disable_table"} ${table.is_reserved && "table-reserve"} p-3 mr-2 ml-1
                          ${
                          Object.keys(selectTable).includes(table.id.toString())
                              ? "selected-table"
                              : ""
                          }`}
                         onClick={() =>{
                         if(table.is_reserved === 2){

                         }else{
                           multiSelect ?
                           handleTableMulitpleSelect(table,index)
                           :
                           handleTableSelect(table)
                         }
                         }}
                       >

                       {
                        table.is_split && table.is_reserved === 2 ? 
                        <span className={`${table.is_reserved ? "table-tooltip" : "table-tooltip-none"}`}>Table split & reserved</span>
                        :
                        table.is_split && table.is_reserved === 1 ?
                        <span className={`${table.is_split ? "table-tooltip" : "table-tooltip-none"}`}>Table split and half reserve</span>
                        :
                        <span className={`${table.is_split ? "table-tooltip" : "table-tooltip-none"}`}>Table split</span>
                       }

                           <span className="split d-flex justify-content-end">
                           <span
                           className={" halfTable halfTableLeft" }
                         >
                         {table.name+"A"}
                         </span>
                         <LeftHalfTable/>
                           </span>

                           <span className="split d-flex justify-content-start">
                           <span
                           className={" halfTable halfTableRight"}
                         >
                         {table.name+"B"}
                         </span>
                         <RightHalfTable/>
                           </span>

                           {
                             table.is_reserved === 2 ||  table.is_reserved === 1 ? 
                            <span className="reservation_time">Occ. from {getReseredTime(table && table.updated_at1)}</span>
                            :
                            ""
                           }
                       </li>
                     </div>
                       :
                        // table merge
                       table.is_merge === 1 ?
                       <div  key={index}
                       className="col-3 d-flex align-items-center justify-content-center my-1 mb-2 "
                     >
                       <li
                       className={`d-flex align-items-center justify-content-center modal--table-item p-3 ${table.is_reserved && "table-reserve disable_table"} mr-2
                       
                      ${
                        Object.keys(selectTable).includes(table.id.toString())
                          ? "selected-table"
                          : ""
                      }`}
                      style={{backgroundColor:table.is_color, }}
                       onClick={() =>{
                        if(table.is_reserved){

                        }else{
                          multiSelect ?
                          handleTableMulitpleSelect(table,index)
                          :
                          handleTableSelect(table)
                        }
                       }}
                     >
                     {
                      table.is_merge && table.is_reserved ?
                      <span className={`${table.is_reserved ? "table-tooltip" : "table-tooltip-none"}`}>Table merged & reserved</span>
                      :
                      <span className={`${table.is_merge ? "table-tooltip" : "table-tooltip-none"}`}>Table merged</span>
                     }
                     <span
                     className="tableName "
                     style={{ color: table.is_color ? table.is_color : "white" }}
                   >
                   {table.name}
                   </span>
                   <FullTable/>
                   {
                    table.is_reserved === 2 ||  table.is_reserved === 1 ? 
                   <span className="reservation_time">Occ. from {getReseredTime(table && table.updated_at1)}</span>
                   :
                   ""
                   }
                     </li>
                     </div>
                       :
                       <div  key={index}
                       className="col-3 d-flex align-items-center justify-content-center my-1 mb-2 "
                     >
                       <li
                         className={`d-flex align-items-center justify-content-center modal--table-item p-3 mr-2 ${table.is_reserved && "table-reserve"}
                        ${
                          Object.keys(selectTable).includes(table.id.toString()) 
                            ? "selected-table"
                            : ""
                        }`}
                         onClick={() =>{
                          multiSelect ?
                          handleTableMulitpleSelect(table,index)
                          :
                          handleTableSelect(table)
                         }
                         }
                       >
                     
                        <span className={`${table.is_reserved ? "table-tooltip" : "table-tooltip-none"}`}>Table reserved</span>
                      
                         <span
                         className="tableName"
                         style={{ color: "white" }}
                       >
                         {table.name}
                       </span>
                       <FullTable/>
                       {
                        table.is_reserved === 2 ||  table.is_reserved === 1 ? 
                       <span className="reservation_time">Occ. from {getReseredTime(table && table.updated_at1)}</span>
                       :
                       ""
                      }
                       </li>
                     </div>

                    )
                    )
                   }

                  </div>
                </ul>

                {/* //footer// */}
                <div className="flex-row d-flex justify-content-end my-2 mt-4">
                 
                  <button
                    type="button"
                    className="btn fw-bold btn-table"
                    style={{ backgroundColor: "#939393", color: "#fff" }}
                    onClick={handleSplit}
                  >
                   {
                     selectTable && Object.values(selectTable)[0]?.is_split ?
                     "Un Split Table"  : " Split Table"
                   }
                  </button>
                </div>
              </div>
              <div className="col-4 border-left d-flex justify-content-between"
              style={{
                flexDirection:"column",
              }}
              >
                <div className="col ml-2">
               {/* <div className="col-12 ">
                
                <label className="mb-1 col-form-label class-variants" style={{color:"#0a8d6e"}}>
               
                <input
                disabled = {order_id}
                type="checkbox"
                className="form-check-input pointer-cursor mr-2"
                name="reservation"
                value = "1"
                onChange={(e)=>{
                  setOrderDetails({
                    ...orderDetails,
                    reservation: orderDetails.reservation === 1 ? 0 : 1,
                  })
                }}
                checked ={
                  orderDetails.reservation ? 1 : 0
                }

                />
                Table Reservation
                </label>
                <br/>
                 {
                  orderDetails.reservation === 1 &&(
                    <>
                
                    <label
                    for="recipient-name"
                    className="col-form-label fw-bold class-variants"
                  >
                    Date
                  </label>
                  <DatePicker
                   required={true}
                    minDate={new Date()}
                    selected={startDate}
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control sm-text py-2 t-mb-15 mb-md-0"
                    shouldCloseOnSelect={true}
                  />
                    <div className="col-12 ">
                    <label
                      for="recipient-name"
                      className="col-form-label fw-bold class-variants"
                    >
                      Time
                    </label>
                    <div className="d-flex align-items-center">
                    <span className="col mr-2">
                    <DatePicker
                    minDate={new Date().getTime()}
                    selected={startTime}
                    value={startTime}
                    onChange={(date) =>  setStartTime(date.getTime())}
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                     showTimeSelect
                     showTimeSelectOnly
                     dropdownMode="select"
                     className="form-control sm-text py-2 t-mb-15 mb-md-0 col-6"
                     shouldCloseOnSelect={true}
                   />
                   </span>

                   <span className="col ml-1">
                   <DatePicker
                  minDate={new Date().getTime()}
                  selected={endTime}
                  value={endTime}
                  onChange={(date) =>  setEndTime(date.getTime())}
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  showTimeSelect
                  showTimeSelectOnly
                  dropdownMode="select"
                  className="form-control sm-text py-2 t-mb-15 mb-md-0 col-6"
                  shouldCloseOnSelect={true}
                  />
                  </span>
                  </div>
                  </div>
                    </>
                  )
                 }
                  
                 </div> */}
                 
                  <div className="col-12">
                    <label
                      for="recipient-name"
                      className="col-form-label fw-bold class-variants "
                    >
                      Waiter
                    </label>
                    <Select
                      options={waiter}
                      defaultValue={waiterSelected}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={setwaiterSelected}
                      placeholder={"Name"}
                      styles={{
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none",
                        }),
                        control: (provided) => ({
                          ...provided,
                          fontSize:"1rem",
                          minHeight: "1rem",
                          // maxHeight: "3.4rem",
                          overflow:"hidden",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0 8px 0 0",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0 8px",
                        }),
                      }}
                    />
                  </div>
                  <div className="col-12">
                  <label
                    for="recipient-name"
                    className="col-form-label fw-bold class-variants"
                  >
                    Guest
                  </label>
                  <input
                    type="number"
                    onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                    onWheel={(e) => e.target.blur()}  
                    className="form-control mb-2"
                    value={guestSelected}
                    onChange={(e) => setGuestSelected(e.target.value)}
                    placeholder={1}
                  />
                </div>
                </div>
                <div className="col ml-2 d-flex justify-content-end"
                style={{
                  flexDirection:"column",
                }}
                >

                  <Modal.Footer
                    style={{
                      borderTop: "none",
                      display:"block",
                      padding: "0.3rem 0rem",
                    }}
                  >
                    <div className="row mt-1" style={{ borderTop: "none", padding:"0"}}>
                      <div className="col-6 p-0 pr-2">
                        <button
                          type="button"
                          className="btn w-100 btn-down fw-bold rounded-25 mr-2"
                          style={{
                            backgroundColor: "#0a8d6e",
                            color: "#fff",
                            fontSize: "0.85rem",
                          }}
                          onClick={handleTableSubmit}
                        >
                          CONFIRM
                        </button>
                      </div>
                      <div className="col-6 p-0" >
                        <button
                          type="button"
                          className="btn w-100 fw-bold btn-down rounded-25 "
                          style={{
                            backgroundColor: "#939393",
                            color: "#fff",
                            fontSize: "0.85rem",
                          }}
                          onClick={() => setModal10Show(false)}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </Modal.Footer>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer
        style={{
          borderTop: "none",
          justifyContent: "space-between",
          display: "block",
        }}
      > */}
      {/* Modal Footer
        <div className="modal-footer" style={{ borderTop: "none" }}>
          <div className="container-fluid col-4 " >
            <div className="col">
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn w-100 fw-bold"
                  style={{ backgroundColor: "#0a8d6e", color: "#fff" }}
                >
                  CONFIRM
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn w-100 fw-bold"
                  style={{ backgroundColor: "#939393", color: "#fff" }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div> */}

      {/* </Modal.Footer> */}
    </Modal>
  );
};

export default TableModal;
