import React, { useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getDate
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food";
import PrintBarcode from "./PrintBarcode";

const BarcodePrint = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
      ingredientItemForSearch,
    } = useContext(RestaurantContext);
  
    let {
      //opening stock
    //   setOpeningStockList,
    //   setOpeningStockForSearch,
    } = useContext(UserContext);

    let { foodForPOS } = useContext(FoodContext);
    // console.log("check item==>",foodForPOS)

    // States hook here
    //new Row
    const [newRow,setNewRow]=useState([]);
    const componentRef = useRef();
    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,
      // pageStyle: "@page { size: 3.5in }"      
    });

    let common_toast = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }
  
    //select style
    const customStyle = {
      control: (provided) => ({
        ...provided,
        minHeight: "2rem",
        marginTop: "0",
        marginBottom: "0",
      }),
  
      singleValue: (provided) => ({
        ...provided,
        alignSelf: "center",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginTop: "0",
        padding: "0 0.5rem",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0 0.4rem",
        svg: {
          height: "2rem",
        },
      }),
    };

    //set items hook
    const handleSetItems = async (index,item) => {    
    console.log("check==>",item);
      newRow[index].item_id=item?.id ? item?.id : "";
      newRow[index].item_name=item;
      newRow[index].item_code=item?.sku ? item.sku : "-";      
      setNewRow([...newRow]);      
    };
  
    const [rowId,setRowId]=useState(0);
    const addNewRow=()=>{
        let nextYearDate = new Date();
        nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);      
        setNewRow((pre)=>[...pre,{
          id:rowId,
          item_id:"",
          item_name:"",
          item_code:"-",
          qty:"",
          start:new Date(),
          expiry:nextYearDate
        }])
        setRowId(rowId+1);  
    }
  
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
    }
  
    //set each item qty
    const handleQty = (index,e) => {
      newRow[index].qty=e.target.value;
      setNewRow([...newRow]);
    };
    
    const handleStartDate = (index,date) => {
        newRow[index].start = date;
        let nextYearDate = new Date(date);
        nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
        newRow[index].expiry = nextYearDate;
        setNewRow([...newRow]);
    }

    const handleExpiryDate = (index,date) => {
      let startDate=new Date(newRow[index].start)
      let expiryDate=new Date(date)
      if(expiryDate < startDate){
        toast.error("Expiry must be greater!",common_toast)
      }else{
        newRow[index].expiry = date;
        setNewRow([...newRow]);
      }
    }
  
    //post req of barcode
    const barcodePrintAxios = async () => {      
        
        let slugArray = [];
        newRow.map((item, index) => { 
          if(item.item_name){
            slugArray.push({
              item_id:item.item_id,
              item_name:item.item_name?.name,
              item_code:item.item_code,
              qty:item.qty,
              start_date:getDate(item.start),
              expiry_date:getDate(item.expiry)
            });
          }         
        });

        // setLoading(true);
        // let formData = {
        //     items: slugArray,
        // };
        // console.log("check data==>",formData);
        if(newRow.length === slugArray.length){
          handleOnlyPrint();          
        }else{
          toast.error("Please select item",common_toast)
        }

        // const url = BASE_URL + "/settings/add-barcode";

        // try {
        //     const res = await axios.post(url, formData, {
        //         headers: { Authorization: `Bearer ${getCookie()}` },
        //     });

        //     setLoading(false);
        //     toast.success(`${_t(t("Barcode has been added"))}`, common_toast);
        // } catch (error) {
        //     console.log("check error==>", error);
        //     setLoading(false);
        //     toast.error(`${_t(t("Please try again later"))}`, common_toast);
        // }
    
    };
  
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();      
        if(newRow.length > 0){
            barcodePrintAxios();
        }
        else{
          toast.error(`${_t(t("Please add rows"))}`, common_toast);
        }
     
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Add Barcode Print"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
  
                      {/* Loading effect */}
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Add Barcode Print"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                          >
                            <div className="col-12">                              
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-Child"
                                          style={{width:"20rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Code"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Start Date"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Expiry Date"))}
                                          </span>
                                        </th>                                                                                
                                        {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                          onClick={()=>{delRow(item.id)}}
                                          ></i>
                                          </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                              <Select
                                                options={foodForPOS !== null ? foodForPOS : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleSetItems(index,e)}
                                                maxMenuHeight="12.8rem"                                                
                                                value={item.item_name}
                                                styles={customStyle}
                                                isMenuOpen={true}
                                                menuPosition="fixed"
                                                className="bank-table-input"                                              
                                                placeholder={
                                                _t(t("Please select items")) + ".."
                                                }
                                                isClearable                                                
                                              />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {item.item_code}
                                              </td>                                                

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={item.id}
                                                  name={item.id}
                                                  onChange={(e)=>handleQty(index,e)}
                                                  value={item.qty || ""}
                                                  placeholder="Qty"
                                                  required                                                  
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">                                                
                                                <DatePicker
                                                    selected={item.start}
                                                    onChange={(date) => handleStartDate(index,date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control table-text text-center"
                                                    placeholderText={_t(t("Start date"))}
                                                    shouldCloseOnSelect={false}
                                                    required
                                                />
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                <DatePicker
                                                    selected={item.expiry}
                                                    onChange={(date) => handleExpiryDate(index,date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dateFormat="dd/MM/yyyy"
                                                    dropdownMode="select"
                                                    className="form-control table-text text-center"
                                                    placeholderText={_t(t("Expiry date"))}
                                                    shouldCloseOnSelect={false}
                                                    required
                                                />
                                              </td>                                                                                           

                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(item.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}                                    
                                  </table>
                              </div>
                                
  
                              <div className="form-group mt-4 pb-2">                                
                                <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">                                
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Print"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
        <PrintBarcode
            rowsItem={newRow}
            printRef={componentRef}
        />
      </>
    );
}

export default BarcodePrint;
