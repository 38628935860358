import React, { useContext } from "react";
import moment from "moment";
import {
  _t,
  getSystemSettings,
  getDate
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";
import "./stock.css"
import Barcode from 'react-barcode';

const PrintBarcode = ({rowsItem,printRef}) => {
    const { t } = useTranslation();
    const {
        //common
        generalSettings,
      } = useContext(SettingsContext);
      console.log("qty==>",rowsItem)
  return (
    
    <>
       {/* print */}
       <div className="d-none">
       <div ref={printRef}>
         {rowsItem && (
           <div className="fk-print">
             <div className="" style={{width:"336px",margin:"0 auto",display:"flex",flexDirection:"row",alignItems:"center",gap:10,flexWrap:"wrap"}}>

                {rowsItem.map((value,index)=>{ 
                    const jsxElements = [];                    
                    for (let i = 0; i < parseInt(value.qty); i++) {
                      jsxElements.push(
                        <div key={i} style={{ width: "105px",height:"55px",marginTop:"2px", marginBottom: "5px", textAlign: "center",paddingTop:"2.2px",paddingLeft:"2px" }}>
                          <div style={{fontSize: "8px", color: "black"}}>                            
                            <span>{value.item_name?.name}</span>
                          </div>                          
                          <Barcode width={0.5} height={9} value={value.item_code} fontSize={8}/>                                                                           
                          <div style={{display: "flex", flexDirection: "column", fontSize: "8px", color: "black"}}>
                            <span>MFG: {moment(value.start).format("DD-MM-YYYY")} </span>
                            <span style={{marginTop:"0.5px"}}>BB: {moment(value.expiry).format("DD-MM-YYYY")}</span>
                          </div>
                        </div>
                      );
                    }
                  
                    return jsxElements;
                        
                })}            
             </div>
           </div>
         )}
       </div>
     </div>
    </>
  )
}

export default PrintBarcode