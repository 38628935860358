import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";

const StockTransferAdd = () => {
    const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    loading,
    setLoading,
    branchForSearch,
    getIngredientItem,
    ingredientItemForSearch,
    ingredientItemStock,
    getWorkPeriod,
    dataPaginating,
  } = useContext(RestaurantContext);
  let {
    //stock transfer
    getStockTransfer,
    stockTransferList,
    setStockTransferList,
    setPaginatedStockTransfer,
    stockTransferForSearch,
    setStockTransferForSearch,
    authUserInfo,
  } = useContext(UserContext);

  let [newStockTransfer, setNewStockTransfer] = useState({
    transfer_no: "",    
    transfer_date: new Date(),
    branch: null,
    remarks: '',
  });
  //new Row
  const [newRow,setNewRow]=useState([]);

  //select style
  
 const customStyle = {
  control: (provided) => ({
    ...provided,
    minHeight: "2rem",
    marginTop: "0",
    marginBottom: "0",
  }),
  placeholder:(provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    alignSelf: "center",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 0.4rem",
    svg: {
      height: "2rem",
    },
  }),
};
  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }
  }, []);

  //Set Tranfer Date
  const handleTransferDate=(transfer_date)=>{
    setNewStockTransfer({...newStockTransfer,transfer_date});
  }

  //set items hook
  const handleSetItems = async (index,item) => {
    newRow[index].unit="";
    newRow[index].base_unit="";
    newRow[index].base_qty="";
    newRow[index].conversion_qty="";
    newRow[index].base_unit=item.unit_id;
    newRow[index].item_name=item;
    newRow[index].code=item.item_code;
    newRow[index].item_units="";
    setNewRow([...newRow]);
    const url = BASE_URL + "/settings/get-unit_item/"+item.id;
    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
    .then((res) => {      
      if(res.data){        
        newRow[index].item_units = res.data;
        setNewRow([...newRow]);
        
      }      
    }).catch((error) =>{
      console.log("check error",error);
    })
  };

  const handleSetRowBranch=(index,branch)=>{
    newRow[index].to_branch=branch;
    setNewRow([...newRow])
  }

  //get avg qty
  const getIngredientInfo = (index,id,itemId) => {
      const url = BASE_URL + '/settings/get-qty-avgrate';
      let formData = new FormData()
      formData.append("branch_id", newStockTransfer.branch.id)
      formData.append("item_id", itemId)
      formData.append("unit_id", id)
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` }
        })
        .then(res => {
          if (res.data && res.data.stock_qty) {
            newRow[index].stock_qty=parseInt(res.data.stock_qty);
            newRow[index].price=parseFloat(res.data.avg_rate).toFixed(2)
            setNewRow([...newRow]);            
          }
        })
        .catch(err => {
          console.log(err)
        });
  }

  const handleQty=(index,e) => {
    const newQty = e.target.value
    if (newQty <= newRow[index].stock_qty) {
    newRow[index].qty=newQty;
    setNewRow([...newRow])
    } else {
      toast.error(`${_t(t(newRow[index].unit ? "Please select a quantity lower than stock quantity of the From Branch" : "Please select unit first"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  const handleSetUnit=(index,unit)=>{
    newRow[index].qty="";
    newRow[index].price="Loading..";
    newRow[index].stock_qty="Loading..";
    newRow[index].unit=unit;
    newRow[index].conversion_qty=parseInt(unit.qty);
    let itemId=newRow[index].item_name.id
    getIngredientInfo(index,unit.id,itemId)
    setNewRow([...newRow]);
  }

  const calBaseQty=(index)=>{
    let qty=newRow[index].qty;
    let conversion_qty=newRow[index].conversion_qty
    if(qty && conversion_qty){
      return qty * conversion_qty;
    }else{
      return "";
    }
  }

  const [rowId,setRowId]=useState(0);
  const addNewRow=()=>{   
    if(newStockTransfer.branch !== null){
      setNewRow((pre)=>[...pre,{
        id:rowId,
        code:"",
        item_name:"",
        to_branch:"",
        unit:"",
        item_units:"",
        qty:"",
        stock_qty:"",
        price:"",
        amount:"",
        base_unit:"",
        base_qty:"",
        conversion_qty:""
      }])
      setRowId(rowId+1);
    }else{
      toast.error(`${_t(t("Please select branch name"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  const delRow=(id)=>{
    let row=[...newRow]
    row= row.filter((item,i)=>id!==item.id);    
    setNewRow(row);
  }

  //handle Set branch hook
  const handleSetBranch = (branch) => {
    setNewStockTransfer({ ...newStockTransfer, branch });
    setNewRow([]);
  };

  const handleRemarks=(e)=>{
    setNewStockTransfer({...newStockTransfer,remarks:e.target.value})
  }

  //post req of food item add
  const stockTransferAxios = async () => {    
    let date =
      new Date(newStockTransfer.transfer_date).getFullYear() +
      "-" +
      (new Date(newStockTransfer.transfer_date).getMonth() + 1) +
      "-" +
      new Date(newStockTransfer.transfer_date).getDate();

    let slugArray=[];
    newRow.map((newStockItem,index) => {
      if(newStockItem.item_name === "" || newStockItem.to_branch === "" || newStockItem.unit === ""){
        slugArray.push();
      }
      else{
        slugArray.push({
          code: newStockItem.code,
          item_id: newStockItem.item_name.id,
          item_name: newStockItem.item_name.name,
          to_branch_id: newStockItem.to_branch.id,
          to_branch_name: newStockItem.to_branch.name,
          unit_id: newStockItem.unit.id,
          unit_name: newStockItem.unit.name,
          qty: parseInt(newStockItem.qty),
          stock_qty: newStockItem.stock_qty,
          price: parseFloat(newStockItem.price),
          amount: (newStockItem.price && newStockItem.qty) ? parseFloat(newStockItem.price) * parseInt(newStockItem.qty) : 0,
          base_unit: newStockItem.base_unit,
          base_qty: calBaseQty(index),
          conversion_qty: parseFloat(newStockItem.conversion_qty),
          base_price: parseFloat(newStockItem.price)
        });
      }
      });

    let formData = {
      transfer_date: date,
      from_branch_id: newStockTransfer.branch.id,
      item: slugArray,
      remarks: newStockTransfer.remarks
    };
    if(newRow.length === slugArray.length){
      setLoading(true);
    const url = BASE_URL + "/settings/stockTransfer";

    try {
      const res = await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });      
      setStockTransferList(res.data[0]);
      setStockTransferForSearch(res.data[1]);
      setLoading(false);
      toast.success(`${_t(t("Stock has been added"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    history.push(
      "/dashboard/manage/stock/stock-transfer"
    )
    } catch (error) {      
      console.log("check error==>",error);
      setLoading(false);
      toast.error(`${_t(t("Please try again later"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }}else{
      toast.error(`${_t(t("Please select all dropdowns"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      newStockTransfer.branch !== null &&
      newStockTransfer.transfer_date !== null
    ) {
      if(newRow.length>0){
        stockTransferAxios();
      }
      else{
        toast.error(`${_t(t("Please add stock items"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      toast.error(`${_t(t("Please fill all the required fields"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Add Stock Transfer"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Add Stock Transfer"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="col-12">
                          <div className="row">
                              <div className="form-group col-4 mt-3">
                                <div className="mb-2">
                                  <label
                                    htmlFor="transfer no"
                                    className="control-label"
                                  >
                                    {_t(t("Transfer no"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control table-text"
                                    id="transfer_no"
                                    name="transfer_no"                                    
                                    placeholder="Auto no"
                                    required
                                  />
                                </div>
                              </div>

                              <div className="form-group col-4 text-right mt-3">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="transfer_date"
                                    className="control-label"
                                  >
                                    {_t(t("Transfer Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    selected={newStockTransfer.transfer_date || ""}
                                    onChange={(e) => handleTransferDate(e)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("Purchase date"))}
                                    shouldCloseOnSelect={false}
                                  />
                                </div>
                              </div>
                              
                              <div className="form-group mt-3 col-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="branch"
                                      className="control-label"
                                    >
                                      {_t(t("Branch"))}
                                      <span className="text-danger">*</span>                                      
                                    </label>
                                  </div>
                                  <Select
                                    options={branchSearch ? branchSearch : []}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    value={newStockTransfer.branch || null}
                                    classNamePrefix="select"
                                    onChange={handleSetBranch}
                                    maxMenuHeight="200px"
                                    styles={customStyle}
                                    placeholder={
                                      _t(t("Please select a branch")) + ".."
                                    }
                                  />
                              </div> 
                            </div>

                            <div className="row">                                                            
                              <div className="form-group mt-2 col-12">
                                <div className="mb-2">
                                  <label
                                    htmlFor="remarks"
                                    className="control-label"
                                  >
                                    {_t(t("Remarks"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <textarea
                                    type="text"
                                    className="form-control table-text"
                                    id="remarks"
                                    name="remarks"
                                    onChange={handleRemarks}
                                    value={newStockTransfer.remarks || ""}
                                    placeholder="Remarks"
                                    style={{height:"33px"}}
                                  />
                                </div>
                              </div>
                            </div>                            
                            
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="d-none table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:"60px"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Code"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-child"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Name"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("To Branch"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:'130px'}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Stock Qty"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Price"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Amount"))}
                                          </span>
                                        </th>

                                        {/* <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base unit"))}
                                          </span>
                                        </th> */}
                                        
                                        {/* <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base qty"))}
                                          </span>
                                        </th> */}
                                        
                                        {/* <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:"9rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Conversion qty"))}
                                          </span>
                                        </th> */}
                                        {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (stockItem, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                          onClick={()=>{delRow(stockItem.id)}}
                                          ></i>
                                          </td>
                                              <td className="d-none table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>                                              
                                              {stockItem.code ? stockItem.code : "-"}
                                              </td>                                            

                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"230px"}}>                                                
                                                <Select
                                                options={ingredientItemForSearch !== null ? ingredientItemForSearch : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleSetItems(index,e)}
                                                maxMenuHeight="12.8rem"                                                
                                                value={stockItem.item_name}
                                                styles={customStyle}
                                                isMenuOpen={true}
                                                menuPosition="fixed"
                                                className="bank-table-input"                                              
                                                placeholder={
                                                _t(t("Please select items")) + ".."
                                                }                                                
                                              />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"240px"}}>                                                                                             
                                                <Select
                                                    options={branchForSearch && branchForSearch.filter((item)=>item.id !== newStockTransfer.branch.id)}
                                                    components={makeAnimated()}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.name}
                                                    value={stockItem.branch}
                                                    className="bank-table-input"
                                                    classNamePrefix="select"
                                                    onChange={(e)=>handleSetRowBranch(index,e)}
                                                    maxMenuHeight="200px"
                                                    styles={customStyle}
                                                    placeholder={
                                                    _t(t("Please select a branch")) + ".."
                                                    }
                                                    isMenuOpen={true}
                                                    menuPosition="fixed"
                                                />
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                               <Select
                                                  menuPosition="fixed"
                                                  options={stockItem.item_units ? stockItem.item_units.filter((item)=>item.id === stockItem.base_unit) : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  value={stockItem.unit}
                                                  classNamePrefix="select"
                                                  onChange={(e)=>handleSetUnit(index,e)}
                                                  className="bank-table-input"
                                                  maxMenuHeight="200px"
                                                  styles={customStyle}                                                  
                                                  placeholder={
                                                  _t(t("please select unit")) + ".."
                                                  }                
                                                  isDisabled={stockItem.item_units?false:true}                                     
                                                />                                                
                                              </td>
                                              <td className="table-text align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className={`form-control text-center table-Input table-text ${                                                    
                                                    ""
                                                  }`}
                                                  id={stockItem.id}
                                                  name={stockItem.id}
                                                  onChange={(e)=>handleQty(index,e)}
                                                  value={                                                    
                                                    stockItem.qty || ""
                                                  }
                                                  required
                                                  placeholder="Enter qty"
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">                                                
                                                {stockItem.stock_qty || stockItem.stock_qty===0 ? stockItem.stock_qty : "-"}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">                                             
                                                {stockItem.price || stockItem.price === 0 ? stockItem.price : "-"} 
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">                                                
                                                {
                                                (stockItem.price && stockItem.qty)
                                                ? !isNaN(stockItem.price * stockItem.qty) && parseFloat(stockItem.price * stockItem.qty).toFixed(2)
                                                : stockItem.unit ? "0" : "-"
                                                }
                                              </td>

                                              {/* hidden columns */}
                                              
                                              {/* <td className="table-text align-middle text-center">
                                                {stockItem.base_unit ? stockItem.base_unit : "-"}
                                              </td> 
                                              
                                              <td className="table-text align-middle text-center">                                                
                                                {formatPrice(calBaseQty(index))}
                                              </td>
                                              
                                              <td className="table-text align-middle text-center">
                                                {stockItem.conversion_qty ? stockItem.conversion_qty : "-"}
                                              </td>  */}
                                              
                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(stockItem.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}                                    
                                  </table>
                              </div>
                              

                            <div className="form-group mt-4 pb-2">
                              {/* <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5 rounded"
                                  type="submit"                                  
                                >
                                  {_t(t("Save"))}
                                </button>
                                <button
                                  className="btn btn-primary px-5 mx-2 rounded"
                                  onClick={() =>
                                    history.push(
                                      "/dashboard/manage/stock/stock-transfer"
                                    )
                                  }
                                >
                                  {_t(t("Back"))}
                                </button>
                              </div> */}
                              <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/stock-transfer"))}
                                //   data-dismiss="modal"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}     
    </>
  );
}

export default StockTransferAdd;