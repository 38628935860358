import React, { useState, useContext, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
//axios and base url
import axios from "axios";
import {
  BASE_URL,
  SAAS_APPLICATION,
  saas_apiUrl,
  saas_apiParams,
  saas_form_data,
} from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from "react-icons/md";
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const Zone = () => {
    let {
        //common
        loading,
        setLoading,
      } = useContext(SettingsContext);
    
      let {
        //order type
        zoneList,
        setZoneList,
        setPaginatedZones,
        zoneForSearch,
        setZoneForSearch,
        branchForListSearch,
        //pagination
        dataPaginating,
      } = useContext(RestaurantContext);
    
      // States hook here
      //new group
      let [newZone, setNewZone] = useState({
        name: "",
        status: "",
        address: "",
        edit: false,
        editSlug: null,
        uploading: false,
      });
    
      //search result
      let [searched, setSearched] = useState({
        list: null,
        searched: false,
      });

      const [branch,setBranch] = useState(null)

      useEffect(()=>{
        const getBranches = async() =>{
          try{
            const areasUrl = BASE_URL + "/settings/get-branch-list-2";
            const response = await axios.get(areasUrl, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            });
            
            setBranch(response.data);
          } catch (error) {
            // Handle the error here
            console.error("Error fetching areas:", error);
          }
        }
        getBranches()
      },[])

     const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
        setSearched({ ...searched, searched: false });
        } else {
        let searchedList = zoneForSearch.filter((zone) => {
            let lowerCaseZoneName = zone.name.toLowerCase();
            let lowerCaseZoneFee =
            zone.fee !== null && zone.fee.toLowerCase();
            return (
            lowerCaseZoneName.includes(searchInput) ||
            (lowerCaseZoneFee && lowerCaseZoneFee.includes(searchInput))
            );
        });
        setSearched({
            ...searched,
            list: searchedList,
            searched: true,
        });
        }
    };

      //delete confirmation modal of branch
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  handleDeleteZone(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

    //delete branch here
    const handleDeleteZone = (id) => {
      setLoading(true);
      const zoneUrl = BASE_URL + `/settings/zones/${id}`;
      return axios
        .delete(zoneUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data === "user") {
            setLoading(false);
            toast.error(
              `${_t(t("Please disable all the user of this branch first"))}`,
              {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          } else {
           
            setZoneList(res.data[0]);
            setZoneForSearch(res.data[1]);
            setSearched({
              ...searched,
              list: res.data[1],
            });
            setLoading(false);
            toast.success(`${_t(t("Branch has been deleted successfully"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    };
  

  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{_t(t("Zones"))}</title>
      </Helmet>
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newZone.uploading === true || loading === true ? (
                  tableLoading()
                ) : ( */}
                    <div key="fragment3">
                      {/* next page data spin loading */}
                      <div
                        className={`${
                          (dataPaginating || loading) && "loadingBlur"
                        }`}
                      ></div>
                      <div
                        className={`${
                          (dataPaginating || loading) && "lds-roller"
                        }`}
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      {/* spin loading ends */}

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {!searched.searched
                                  ? _t(t("Zones List"))
                                  : _t(t("Search Result"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                      <div className="col-md-6 col-lg-7">
                        <div className="row gx-3 align-items-center">
                          
                          <div className="col-md-9 t-mb-15 mb-md-0">
                            <div className="input-group">
                              <div className="form-file">
                                <input
                                  type="text"
                                  className="form-control border-0 form-control--light-1 rounded-0"
                                  placeholder={_t(t("Search")) + ".."}
                                  onChange={handleSearch}
                                />
                              </div>
                              <button
                                className="btn btn-primary"
                                type="button"
                              >
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>

                          <div className="col-md-3 text-md-right">
                              <NavLink
                                to="/dashboard/manage/restaurant/zone-add"
                                className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                              >
                                {_t(t("add new"))}
                              </NavLink>
                          </div>
                        </div>
                      </div>
                      </div>
                      {/* Table */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Zone"))}
                              </th>

                              <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Delivery Charges"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                             
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searched.searched
                              ? [
                                branch && zoneList && [
                                    zoneList.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="6"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      zoneList.data.map((item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (zoneList.current_page -
                                                  1) *
                                                  zoneList.per_page}
                                            </th>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.name}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <NavLink
                                              className="btn btn-primary ml-2 rounded-sm"
                                              to={`/dashboard/manage/restaurant/zone-edit/${item.id}`}
                                              style={{height:"1.7rem",width:"1.7rem"}}                                                      
                                            >
                                              <div className="d-flex align-items-center justify-content-center">
                                                <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                              </div>
                                            </NavLink>
                                            <button
                                              className="btn btn-danger ml-2 rounded-sm del-btn"
                                              onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                              >                                                    
                                                <MdDelete className="del-icon" />                                                    
                                            </button>
                                           </td>

                                          </tr>
                                        );
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  branch && searched && [
                                    searched.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="6"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searched.list.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (zoneList.current_page -
                                                    1) *
                                                    zoneList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.address || "-"}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <NavLink
                                                className="btn btn-primary ml-2 rounded-sm"
                                                to={`/dashboard/manage/restaurant/zone-edit/${item.id}`}
                                                style={{height:"1.7rem",width:"1.7rem"}}                                                      
                                              >
                                                <div className="d-flex align-items-center justify-content-center">
                                                  <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                </div>
                                              </NavLink>
                                              <button
                                                className="btn btn-danger ml-2 rounded-sm del-btn"
                                                onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                >                                                    
                                                  <MdDelete className="del-icon" />                                                    
                                              </button>
                                             </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* pagination loading effect */}
              {newZone.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searched.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(zoneList, setPaginatedZones)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(zoneList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearched({
                                      ...searched,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searched,
                                    zoneForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Zone;
