import React, { useState, useContext, useEffect, useRef } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

import {
  _t,
  getCookie,
  modalLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
//context consumer
import { FoodContext } from "../../../../../contexts/Food";
import { RestaurantContext } from "../../../../../contexts/Restaurant";


const FoodDeal = ({
 createDeal,
 setCreateDeal,
 setSearchedFoodItem,
 searchedFoodItem,
 loading,
 setLoading,
}) => {
    let {
        deptTagForSearch,
        // setDeptTagForSearch,
      } = useContext(RestaurantContext);

    let {
        //food
        setFoodList,
        propertyItemForSearch,
        foodForSearch,
        setFoodForSearch,
    
      } = useContext(FoodContext);

    const { t } = useTranslation();

      let itemType = [
       {
         id: 1,
         name: "Item"
       },
       {
         id: 2,
         name: "Property"
       },
      ]
      //new Row
    const [newRow,setNewRow]=useState([]);
    const rowId=useRef(0);
   
    useEffect(()=>{
      setNewRow([]);
      if(createDeal.deal_item?.length > 0){
        createDeal.deal_item.forEach((deal)=>{
          let menus = deptTagForSearch.filter((menu)=> JSON.parse(deal.menu_id).includes(menu.id));
          let type = [];
          let items = [];
          let properties = [];
          if(deal.food_type == "Property"){
            type = itemType[1] 
            properties = propertyItemForSearch && propertyItemForSearch.filter((proprty)=>JSON.parse(deal.item_id).includes(proprty.id))
            }else{
              type = itemType[0]
              items = foodForSearch && foodForSearch.filter((item)=>JSON.parse(deal.item_id).includes(item.id))
            }
            let newId = rowId.current 
            setNewRow((pre)=>[...pre,{
              id:newId,
              menu: menus,
              itemType: type,
              item: items,
              property: properties,
              rate: deal.price,
            }])
            rowId.current += 1
        })
      }
  },[createDeal]);

    const handleSetItems = (index,item) =>{
      newRow[index].item = item;
      setNewRow([...newRow]);
  }

  const handleSetProperty = (index,property) =>{
    newRow[index].property = property;
    setNewRow([...newRow]);
  }

  const handleSetType = (index,type) =>{
    newRow[index].itemType = type;
    setNewRow([...newRow]);
  }

  const handleSetMenu = (index,menu) =>{
    newRow[index].menu = menu;
    setNewRow([...newRow]);
  }

  const delRow=(id)=>{
    let row=[...newRow]
    row= row.filter((item,i)=>id!==item.id);
    setNewRow(row);
    
  }

  const addNewRow=()=>{
    let row_id = rowId.current;
      setNewRow((pre)=>[...pre,{
        id:row_id,
        menu: [],
        itemType: null,
        item: null,
        property: null,
        rate: "",
      }])
      rowId.current += 1
  }

 const handleSaveDeal = (e) =>{
    e.preventDefault();
      let deals = [];
      newRow && newRow.map((deal)=>{
        if(deal.itemType === null || deal.rate === "" || (deal.itemType?.id === 1 ? deal.item === null : deal.property === null)){
          deals.push()
        }else{
          let item = deal.itemType?.id === 1 ? deal.item && deal.item?.map((item)=>item?.id) : deal.property && deal.property?.map((property)=>property?.id);
          deals.push({
            item_id: JSON.stringify(item ? item : []),
            food_type: deal.itemType && deal.itemType?.name,
            menu_id: JSON.stringify(deal.menu?.length > 0 ? deal.menu?.map((item) => item?.id) : []),
            price: deal.rate
          })
        }
      });
      
      if(newRow === null || newRow?.length === 0){
        toast.error(`${_t(t("Please add row"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        })
        return false;
      }
      if(newRow?.length !== deals?.length){
        toast.error(`${_t(t("Please select/ fill required"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        })
        return false;
      }
      setLoading(true);
      let formData = {
        food_item_id: createDeal.id,
        // items : JSON.stringify(deal)
        items : deals
      }
      const url = BASE_URL + "/settings/create-deal";
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setFoodList(res.data[0]);
          setFoodForSearch(res.data[1]);
          setSearchedFoodItem({
            ...searchedFoodItem,
            list: res.data[1],
          });
          setCreateDeal({
            id:null,
            name:"",
            uploading:false,
            modal: false
          });
          setNewRow([])
          document.getElementById('close-btn').click();
          toast.success(`${_t(t("Deal has been created"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setLoading(false)
        })
        .catch((err)=>{
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setLoading(false)
        })
  }


  return (
    <div>
        {/* create deal modal */}
        <div
        className={`modal fade ${
        createDeal.modal === true ? "showing" : ""
        }`}
        aria-hidden="true">
        <div className="modal-dialog modal-lg createDeal">
          <div className="modal-content">
            <div className="modal-header align-items-center">
                <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                    {_t(t("Create Deal for"))}{" "}
                    <span className="text-capitalize">
                    {createDeal && createDeal.name}
                    </span>
                </h5>
                </div>
                <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                    setCreateDeal({
                    ...createDeal,
                    modal: false
                    })
                }}
                ></button>
            </div>
            
            <div className="modal-body">

                {/* show form or show saving loading */}
                {createDeal.uploading === false ? (
                <div key="fragment-food-group-1">
                    <form autoComplete="off" onSubmit={handleSaveDeal} className="mx-2">
                    <div className= "table-responsive mt-3 pr-1 m-1 deal_height">
                     <table className="table table-bordered-sm table-hover">
                        <thead className="align-middle">                                    
                        <tr>
                        {newRow.length == 0 ? <th
                            scope="col"
                            className="table-text text-capitalize align-middle text-center border-1 border"
                        >
                            <span className="t-mr-10" onClick={()=>addNewRow()}>
                                <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                            </span>
                        </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                            <th
                            scope="col"
                            className="table-text text-capitalize align-middle text-center border-1 border table-Child"
                            >
                            <span style={{fontWeight:500}}>
                            {_t(t("Menu"))}
                            </span>
                            </th>                                     
                            
                            <th
                            scope="col"
                            className="table-text text-capitalize align-middle text-center border-1 border"
                            >
                            <span style={{fontWeight:500}}>
                            {_t(t("Type"))}
                            <small className="text-danger">*</small>
                            </span>
                            </th>

                            <th
                            scope="col"
                            className="table-text text-capitalize align-middle text-center border-1 border"
                            >
                            <span style={{fontWeight:500}}>
                            {_t(t("Item"))}
                            <small className="text-danger">*</small>
                            </span>
                            </th>

                            <th
                            scope="col"
                            className="table-text text-capitalize align-middle text-center border-1 border"
                            >
                            <span style={{fontWeight:500}}>
                            {_t(t("Rate"))}
                            <small className="text-danger">*</small>
                            </span>
                            </th>

                        </tr>
                        </thead>
                        {newRow && newRow.length>0 ?
                        <tbody className="align-middle">
                        {newRow.map(
                            (item, index) => {
                            return (
                                <tr
                                className="align-middle"
                                key={index}
                                >
                                <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                            {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                            </span>}&nbsp;
                            <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                            onClick={()=>{delRow(item.id)}}
                            ></i>
                            </td>
                                <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"150px"}}>
                                <Select
                                    options={deptTagForSearch ? deptTagForSearch : []}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    classNamePrefix="select"
                                    value={item.menu}
                                    onChange={(e)=>handleSetMenu(index,e)}
                                    maxMenuHeight="13rem"  
                                    // styles={customStyle}
                                    shouldCloseOnSelect={false}
                                    menuPosition="fixed"
                                    className="basic-multi-select"
                                    isMulti
                                    //className="bank-table-input"                                              
                                    placeholder={
                                    _t(t("Please select menu")) + ".."
                                    }                                                
                                />
                                </td>                                              

                                <td className="table-text text-capitalize align-middle text-center"  style={{minWidth:"100px"}}>
                                    <Select
                                    options={itemType ? itemType : []}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={item.itemType}
                                    classNamePrefix="select"
                                    onChange={(e)=>handleSetType(index,e)}
                                    maxMenuHeight="13rem"                                                
                                    // styles={customStyle}
                                    isMenuOpen={true}
                                    menuPosition="fixed"
                                    className="basic-multi-select"
                                    //className="bank-table-input"                                              
                                    placeholder={
                                    _t(t("Please select type")) + ".."
                                    }                                                
                                    />
                                </td>

                                <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"150px"}}> 
                                    {
                                    item.itemType && item.itemType?.id && item.itemType.id === 1 ? (
                                        <Select
                                        menuPosition="fixed"
                                        options={foodForSearch ? foodForSearch : []}
                                        components={makeAnimated()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        value={item.item}
                                        classNamePrefix="select"
                                        className="basic-multi-select"
                                        onChange={(e)=>handleSetItems(index,e)}
                                        isMulti
                                        //styles={customStyle}                                                  
                                        placeholder={
                                        _t(t("please select item")) + ".."
                                        } 
                                        isDisabled={item.itemType === null || item.itemType === "" ? true : false}
                                        />
                                    ): (
                                        <Select
                                        menuPosition="fixed"
                                        options={propertyItemForSearch ? propertyItemForSearch : []}
                                        components={makeAnimated()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        value={item.property}
                                        isMulti
                                        classNamePrefix="select"
                                        className="basic-multi-select"
                                        onChange={(e)=>handleSetProperty(index,e)}
                                        placeholder={
                                        _t(t("please select property")) + ".."
                                        } 
                                        isDisabled={item.itemType === null || item.itemType === "" ? true : false}
                                        />
                                    )
                                    }                                               
                                </td>
                                <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"70px"}}>
                                    <input
                                    className="form-control table-text rounded-25 "
                                    step="0.0001"
                                    type="number"
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                                        evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}  
                                    min="0"
                                    value={item.rate || ""}
                                    placeholder="Price"
                                    onChange={(e)=>{
                                        newRow[index].rate = e.target.value;
                                        setNewRow([...newRow]);
                                    }}
                                    />                                            
                                </td>
                              </tr>
                            );
                            }
                        )}
                        </tbody>
                        : null}
                    </table>
                    </div>
                    {/*foodForSearch && (
                        <div className="form-group">
                        <div className="mb-2">
                            <label className="control-label">
                            {_t(t("Add items"))}
                            <span className="text-danger">*</span>
                            </label>
                        </div>
                        <Select
                            options={foodForSearch && foodForSearch}
                            value={createDeal.item}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            key={createDeal.item && createDeal.item?.length}
                            isMulti
                            closeMenuOnSelect={false}
                            maxMenuHeight="200px"
                            onChange={handleSetItems}
                            placeholder={_t(t("Please select items")) + ".."}
                        />
                        </div>
                    )*/}
                    
                    <button
                        disabled={loading}
                        type="submit"
                        className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4 rounded"
                    >
                        {_t(t("Save"))}
                    </button>
                    </form>
                </div>
                ) : (
                <div key="fragment2">
                    <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                    </div>
                    {modalLoading(5)}
                </div>
                )}
            </div>
           </div>
         </div>
      </div>
    </div>
  )
}

export default FoodDeal
