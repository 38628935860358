import React, { useState, useContext, useEffect,useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";
//pages & includes
import ManageSidebar from "../ManageSidebar";
//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  customStyle,
} from "../../../../../functions/Functions";
//axios and base url
import axios from "axios";
import {
  BASE_URL,
  SAAS_APPLICATION,
  saas_apiUrl,
  saas_apiParams,
  saas_form_data,
} from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const ZoneAdd = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    let {
        //common
        loading,
        setLoading,
      } = useContext(SettingsContext);
    
      let {
       //get branch
        branchForSearch,
        //pagination
        dataPaginating,
      } = useContext(RestaurantContext);

      let [allAreas,setAllAreas]=useState(null)
      // States hook here
      let [newZone, setNewZone] = useState({
        branch: null,
        name: "",
        areas: "",
        min_order: 0,
        delivery_charges: 0,
        delivery_estimation: 0,
        free_delivery_after: 0,
        free_delivery: 0,
        status: 1,
        edit: false,
        editSlug: null,
        uploading: false,
      });
    
    //set states on edit
    const handleSetEdit = (id) =>{

      setNewZone({
        ...newZone,
        edit: true,
        editSlug: id,
      })
    }

    useEffect(()=>{
      const getAreas = async() =>{
        try{
          const areasUrl = BASE_URL + "/settings/get-area-list";
          const response = await axios.get(areasUrl, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setAllAreas(response.data[0]);
        } catch (error) {
          // Handle the error here
          console.error("Error fetching areas:", error);
        }
      }
      getAreas()
    },[])

    useEffect(()=>{
      if(params.id && branchForSearch && allAreas){
        handleSetEdit(params.id);
      }
    },[branchForSearch]);

      const handleSetAreas = (area) =>{
        setNewZone({
          ...newZone,
          areas : area,
        })
      }

      // branch select
      const handleSetNewBranch = (branch) =>{
        setNewZone({
          ...newZone,
          branch: branch
        })
      }
      // satus change
      const handleChecked  = () =>{
        setNewZone({
          ...newZone,
          status: newZone.status == 0 ? 1 : 0
        })
      }
    
      const handleChange = (e) =>{
         setNewZone({
          ...newZone,
          [e.target.name] : e.target.value
         })
      }

      //add zone 
      const handleSaveNewZone = (e) =>{
        e.preventDefault();
        setNewZone({
          ...newZone,
          uploading: true,
        });
        if(newZone.branch === null || newZone.branch === ""){
          toast.error(`${_t(t("Please select branch"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        if(newZone.areas === null || newZone.areas === "" || newZone.areas?.length === 0){
          toast.error(`${_t(t("Please select areas"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        
        let area = newZone.areas.map((areas)=>areas.area_id);
        const zoneUrl = BASE_URL + "/settings/zones";
        let formData = new FormData();
        formData.append("branch_id", newZone.branch.id);
        formData.append("name", newZone.name);
        formData.append("areas", JSON.stringify(area));
        formData.append("minimum", newZone.min_order ? parseFloat(newZone.min_order) : 0);
        formData.append("fee", newZone.delivery_charges ? parseFloat(newZone.delivery_charges) : 0);
        formData.append("free_delivery", newZone.free_delivery);
        formData.append("delivery_estimation", newZone.delivery_estimation ? parseFloat(newZone.delivery_estimation) : 0);
        formData.append("min_for_free_delivery", newZone.free_delivery_after ? parseFloat(newZone.free_delivery_after) : 0);
        formData.append("status", newZone.status);
        return axios
        .post(zoneUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewZone({
            branch: null,
            name: "",
            areas: "",
            min_order: 0,
            delivery_charges: 0,
            delivery_estimation: 0,
            free_delivery_after: 0,
            free_delivery: 0,
            status: 1,
            edit: false,
            editSlug: null,
            uploading: false,
          })
          setLoading(false);
          toast.success(`${_t(t("Zone has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          history.push("/dashboard/manage/restaurant/zones");
       })
        .catch((error) => {
          console.log("check error==>",error)
          setLoading(false);
          setNewZone({
            ...newZone,
            uploading: false,
          })
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });

      }

      // update zone
      const handleUpdateZone = (e) =>{
        e.preventDefault();
        setNewZone({
          ...newZone,
          uploading: true,
        });
        if(newZone.branch === null || newZone.branch === ""){
          toast.error(`${_t(t("Please select branch"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        if(newZone.areas === null || newZone.areas === "" || newZone.areas?.length === 0){
          toast.error(`${_t(t("Please select areas"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        let area = newZone.areas.map((areas)=>areas.area_id);
        const zoneUrl = BASE_URL + `/settings/zones/${newZone.editSlug}`;
        let formData = new FormData();
        formData.append("branch_id", newZone.branch.id);
        formData.append("name", newZone.name);
        formData.append("areas", JSON.stringify(area));
        formData.append("minimum", newZone.min_order ? parseFloat(newZone.min_order) : 0);
        formData.append("fee", newZone.delivery_charges ? parseFloat(newZone.delivery_charges) : 0);
        formData.append("free_delivery", newZone.free_delivery);
        formData.append("delivery_estimation", newZone.delivery_estimation ? parseFloat(newZone.delivery_estimation) : 0);
        formData.append("min_for_free_delivery", newZone.free_delivery_after ? parseFloat(newZone.free_delivery_after) : 0);
        formData.append("status", newZone.status);
        return axios
        .put(zoneUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewZone({
            branch: null,
            name: "",
            areas: "",
            min_order: 0,
            delivery_charges: 0,
            delivery_estimation: 0,
            free_delivery_after: 0,
            free_delivery: 0,
            status: 1,
            edit: false,
            editSlug: null,
            uploading: false,
          });
          history.push("/dashboard/manage/restaurant/zones");
          setLoading(false);
          setNewZone({
            ...newZone,
            uploading: false,
          })
          toast.success(`${_t(t("Zone has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
       })
        .catch((error) => {
          console.log("check error==>",error)
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
      }
    

  return (
    <div>
      <Helmet>
        <title>{_t(t("Add Zone"))}</title>
      </Helmet>
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-menu table-bottom-info-hide" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newZone.uploading === true || loading === true ? (
                  tableLoading()
                  ) : ( */}
                    <div key="fragment3">
                      {/* next page data spin loading */}
                      <div
                        className={`${
                          (dataPaginating || loading || newZone.uploading) && "loadingBlur"
                        }`}
                      ></div>
                      <div
                        className={`${
                          (dataPaginating || loading || newZone.uploading) && "lds-roller"
                        }`}
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      {/* spin loading ends */}

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {!newZone.edit  ? _t(t("Add New Zone")) : _t(t("Update Zone"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">                                  
                            </div>
                        </div>
                      </div>
                         {/* form start here */}
                         <div key="fragment-branch-1">
                         <form
                         onSubmit={
                          !newZone.edit ? handleSaveNewZone : handleUpdateZone
                          }
                           className="row card p-2 mx-3 mb-5 new-text-style"
                           autoComplete="off"
                         >
                           <div className="row">
                               <div className="form-group col-6 mt-3">
                                   <label htmlFor="name" className="form-label">
                                       {_t(t("Select Branch"))}{" "}
                                       <span className="text-danger">*</span>
                                   </label>
                                   <Select
                                    options={branchForSearch && branchForSearch}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    classNamePrefix="Branch"
                                    className="sm-text"
                                    maxMenuHeight="200px"
                                    placeholder="Branch"
                                    styles={customStyle}
                                    value={newZone.branch || ""}
                                    required
                                    onChange={handleSetNewBranch}
                                   />
                               </div>                            
                               <div className="form-group mt-3 col-6">
                                  <label htmlFor="zone" className="form-label">
                                      {_t(t("Zone Name"))}{" "}
                                      <span className="text-danger">*</span>
                                  </label>
                                  <input
                                      type="text"
                                      className="form-control "
                                      id="name"
                                      name="name"
                                      placeholder="e.g. karachi East"
                                      value={newZone.name || ""}
                                      required
                                      onChange={handleChange}
                                  />
                               </div>
                            </div>    
       
                            <div className="row">
                               <div className="form-group col-6 mt-3">
                                   <label htmlFor="name" className="form-label">
                                       {_t(t("Select Areas"))}{" "}
                                       <span className="text-danger">*</span>
                                   </label>
                                   <Select
                                    options={allAreas && allAreas}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.area_id}
                                    isMulti={true}
                                    classNamePrefix="Area"
                                    className="sm-text"
                                    maxMenuHeight="200px"
                                    placeholder="Area"
                                    styles={customStyle}
                                    value={newZone.areas || ""}
                                    onChange={handleSetAreas}
                                   />
                               </div>                            
                               <div className="form-group mt-3 col-6">
                                  <label htmlFor="min_order" className="form-label">
                                  {_t(t("Minimum Order"))}{" "}
                                  </label>
                                  <input
                                      type="number"
                                      className="form-control "
                                      id="min_order"
                                      name="min_order"
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                        evt.preventDefault()
                                      }
                                      onWheel={(e) => e.target.blur()}  
                                      min="0"
                                      placeholder="e.g. 0"
                                      value={newZone.min_order || ""}
                                      onChange={handleChange}
                                  />
                               </div>
                            </div>   
                            
                            <div className="row">
                               <div className="form-group col-4 mt-3">
                                  <label htmlFor="delivery_charges" className="form-label">
                                  {_t(t("Delivery Charges"))}{" "}
                                  </label>
                                  <input
                                      type="number"
                                      className="form-control "
                                      id="delivery_charges"
                                      name="delivery_charges"
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                        evt.preventDefault()
                                      }
                                      onWheel={(e) => e.target.blur()}  
                                      min="0"
                                      placeholder="e.g. 0"
                                      value={newZone.delivery_charges || ""}
                                      onChange={handleChange}
                                  />
                                </div>   
                                
                                <div className="form-group col-2 mt-3">
                                  <label htmlFor="free_delivery" className="form-label mb-1">
                                    {_t(t("Free Delivery"))}{" "}
                                  </label>
                                  <div className="mt-2 ml-1">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="free_delivery"
                                      name="free_delivery"
                                      onChange={() => {
                                      setNewZone({
                                        ...newZone,
                                        free_delivery:
                                          parseInt(
                                            newZone.free_delivery
                                          ) === 0
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                      checked={
                                        parseInt(newZone.free_delivery) === 1
                                      }
                                    />
                                  </div>
                                </div> 

                                <div className="form-group mt-3 col-6">
                                    <label htmlFor="delivery_estimation" className="form-label">
                                    {_t(t("Delivery Estimation"))}{" "}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control "
                                        id="delivery_estimation"
                                        name="delivery_estimation"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                          evt.preventDefault()
                                        }
                                        onWheel={(e) => e.target.blur()}  
                                        min="0"
                                        placeholder="e.g. 0"
                                        value={newZone.delivery_estimation || ""}
                                        onChange={handleChange}
                                    />
                               </div>
                               
                            </div>  
                            
                            <div className="row">
                               <div className="form-group col-6 mt-3">
                                  <label htmlFor="free_delivery_after" className="form-label">
                                  {_t(t("Free Delivery After"))}{" "}
                                  </label>
                                  <input
                                      type="number"
                                      className="form-control "
                                      id="free_delivery_after"
                                      name="free_delivery_after"
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                        evt.preventDefault()
                                      }
                                      onWheel={(e) => e.target.blur()}  
                                      min="0"
                                      placeholder="e.g. 0"
                                      value={newZone.free_delivery_after || ""}
                                      onChange={handleChange}
                                  />
                                </div>                            

                                <div className="form-group mt-3 col-6">
                                    <label htmlFor="status" className="form-label mb-2">
                                    {_t(t("Status"))}{" "}
                                    </label>
                                    <div className="mt-1 ml-1">
                                      <Switch
                                      onChange={handleChecked}
                                      checked={newZone.status === 1}
                                      className="react-switch ml-1"
                                      name="status"
                                      height={20}
                                      width={42}
                                      offColor="#ee5253"                        
                                     />
                                    </div>
                               </div>
                               
                            </div>  

                           <div className="row mt-4">
                             <div className="d-flex justify-content-end">
                               <div className="col-3 d-flex justify-content-end">
                               <button
                                   type="button"
                                   className="btn rounded hov-btn mr-3"
                                   onClick={()=>(history.push("/dashboard/manage/restaurant/zones"))}
                                 >
                                   <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                 </button>
                                 <button
                                 type="submit"
                                 className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {!newZone.edit ? _t(t("Save")) : _t(t("Update"))}
                                </button>
                               </div>
                               {/* <div className="col-3">
                                 <button
                                   type="button"
                                   className="btn btn-primary w-100 x text-uppercase t-width-max rounded"
                                   onClick={()=>(history.push("/dashboard/manage/restaurant/branches"))}
                                 >
                                   {_t(t("BACK"))}
                                 </button>
                               </div> */}
                             </div>
                           </div>
                         </form>
                       </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ZoneAdd
