import React, { useState, useContext, useRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { UserContext } from "../../../../../../contexts/User";
import { SettingsContext } from "../../../../../../contexts/Settings";
import { toast } from "react-toastify";
//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  // restaurantMenuLink,
} from "../../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL } from "../../../../../../BaseUrl";
import DatePicker from "react-datepicker";

const PosModal = ({
  emptyCard,
  cashDetails,
  setCashDetails,
  setButtonDisabled,
  noteRiderModal,
  setNoteRiderModal,
  setBackModal,
  backModal,
  addReasonOnSubmit,
  deleteReason,
  setDeleteReason,
  setAddReasonModalShowing,
  addReasonModalShowing,
  handleCardSubmit,
  setOrderDetails,
  orderDetails,
  cartTotal,
  setCartTotal,
  cartSubTotal,
  setDiscount,
  cardModal,
  paymentTypeModalState,
  setPaymentTypeModalState,
}) => {
  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };
  const { t } = useTranslation();
  const { loading, setLoading,} =
    useContext(SettingsContext);
  const { partyForSearch } = useContext(UserContext);

  // pay in/out
  const [otherChequeFields, setOtherChequeFields] = useState(false);

  const focusPopUpInputField = input => {
    if (input) {
      input.focus()
    }
  };

  //  party
  const handleSetPartyFromCashModal = (party) => {
    setCashDetails({
      ...cashDetails,
      party_id: party.id,
      party_name: party.name,
    });
  };

    //close tax calculation
    const closePaymentTypeTax = () => {
      setPaymentTypeModalState({
        showModal: false,
        taxCalSelect:false,
        subTotal: 0,
        vat: 0,
        vatAmount: 0,
        serviceCharge: 0,
        serviceChargeAmount: 0,
        deliveryCharges: 0,
        discount: 0,
        totalPayable: 0,
        payment_type_name: "",
      });
    };
  
  
  //cash modal
  const handleSubmitCash = (type) => {
    let url = BASE_URL + "/settings/new-payment";
    setLoading(true);
    console.log("cash detail : ", cashDetails);
    if (!cashDetails.payment_type) {
      toast.error(`${_t(t("Please add payment type"))}`, toastObj);
      setLoading(false);
      return false;
    } else {
      let formData = cashDetails;
      formData.payment_status = type;

      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          toast.success(
            `${_t(t("Record has been added successfully."))}`,
            toastObj
          );
          setLoading(false);
          setCashDetails({
            ...cashDetails,
            party_id: "",
            party_name: "",
            payment_type: false,
            cheque_no: 0,
            cheque_date: null,
            payment_status: null,
            remarks: "",
            amount: 0,
          });
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, toastObj);
          setButtonDisabled(false);
        });
    }
  };

  return (
    <>
      {/* Cash Modal */}
      <div className="modal fade" id="cashModal" aria-hidden="true">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete="off">
                  <div>
                    {/* <label htmlFor="name" className="form-label">
                    {_t(t("Amount"))}{" "}
                    <small className="text-primary">*</small>
                  </label> */}
                    <div className="addons-list__item mt-1 mx-1">
                      <Select
                        options={partyForSearch && partyForSearch}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        className="xsm-text"
                        onChange={handleSetPartyFromCashModal}
                        maxMenuHeight="200px"
                        placeholder={_t(t("Select Party")) + ".."}
                      />
                    </div>

                    <div className="addons-list__item mt-1 mx-1 mb-1 fk-addons-table__body">
                      <label htmlFor="name" className="form-label d-block">
                        {_t(t("Payment Type"))}{" "}
                        <small className="text-primary">*</small>
                      </label>
                      <label className="mx-checkbox d-inline-block mr-3">
                        <input
                          type="radio"
                          value="Cash"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          name="payment_type"
                          onChange={() => {
                            setOtherChequeFields(false);
                            setCashDetails({
                              ...cashDetails,
                              payment_type: "Cash",
                            });
                          }}
                        />
                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text">
                          Cash
                        </span>
                      </label>
                      <label className="mx-checkbox d-inline-block mr-3">
                        <input
                          type="radio"
                          value="Cheque"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          name="payment_type"
                          onChange={() => {
                            setOtherChequeFields(true);
                            setCashDetails({
                              ...cashDetails,
                              payment_type: "Cheque",
                            });
                          }}
                        />
                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text">
                          Cheque
                        </span>
                      </label>
                    </div>
                    <div
                      className={`others-cheque-fields
                       ${otherChequeFields && "active"}
                      `}
                    >
                      <div className="addons-list__item mt-1 mx-1">
                        <label htmlFor="name" className="form-label d-block">
                          {_t(t("Enter Cheque No."))}{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cheque_no"
                          name="cheque_no"
                          placeholder="Enter Cheque No."
                          value={cashDetails.cheque_no}
                          onChange={(e) => {
                            setCashDetails({
                              ...cashDetails,
                              cheque_no: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="addons-list__item mt-1 mx-1 mb-3">
                        <label htmlFor="name" className="form-label d-block">
                          {_t(t("Enter issue cheque date"))}{" "}
                        </label>
                        <DatePicker
                          selected={cashDetails.cheque_date}
                          onChange={(date) => {
                            setCashDetails({
                              ...cashDetails,
                              cheque_date: date,
                            });
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control sm-text py-2 t-mb-15 mb-md-0"
                          placeholderText={_t(t("Enter issue cheque date"))}
                          shouldCloseOnSelect={false}
                        />
                      </div>
                    </div>
                    <div className="addons-list__item mt-1 mx-1">
                      <label htmlFor="name" className="form-label d-block">
                        {_t(t("Enter Amount"))}{" "}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="name"
                        name="name"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        placeholder="Enter Amount"
                        value={cashDetails.amount}
                        required
                        onChange={(e) => {
                          setCashDetails({
                            ...cashDetails,
                            amount: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="addons-list_item mt-1 mx-1">
                      <textarea
                        name="remarks"
                        className="form-control"
                        placeholder="Remarks"
                        value={cashDetails.remarks}
                        onChange={(e) => {
                          setCashDetails({
                            ...cashDetails,
                            remarks: e.target.value,
                          });
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success font-weight-bold w-100 sm-text text-uppercase t-width-max"
                          onClick={(e) => handleSubmitCash("pay-in")}
                          data-dismiss={`${
                            cashDetails.payment_type ? "modal" : ""
                          }`}
                        >
                          {_t(t("Pay-in"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary font-weight-bold w-100 sm-text text-uppercase t-width-max"
                          data-dismiss={`${
                            cashDetails.payment_type ? "modal" : ""
                          }`}
                          onClick={(e) => handleSubmitCash("pay-out")}
                        >
                          {_t(t("Pay out"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*back btn Modal*/}
      <div
        className={`modal fade ${backModal !== false ? "showing" : ""}`}
        id="cartEmpty"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-body">
              {/* show form or show saving loading */}
              <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
                <div>
                  <h2 htmlFor="name" className="form-label text-center my-2">
                    Are you sure?
                  </h2>
                  <p className="text-center mt-1 mb-4">
                    You want to cancel this order
                  </p>
                </div>

                <div className="mt-3 mb-1">
                  <div className="row d-flex justify-content-center w-100 m-0">
                    <div className="col-3 mr-1">
                      <button
                        type="button"
                        className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded-25"
                        data-dismiss="modal"
                        onClick={emptyCard}
                      >
                        {_t(t("Yes"))}
                      </button>
                    </div>
                    <div className="col-3 ">
                      <button
                        type="button"
                        className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded-25"
                        data-dismiss="modal"
                        onClick={(e) => {
                          setBackModal(false);
                        }}
                      >
                        {_t(t("No"))}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

       {/*back btn Modal*/}
       <div
       className={`modal fade ${noteRiderModal !== false ? "showing" : ""}`}
       id="cartEmpty"
       aria-hidden="true"
     >
       <div className="modal-dialog modal-md">
         <div className="modal-content">
           <div className="modal-header align-items-center">
             <div className="fk-sm-card__content"></div>
             <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => setNoteRiderModal(false)}
             ></button>
            </div>
           <div className="modal-body">
             {/* show form or show saving loading */}
             <form onSubmit={(e) => {
              e.preventDefault();
              setNoteRiderModal(false)
             }}autoComplete="off">
                <label htmlFor="noteToRider" className="form-label">
                    Rider Note <span style={{color:"red"}}>*</span>
                </label>
                <input
                  ref={focusPopUpInputField}
                  type="text"
                  className="form-control"
                  name = 'noteToRider'
                  id = 'noteToRider'
                  placeholder="rider note"
                  autoFocus
                  required
                  value={orderDetails.note_to_rider || ""}
                  onChange={(e)=>{
                    setOrderDetails({
                      ...orderDetails,
                      note_to_rider: e.target.value,
                    })
                  }}
                />

               <div className="mt-3 mb-1">
                 <div className="row d-flex justify-content-center w-100 m-0">
                   <div className="col-3 mr-1">
                     <button
                       type="submit"
                       className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded-25"
                       data-dismiss="modal"
                     >
                       {_t(t("Submit"))}
                     </button>
                   </div>
                   <div className="col-3 ">
                     <button
                       type="button"
                       className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded-25"
                       data-dismiss="modal"
                       onClick={(e) => {
                        setNoteRiderModal(false);
                        setOrderDetails({
                          ...orderDetails,
                          note_to_rider: "",
                        })
                       }}
                     >
                       {_t(t("Remove"))}
                     </button>
                   </div>
                 </div>
               </div>
             </form>
           </div>
         </div>
       </div>
     </div>

      {/*Reason Modal*/}
      <div
        className={`modal fade ${
          addReasonModalShowing !== null ? "showing" : ""
        }`}
        id="addReason"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => setAddReasonModalShowing(null)}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
                  <div>
                    <label htmlFor="name" className="form-label">
                      {_t(t("Reason"))}{" "}
                      <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      ref={focusPopUpInputField}
                      type="text"
                      className="form-control"
                      id="modal_reasons"
                      name="modal_reasons"
                      placeholder="e.g. Reason"
                      required
                      value={deleteReason}
                      onChange={(e) => setDeleteReason(e.target.value)}
                    />
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          onClick={(e) => {
                            addReasonOnSubmit(true);
                          }}
                        >
                          {_t(t("Made"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          onClick={(e) => {
                            addReasonOnSubmit(false);
                          }}
                        >
                          {_t(t("Unmade"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Card Modal */}
      <div
        className="modal fade"
        ref={cardModal}
        id="cardModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content" style={{ borderRadius: "1rem" }}>
            {/* <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="modal-body py-4">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete="off">
                  <div>
                    <div className="addons-list__item mt-1 mx-1">
                      <label
                        htmlFor="name"
                        className="form-label d-flex justify-content-between"
                      >
                        <span>{_t(t("Enter Card Number"))} </span>
                        <span
                          onClick={(e) => {
                            cardModal.current.classList.remove("showing");
                            const dis = !orderDetails?.capAmountMet
                              ? (cartSubTotal / 100) *
                                parseFloat(orderDetails.discount)
                              : parseFloat(orderDetails.discount);
                            setCartTotal(cartTotal + dis);
                            setDiscount(0);
                            
                            setOrderDetails((curr)=>{
                              const newData = {
                              ...curr,
                              discount: 0,
                              card_number: "",
                              scheme: {
                                scheme_id: null,
                                scheme_name: "",
                                cap_amount: 0,
                                remainAmount: 500,
                                discount_amount: null,
                                open_discount:null,
                                discount_type: "",
                                how_much_discount: null,
                              },
                             }
                              return newData
                            });
                            //setSchemeForSearchObj(null);
                          }}
                        >
                          <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                        </span>
                      </label>
                      <input
                        ref={focusPopUpInputField}
                        type="number"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}  
                        min="0"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter Card Number"
                        autoComplete="off"
                        maxLength={16}
                        value={orderDetails.card_number}
                        required
                        onChange={(e) => {
                          setOrderDetails({
                            ...orderDetails,
                            card_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-12 px-3">
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bold w-100 sm-text text-uppercase t-width-max"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCardSubmit(e);
                          }}
                        >
                          {_t(t("Add Card"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

       {/* payment type tax modal */}
       <div
       className={`modal fade ${
         paymentTypeModalState.showModal ? "showing" : ""
       }`}
       id="addPayment"
       aria-hidden="true"
     >
       <div className="modal-dialog modal-md">
         <div className="modal-content">
           <div className="modal-header align-items-center py-1">
             <div className="fk-sm-card__content">
               <h4>{paymentTypeModalState.payment_type_name}</h4>
             </div>
             <button
               type="button"
               className="btn-close"
               data-dismiss="modal"
               aria-label="Close"
               onClick={closePaymentTypeTax}
             ></button>
           </div>
           <div className="modal-body">
             {/* show form or show saving loading */}
             <div key="fragment-food-group-1">
               <div>
                 <table className="table mb-4 table-bordered table-striped">
                   <tbody>
                     <tr>
                       <th>{_t(t("Sub Total"))}</th>
                       <td className="font-bold text-center">
                         {currencySymbolLeft()}
                         {parseFloat(paymentTypeModalState.subTotal).toFixed(
                           2
                         )}
                         {currencySymbolRight()}
                       </td>
                     </tr>
                     <tr>
                       <th>
                         {_t(t("VAT"))} {`(${paymentTypeModalState.vat}%)`}
                       </th>
                       <td className="font-bold text-center">
                         {currencySymbolLeft()}
                         {parseFloat(paymentTypeModalState.vatAmount).toFixed(
                           2
                         )}
                         {currencySymbolRight()}
                       </td>
                     </tr>
                     <tr>
                       <th>
                         {_t(t("Service Charges"))}{" "}
                         {`(${paymentTypeModalState.serviceCharge})%`}
                       </th>
                       <td className="font-bold text-center">
                         {currencySymbolLeft()}
                         {parseFloat(
                           paymentTypeModalState.serviceChargeAmount
                         ).toFixed(2)}
                         {currencySymbolRight()}
                       </td>
                     </tr>
                     <tr>
                       <th>{_t(t("Discount"))}</th>
                       <td className="font-bold text-center">
                         {currencySymbolLeft()}
                         {parseFloat(paymentTypeModalState.discount).toFixed(
                           2
                         )}
                         {currencySymbolRight()}
                       </td>
                     </tr>
                     <tr>
                       <th>{_t(t("Delivery Charges"))}</th>
                       <td className="font-bold text-center">
                         {currencySymbolLeft()}
                         {paymentTypeModalState.deliveryCharges
                           ? parseFloat(
                               paymentTypeModalState.deliveryCharges
                             ).toFixed(2)
                           : 0}
                         {currencySymbolRight()}
                       </td>
                     </tr>
                     <tr>
                       <th>{_t(t("Total Amount"))}</th>
                       <td className="font-bold text-center">
                         {currencySymbolLeft()}
                         {parseFloat(
                           paymentTypeModalState.totalPayable
                         ).toFixed(2)}
                         {currencySymbolRight()}
                       </td>
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
           </div>
           <div className="modal-footer" style={{justifyContent: "end !important"}}>
             <div className="row m-0 py-2 px-0">
               <button
                 className="btn btn-primary"
                 onClick={closePaymentTypeTax}
               >
                 {_t(t("OK"))}
               </button>
             </div>
           </div>
         </div>
       </div>
     </div>

    </>
  );
};

export default PosModal;
