import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { useReactToPrint } from "react-to-print";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";
// import { FoodContext } from "../../../../contexts/Food";

const StockTransferReport = () => {
  const { t } = useTranslation();
    // const history = useHistory();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo, getSupplier } = useContext(UserContext);
    let { branchForSearch } = useContext(RestaurantContext);
    // let { foodForPOS } = useContext(FoodContext);    
  
    //all data
    const [reportData, setReportData] = useState(null);
    const componentRef = useRef();
    const componentRef1 = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [option,setOption] = useState("print");
    const [group,setGroup] = useState(null);

    const [branchSearch, setBranchSearch] = useState(null);
    useEffect(() => {        
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
          setBranchSearch(
            branchForSearch.filter(
              (branch) => branch.id === authUserInfo.details.branch_id
            )
          );
        } else {
          setBranchSearch(branchForSearch);
        }
    
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, [generalSettings]);

    let groupArr = [
        { id: 1, name: "Document No" },
        { id: 2, name: "Document Date" },
        { id: 3, name: "Branch" }
    ]

      // const thermalPrint = useReactToPrint({
      //   content: () => componentRefThermal.current,
      // });

      const handleBranch=(branch)=>{
        setBranch(branch)
      }     

      const handleOption  = (e) =>{
        setOption(e.target.value)
      }      
  
    //get excel reports filter
    const getExcelReport = () => {
      setReportData(null);
      setGeneratedReport(false) 
      if (        
        startDate !== null &&
        endDate !== null &&
        (group !== null && group?.name)
      ) {
        if(option==='print'){
            getReport()
        }else{
            getSummaryReport()
        }                 
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and group"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

    const getReport = async () => {
        setDataPaginating(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-stock-transfer-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch?.id ? branch.id : null);        
        formData.append("group_by", group.name);
        
        
        return axios
        .post(url,formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("ReportResult", res);
          setGeneratedReport(true);
          setReportData(res.data)
          setDataPaginating(false);
          componentRef.current.handleDownload();             
          })
          .catch((error) => {
            setDataPaginating(false);
            toast.error(
                `${_t(
                  t(
                    "Please try again"
                  )
                )}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
          }); 
    }

    const getSummaryReport = async () => {
        setDataPaginating(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-stock-transfer-summary-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch?.id ? branch?.id : null);        
        formData.append("group_by", group.name);
        
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("ReportResult", res);
            setGeneratedReport(true);
            setReportData(res.data)
            setDataPaginating(false);
            componentRef1.current.handleDownload();             
          })
          .catch((error) => {
            setDataPaginating(false);
            toast.error(
                `${_t(
                  t(
                    "Please try again"
                  )
                )}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
          }); 
    }

 
    console.log("abcd44",group)
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Stock Transfer Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Stock Transfer Report"
          sheet="sheet 1"
          buttonText="Stock Transfer Detail"
          ref={componentRef}
        />

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xlss"
          filename="Stock Transfer Report"
          sheet="sheet 1"
          buttonText="Stock Transfer Detail"
          ref={componentRef1}
        />          
  
         {/* Excel Report Details*/}
         <div className="d-none">
         <div>           
           <div className="fk-print">
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && group && (
                       <>
                         <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                           <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="12" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Stock Transfer Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                              {_t(t("Group Name"))}
                              </th>
                                  {group?.name !== "Document No"&&<th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Invoice No"))}
                                  </th>}
                                  {group?.name !== "Document Date" && <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>}
                                  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Item Name"))}
                                  </th>
                                  {group?.name !== "Branch" &&
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("From Branch"))}
                                  </th>}
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("To Branch"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Unit"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                  
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* {console.log("309 ", reportData)} */}
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).length>0 && Object.keys(reportData).map(
                                    (group_name, index) => {
                                      let totalQty = 0;
                                      return(
                                      <div key={index}>
                                    
                                      <tr>
                                      <th
                                        scope="row"
                                        className="lg-text text-capitalize align-middle text-center"
                                      >
                                        {group_name}
                                      </th>
                                    </tr>
                                {reportData[group_name]?.length > 0 && reportData[group_name].map((item, i) => {
                                  totalQty += parseFloat(item.qty);
                                  
                                  return (
                                    <tr>
                                    <td></td>
                                      {group?.name !== "Document No" && <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center "
                                      >
                                        {item.transfer_no}
                                      </td>}
                                      {group?.name !== "Document Date" && <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        <Moment format="LL">
                                          {item.tranfer_date}
                                        </Moment>
                                      </td>}
                                                                         
                                     <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.item_name}
                                      </td>
                                      {group?.name !== "Branch" &&
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.from_branch}
                                      </td>}
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.to_branch}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.unit}
                                      </td>                                     
                                     <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.qty}
                                      </td> 
                                                                                                                                                                                     
                                    </tr>
                                  );
                                })}
                                 <tr>                                  
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <th
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    Total
                                  </th>                                  
                                  <td
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                    <strong>
                                    {isNaN(totalQty) ? 0 : formatPrice(totalQty)}
                                    </strong>
                                </td>
                                <td></td>
                                                              
                                 </tr>
                                </div>
                                );
                              }
                              )
                             }
                              
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                           </div>
                         </div>
                       </>
                     )}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       {/* Excel Report Summary*/}
       <div className="d-none">
         <div>           
           <div className="fk-print">
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && group && (
                       <>
                         <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                           <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xlss"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="6" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Purchase Invoice Summary Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                              {_t(t("Group Name"))}
                              </th>
                                  {group?.name !== "Document No"&&<th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Invoice No"))}
                                  </th>}
                                  {group?.name !== "Document Date" && <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>}
                                 
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Item Name"))}
                                  </th>

                                  {group?.name !== "Branch" &&
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("From Branch"))}
                                  </th>}
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("To Branch"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Unit"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* {console.log("309 ", reportData)} */}
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).length > 0 && Object.keys(reportData).map(
                                    (group_name, index) => {
                                      let totalQty = 0;
                                      return(
                                      <div key={index}>
                                    
                                      <tr>
                                      <th
                                        scope="row"
                                        className="lg-text text-capitalize align-middle text-center"
                                      >
                                        {group_name}
                                      </th>
                                    </tr>
                                {reportData[group_name]?.length > 0 &&reportData[group_name].map((item, i) => {
                                  totalQty += parseFloat(item.qty);
                                 
                                  return (
                                    <tr>
                                    <td></td>
                                      
                                    {group?.name !== "Document No" && <td
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center "
                                  >
                                    {item.transfer_no}
                                  </td>}
                                  {group?.name !== "Document Date" && <td
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    <Moment format="LL">
                                      {item.tranfer_date}
                                    </Moment>
                                  </td>}
                                 
                                                                     
                                 <td
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    {item.item_name}
                                  </td>

                                  {group?.name !== "Branch" &&
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.from_branch}
                                      </td>}
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.to_branch}
                                      </td>
                                  <td
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    {item.unit}
                                  </td>                                     
                                 <td
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    {item.qty}
                                  </td> 
                                 </tr>
                                  );
                                })}
                                 <tr>                                  
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <th
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    Total
                                  </th>                                  
                                  <td
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                    <strong>
                                  {isNaN(totalQty) ? 0 : formatPrice(totalQty)}
                                  </strong>
                                </td>
                                                               
                                 </tr>
                                </div>
                                );
                              }
                              )
                             }
                        </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                           </div>
                         </div>
                       </>
                     )}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     
                      {/* spin loading ends */}
  
                      {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}
                      
                        <div key="smtp-form">
                          <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="row t-mb-15 mb-md-0" >
                              <ul className="t-list fk-breadcrumb col-6">
                                <li className="fk-breadcrumb__list">
                                  <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Stock Transfer Report"))}
                                  </span>
                                </li>
                              </ul>
                              <div className="col-6 d-flex justify-content-end p-0">
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="print"
                                  name="print"
                                  value="print"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "print"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="print"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Print"))}
                                </label>
                              </div>

                              <div className="d-inline mr-2 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="print_summary"
                                  name="print_summary"
                                  value="print_summary"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "print_summary"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="print_summary"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Print Summary"))}
                                </label>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={false}                                
                              />
                            </div>

                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>                                                                                 
  
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={branch}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>                            

                            <div className="col-3">
                              <Select
                                options={groupArr && groupArr}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={group}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={(group)=>setGroup(group)}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select group")) + ".."}
                              />
                            </div> 

                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getExcelReport()}
                              >
                               GENERATE REPORT
                              </button>
                            </div>
                          </div>                          

                          {/* <div className="row d-flex justify-content-end mt-3">
                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getExcelReport("excel")}
                              >
                               MENU MIX REPORT
                              </button>
                            </div>
                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getExcelReport("thermal")}
                              >
                               MENU MIX PDF
                              </button>
                            </div>
                          </div>                        */}
                          
                        </div>                                          
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
  
      </>
    );
};

export default StockTransferReport;
