import React, { useState, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import GoodsReport from "./GoodsReport";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
// import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}

const FinishGoodsAcceptance = () => {
    const { t } = useTranslation();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);

    const {
      branchForSearch,
    } = useContext(RestaurantContext) 

    let {

      //finishedGoodsAcceptance
      finishedGoodsAcceptanceList,
      setFinishedGoodsAcceptanceList,
      setPaginatedFinishGoodAcceptanceList,
      finishedGoodsAcceptanceListForSearch,
      setFinishedGoodsAcceptanceListForSearch,

      //pagination
      dataPaginating,
    } = useContext(UserContext);
  
    // States hook here
    //search result
    let [searchedGoods, setSearchedGoods] = useState({
      list: null,
      searched: false,
    });
  
    //search purchase here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0) {
        setSearchedGoods({ ...searchedGoods, searched: false });
      } else {
        let searchedList = finishedGoodsAcceptanceListForSearch.filter((item) => {
          //name
          let lowerCaseItemIdentity = item.document_identity?.toLowerCase();
  
          //email
          let lowerCaseItemBranch =
            item.from_branch_name !== null && item.from_branch_name.toLowerCase();
  
          return (
            (lowerCaseItemIdentity && lowerCaseItemIdentity.includes(searchInput)) ||
            (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
          );
        });
        setSearchedGoods({
          ...searchedGoods,
          list: searchedList,
          searched: true,
        });
      }
    };

    //print bills states and function
    const [newRow,setNewRow]=useState([]);
    const [documentDate,setDocumentDate]=useState(new Date());
    let [newFinishGoods, setNewFinishGoods] = useState("");
    const componentRef = useRef();
    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,      
    });

    const handleGetPrintData = async(id) => {
      const url =
      BASE_URL +
        "/settings/get-finished-good-transfer-edit/" +
        parseInt(id);
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });          
            if(res.data){
              let date = new Date(res.data[0]?.document_date);
              setDocumentDate(date);
              let branch=branchForSearch?.find((item)=>item.id === res.data[0].from_branch_id)
              let toBranch=branchForSearch?.find((item)=>item.id === res.data[0].to_branch_id)                      
              setNewFinishGoods({
                ...newFinishGoods,
                branch,
                toBranch,
                remarks: res.data[0]?.remarks,
                identity: res.data[0]?.document_identity,   
              })
              let newArr=[];
              if(res.data[1] && res.data[1]?.length>0){
                res.data[1].map((item)=>{
                  newArr.push(item)
                })
                setNewRow([...newArr])
              }
            }
            handleOnlyPrint();            
            
          } catch(error) {
            console.log("error==>",error)
          }
    }

  return (
    <>
    <Helmet>
      <title>{_t(t("Finished Goods Acceptance"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ManageSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div className="fk-scroll--pos-table-menu" data-simplebar>
                <div className="t-pl-15 t-pr-15">
                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="fragment3">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* spin loading ends */}

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {!searchedGoods.searched
                                  ? _t(t("Goods Acceptance List"))
                                  : _t(t("Search Result"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center">
                            {/* Search customer */}
                            <div className="col-md-3 t-mb-15 mb-md-0"></div>
                            <div className="col-md-9 t-mb-15 mb-md-0">
                              <div className="input-group">
                                <div className="form-file">
                                  <input
                                    type="text"
                                    className="form-control border-0 form-control--light-1 rounded-left"
                                    placeholder={_t(t("Search")) + ".."}
                                    onChange={handleSearch}
                                  />
                                </div>
                                <button
                                  className="btn btn-primary rounded-right"
                                  type="button"
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>                      
                          </div>
                        </div>
                      </div>
                      {/* Table */}
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Transfer No"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Transfer Date"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searchedGoods.searched
                              ? [
                                finishedGoodsAcceptanceList && [
                                    finishedGoodsAcceptanceList.data.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="7"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    finishedGoodsAcceptanceList.data.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (finishedGoodsAcceptanceList.current_page -
                                                  1) *
                                                  finishedGoodsAcceptanceList.per_page}
                                            </th>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item?.document_identity}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                            <Moment format="LL">
                                              {item.document_date}
                                              </Moment>
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">                                              
                                              {item.from_branch_name}                                              
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">                                              
                                              <div className="d-flex justify-content-center">
                                                {item.is_acceptance !== 1 ? 
                                              <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/finished-goods-acceptance-edit/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink> :                                                 
                                                 <NavLink
                                                  className="btn btn-warning ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/finished-goods-acceptance-view/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-eye edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>}
                                                 <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print edit-icon"></i>
                                                  </button>
                                                 </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]
                              : [
                                /* searched data, logic === haveData*/
                                searchedGoods && [
                                  searchedGoods.list.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="7"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    searchedGoods.list.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (finishedGoodsAcceptanceList.current_page -
                                                  1) *
                                                  finishedGoodsAcceptanceList.per_page}
                                            </th>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item?.document_identity}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                            <Moment format="LL">
                                              {item.document_date}
                                              </Moment>
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">                                              
                                              {item.from_branch_name}                                              
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">                                              
                                              <div className="d-flex justify-content-center"> 
                                              {item.is_acceptance !== 1 ? 
                                              <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/finished-goods-acceptance-edit/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink> :                                                 
                                                 <NavLink
                                                  className="btn btn-warning ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/finished-goods-acceptance-view/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-eye edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>}
                                                 <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print edit-icon"></i>
                                                  </button>
                                                 </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  {/* )} */}
                </div>
              </div>
            </div>

            {/* pagination loading effect */}
            {loading === true
              ? paginationLoading()
              : [
                // logic === !searched
                !searchedGoods.searched ? (
                  <div key="fragment4">
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          {/* pagination function */}
                          {pagination(
                            finishedGoodsAcceptanceList,
                            setPaginatedFinishGoodAcceptanceList
                          )}
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {showingData(finishedGoodsAcceptanceList)}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  // if searched
                  <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                    <div className="row align-items-center t-pl-15 t-pr-15">
                      <div className="col-md-7 t-mb-15 mb-md-0">
                        <ul className="t-list d-flex">
                          <li className="t-list__item no-pagination-style">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                setSearchedGoods({
                                  ...searchedGoods,
                                  searched: false,
                                })
                              }
                            >
                              {_t(t("Clear Search"))}
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-5">
                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                          <li className="t-list__item">
                            <span className="d-inline-block sm-text">
                              {searchedShowingData(
                                searchedGoods,
                                finishedGoodsAcceptanceListForSearch
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ),
              ]}
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
    <GoodsReport
        name = "Finished Goods Acceptance"
        documentDate={documentDate}
        newFinishGoods={newFinishGoods}
        newRow={newRow}
        stockPrint={componentRef}
    />
  </>
  )
}

export default FinishGoodsAcceptance;