import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { useReactToPrint } from "react-to-print";
// import StockReport from "./StockReport";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
const StoreRequisitionAdd = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
      ingredientItemForSearch,
    } = useContext(RestaurantContext);
  
    let {
      //store requisition
      setStoreRequisitionList,
      setStoreRequisitionForSearch,
      authUserInfo
    } = useContext(UserContext);

    const [branchSearch, setBranchSearch] = useState(null);

    useEffect(()=>{
      if (authUserInfo?.details.user_type === "manager") {
        setBranchSearch(
          branchForSearch.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
    },[]);

    // States hook here
    //new item
    let [newStoreRequisition, setNewStoreRequisition] = useState({
      document_no: "",
      fbranch: null,
      tbranch: null,     
      remarks: "",            
    //   item_amount: 0,
    });
    //new Row
    const [newRow,setNewRow]=useState([]);

    let common_toast = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }
  
    //select style
    
 const customStyle = {
  control: (provided) => ({
    ...provided,
    minHeight: "2rem",
    marginTop: "0",
    marginBottom: "0",
  }),
  placeholder:(provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    alignSelf: "center",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 0.4rem",
    svg: {
      height: "2rem",
    },
  }),
};
    //print bills
    const componentRef = useRef();
  
    //datepicker
    const [documentDate, setDocumentDate] = useState(new Date());

    //set items hook
    const handleSetItems = async (index,item) => {
      newRow[index].unit="";
    //   newRow[index].base_unit="";
    //   newRow[index].base_qty="";
    //   newRow[index].conversion_qty="";
      newRow[index].item_name=item;
      newRow[index].base_unit=item.unit_id;
      newRow[index].item_units=""
      setNewRow([...newRow]);
      const url = BASE_URL + "/settings/get-unit_item/"+item.id;
      await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
      .then((res) => {
        if(res.data){
        newRow[index].item_units = res.data;
        setNewRow([...newRow]);
        }      
      }).catch((error) =>{
        console.log("check error",error);
      })
    };
  
    const handleSetUnit=(index,unit)=>{
      newRow[index].unit=unit;
      newRow[index].conversion_qty=parseFloat(unit.qty).toFixed(2);
      setNewRow([...newRow]);
    }
  
    // const calBaseQty=(index)=>{
    //   let qty=newRow[index].qty;
    //   let conversion_qty=newRow[index].conversion_qty
    //   if(qty && conversion_qty){
    //     return qty * conversion_qty;
    //   }else{
    //     return "";
    //   }
    // }
  
    // const handleOnlyPrint = useReactToPrint({
    //   content: () => componentRef.current,
    // });
  
    const [rowId,setRowId]=useState(0);
    const addNewRow=()=>{
      if(newStoreRequisition.fbranch !== null && newStoreRequisition.tbranch !== null ){
        setNewRow((pre)=>[...pre,{
          id:rowId,
          item_name:"",
          qty:"",
          unit:"",
          item_units:"",
        //   rate:"",
        //   amount:"",
        //   total:"",
        //   base_unit:"",
        // base_qty:"",
        // conversion_qty:""
        }])
        setRowId(rowId+1);
      }else{
        toast.error(`${_t(t("Please select both branches"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }   
    }
  
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
    }
  
    // total amount
    // const handleAmount = () => {
    //   let total = 0;
    //   if (newRow !== null && newRow.length>0) {
    //     newRow.map((item) => {        
    //       if (parseFloat(item.qty) && parseFloat(item.rate)) {
    //         total = total +  parseFloat(item.qty) * parseFloat(item.rate);
    //       }
    //     });
    //   }
    //   return total;
    // };
  
    //subtotal
    // const handleGetAmount = (index) => {
    //   let qty = 0;
    //   let rate = 0;
    //   if (newRow !== null && newRow[index].qty !== "") {
    //     qty = parseFloat(newRow[index].qty);
    //   }
    //   if (newRow !== null && newRow[index].rate !== "") {
    //     rate = parseFloat(newRow[index].rate);
    //   }
    //   return rate * qty;
    // };
  
    //set each item qty
    const handleItemQty = (index,e) => {
      newRow[index].qty=e.target.value;
      setNewRow([...newRow]);
    };
  
    //set each item qty
    // const handleItemRate = (index,e) => {
    //   newRow[index].rate=e.target.value;
    //   setNewRow([...newRow]);
    // };
  
    //handle Set branch hook
    const handleSetBranch = (fbranch) => {
      newStoreRequisition.tbranch = null;
      setNewStoreRequisition({ ...newStoreRequisition, fbranch });
    };

    const handleSetToBranch = (tbranch) => {
      // newStoreRequisition.tbranch = null;
      setNewStoreRequisition({ ...newStoreRequisition, tbranch });
    };

    const handleRemarks=(e)=>{
        setNewStoreRequisition({ ...newStoreRequisition, remarks:e.target.value });
    }
  
    //post req of stock item add
    const storeItemAxios = async () => {
      let date =
        new Date(documentDate).getFullYear() +
        "-" +
        (new Date(documentDate).getMonth() + 1) +
        "-" +
        new Date(documentDate).getDate();
  
      let slugArray=[];
      newRow.map((newStoreItem,index) => {
        if(newStoreItem.item_name === "" || newStoreItem.unit===""){
        //   toast.error(`${_t(t("Please select all dropdowns"))}`, common_toast);
        slugArray.push();
        }
        else{
          slugArray.push({
            qty: parseInt(newStoreItem.qty),
            // price: parseFloat(newStoreItem.rate),
            item_id: newStoreItem.item_name.id,
            item_name: newStoreItem.item_name.name,          
            unit_id: newStoreItem.unit.id,
            unit_name: newStoreItem.unit.name,
            // amount: handleGetAmount(index),          
            // base_unit: newStoreItem.base_unit,
            // base_qty: calBaseQty(index),
            // conversion_qty: parseFloat(newStoreItem.conversion_qty)
          });
        }
        });
        
        if(newRow.length === slugArray.length){
        //   setLoading(true);
          let formData = {
            branch_id: newStoreRequisition.branch.id,     
            document_date: date,
            remarks: newStoreRequisition.remarks,
            item: slugArray,
            // total_amount: handleAmount(),
          };
      // handleOnlyPrint();
      console.log("check form data==>",formData);
  
      const url = BASE_URL + "/settings/storeRequisition";
      
    //   try {
    //       const res = await axios.post(url, formData, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     });        
    //     setStoreRequisitionList(res.data[0]);
    //     setStoreRequisitionForSearch(res.data[1]);
    //     setLoading(false);
    //     toast.success(`${_t(t("Store Requisition has been added"))}`, {
    //       position: "bottom-center",
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //   history.push(
    //     "/dashboard/manage/stock/store-requisition"
    //   )
    //   } catch (error) {        
    //     console.log("check error==>",error);
    //     setLoading(false);
    //     toast.error(`${_t(t("Please try again later"))}`, common_toast);
    //   }
    }else{
        toast.error(`${_t(t("Please select all dropdowns"))}`, common_toast);
    }
  };
  
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();
      if (
        newStoreRequisition.fbranch !== null &&
        newStoreRequisition.tbranch !== null &&
        newStoreRequisition.document_no !== null &&
        documentDate !== null
      ) {
        if(newRow.length > 0){
          storeItemAxios();
        }
        else{
          toast.error(`${_t(t("Please add stocks"))}`, common_toast);
        }
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`,common_toast);
      }
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Add Store Requisition"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
  
                      {/* Loading effect */}
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Add Store Requisition"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                          >
                            <div className="col-12">
                            <div className="row">
                                <div className="form-group col-4 mt-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="document no"
                                      className="control-label"
                                    >
                                      {_t(t("Document no"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control table-text"
                                      id="document_no"
                                      name="document_no"                                      
                                      placeholder="Auto no"
                                      required
                                    />
                                  </div>
                                </div>
  
                                <div className="form-group col-4 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="transfer_date"
                                      className="control-label"
                                    >
                                      {_t(t("Document Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={documentDate}
                                      onChange={(date) => setDocumentDate(date)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("Document date"))}
                                      shouldCloseOnSelect={false}
                                      required
                                    />
                                  </div>
                                </div>
                                
                                <div className="form-group mt-3 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("From Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchSearch ? branchSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newStoreRequisition.fbranch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                    />
                                </div> 
                              </div>
  
                              <div className="row">
                              <div className="form-group mt-2 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("To Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchForSearch ? branchForSearch.filter((item)=>item.id!==newStoreRequisition?.fbranch?.id) : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newStoreRequisition.tbranch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetToBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                      isDisabled={newStoreRequisition?.fbranch ? false : true}
                                    />
                                </div>                                                            
                                <div className="form-group mt-2 col-8">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="remarks"
                                      className="control-label"
                                    >
                                      {_t(t("Remarks"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <textarea
                                      type="text"
                                      className="form-control table-text"
                                      id="remarks"
                                      name="remarks"
                                      onChange={handleRemarks}
                                      value={newStoreRequisition.remarks || ""}
                                      placeholder="Remarks"
                                      style={{height:"33px"}}
                                    />
                                  </div>
                                </div>
                              </div>                            
                              
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-Child"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                          </span>
                                        </th>

                                        {/* <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Rate"))}
                                          </span>
                                        </th> */}

                                        {/* <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Amount"))}
                                          </span>
                                        </th>                                       */}
                                        
                                        {/* <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base unit"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base qty"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:"9rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Conversion unit"))}
                                          </span>
                                        </th> */}
                                        {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                          onClick={()=>{delRow(item.id)}}
                                          ></i>
                                          </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                              <Select
                                                options={ingredientItemForSearch !== null ? ingredientItemForSearch : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleSetItems(index,e)}
                                                maxMenuHeight="12.8rem"                                                
                                                value={item.item_name}
                                                styles={customStyle}
                                                isMenuOpen={true}
                                                menuPosition="fixed"
                                                className="bank-table-input"                                              
                                                placeholder={
                                                _t(t("Please select items")) + ".."
                                                }                                                
                                              />
                                              </td>                                              

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={item.id}
                                                  name={item.id}
                                                  onChange={(e)=>handleItemQty(index,e)}
                                                  value={item.qty || ""}
                                                  placeholder="Qty"
                                                  required                                                  
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"200px"}}>                                                
                                                <Select
                                                  menuPosition="fixed"
                                                  options={item.item_units ? item?.item_units.filter((value)=>value.id === item?.base_unit) : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  value={item.unit}
                                                  classNamePrefix="select"
                                                  onChange={(e)=>handleSetUnit(index,e)}
                                                  className="bank-table-input"
                                                  maxMenuHeight="200px"
                                                  styles={customStyle}                                                  
                                                  placeholder={
                                                  _t(t("please select unit")) + ".."
                                                  } 
                                                  isDisabled={item.item_units?false:true}                                                  
                                                />
                                              </td>
                                              {/* <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={stockItem.id}
                                                  name={stockItem.id}
                                                  onChange={(e)=>handleItemRate(index,e)}
                                                  value={stockItem.rate || ""}
                                                  placeholder="Price"
                                                  required                                                  
                                                />
                                              </td> */}
                                              {/* <td className="table-text align-middle text-center">
                                                {formatPrice(
                                                  handleGetAmount(                                                    
                                                    index
                                                  )
                                                )}
                                              </td>                                               */}

                                              {/* hidden columns */}
                                              
                                              {/* <td className="table-text align-middle text-center">
                                                {stockItem.base_unit}
                                              </td> 
                                              
                                              <td className="table-text align-middle text-center">                                                
                                                {formatPrice(calBaseQty(index))}
                                              </td>
                                              
                                              <td className="table-text align-middle text-center">
                                                {stockItem.conversion_qty}
                                              </td>  */}

                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(item.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}
                                    {/* {newRow && newRow.length>0 ?
                                    <tfoot className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          colSpan="1"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        ></th>

                                        <th
                                          scope="col"
                                          colSpan="3"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        ></th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                              {_t(t("Total"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(handleAmount())}
                                          </span>
                                        </th>                                        

                                        <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      </tr>
                                    </tfoot>
                                    :null} */}
                                  </table>
                              </div>                                                            

                              <div className="d-flex justify-content-end align-items-center">
                                <div className="col-3 d-flex justify-content-end">
                                    <button
                                    type="button"
                                    className="btn rounded hov-btn mr-3"
                                    onClick={()=>(history.push("/dashboard/manage/stock/store-requisition"))}
                                    //   data-dismiss="modal"
                                    >
                                        <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                    </button>
                                    <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary rounded"
                                    >
                                        <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                    </button>                                                    
                                </div>                                                    
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
       {/* <StockReport
       name = "Purchase Invoice"
       documentDate={documentDate}
       newItem={newItem}
       newRow={newRow}
      //  qtyOfItem={qtyOfItem}
      //  rateOfItem={rateOfItem}
       handleGetTotal={handleGetTotal}
       handleAmount={handleAmount}
       handleTaxAmount={handleTaxAmount}
       handleDisAmount={handleDisAmount}
       handleGetSubTotal={handleGetSubTotal}
       handleGetAmount={handleGetAmount}
       stockPrint={componentRef}
       /> */} 
      </>
    );
}

export default StoreRequisitionAdd;