import React, { useEffect, useContext, useState,useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import './stock.css'

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food";

const IngredientPurchaseEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params=useParams();
  //getting context values here
  let {
    loading,
    setLoading,
    branchForSearch,
    getIngredientItem,
    ingredientItemForSearch,
    dataPaginating,
  } = useContext(RestaurantContext);
  let { getSupplier, supplierForSearch, authUserInfo } = useContext(UserContext);
  let { workPeriodWeb, getFoodWeb } = useContext(FoodContext);
  // States hook here
  //new item
  let [newItem, setNewItem] = useState({
    branch: null,
    supplier: null,
    invoice: "",
    description: "",
    paymentType: "cash",
    items: [],
    paid: null,
    total: null,
    due: null,
    item_amount: 0,
    item_dis: 0,
    item_tax: 0,
  });
  //new Row
  const [newRow,setNewRow]=useState([]);
  //edit account
  const [editPurchase,setEditPurchase]=useState(null);
  
  //select style
  
 const customStyle = {
  control: (provided) => ({
    ...provided,
    minHeight: "2rem",
    marginTop: "0",
    marginBottom: "0",
  }),
  placeholder:(provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    alignSelf: "center",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 0.4rem",
    svg: {
      height: "2rem",
    },
  }),
};
  
  //datepicker
  const [purchaseDate, setPurchaseDate] = useState(new Date());

  //Get Specific item for edit
  const getPurchasedItem = async () => {
    setLoading(true);
    const url =
    BASE_URL +
      "/settings/get-ingredient_purchase_items/" +
      parseInt(params.id);
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });        
          if(res){
            setEditPurchase(res.data)
          }         
          
          setLoading(false);
        } catch {
          setLoading(false);
        }
      };
      
      const [branchSearch, setBranchSearch] = useState(null);
      //useEffect == componentDidMount()
      useEffect(() => {
        getFoodWeb();
        getSupplier();
        getIngredientItem();
        getPurchasedItem();

        //branch condition
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
          setBranchSearch(
            branchForSearch.filter(
              (branch) => branch.id === authUserInfo.details.branch_id
            )
          );
        } else {
          setBranchSearch(branchForSearch);
        }
      }, []);
      
    let rowId=useRef(0);
    useEffect(()=>{
  if(editPurchase && editPurchase !== null){
    let docDate=new Date(editPurchase[1].purchase_date);
    let branch=branchForSearch?.find((item)=>editPurchase[1].branch_id === item.id)
    let suppAcc=supplierForSearch?.find((item)=>editPurchase[1].supplier_id === item.id)
    setNewItem({
      ...newItem,
      invoice:editPurchase[1].invoice_number,
      branch,
      supplier:suppAcc,
      paymentType:editPurchase[1].payment_type,
      description:editPurchase[1].desc,
    })
    setPurchaseDate(docDate);
    let rowArr=[];
    if(editPurchase[0] && editPurchase[0].length > 0){
      editPurchase[0].map((item,index)=>{
        let ing_item=ingredientItemForSearch?.find((v)=>v.id===item.ingredient_id);
        rowArr.push({
          id:rowId.current,
          item_name:ing_item,
          qty:item.qty,
          unitId:item.unit_id,
          unit:item.unit_name,
          rate:item.rate,
          amount:item.total_amount,
          discount_percent:parseFloat(item.discount_percent).toFixed(2),
          discount:item.discount,
          tax_percent:parseFloat(item.tax_percent).toFixed(2),
          tax:item.tax, 
          total:item.total,
          base_unit:item.base_unit,
          base_qty:item.base_qty,
          conversion_qty:parseFloat(item.conversion_qty).toFixed(2),
          base_price: item.base_price,
          isEdit:true,
        })
        rowId.current += 1
      })
      setNewRow([...rowArr]);
    }
  }
  },[editPurchase,supplierForSearch,branchForSearch])

  //on change input field
  const handleChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  //set items hook
  const handleSetItems = async (index,item) => {
    newRow[index].unit="";
    newRow[index].base_unit="";
    newRow[index].base_qty="";
    newRow[index].conversion_qty="";
    newRow[index].item_name=item;
    newRow[index].base_unit=item.unit_id;
    newRow[index].item_units="";
    setNewRow([...newRow]);
    getUnits(item.id,index)
  };

  const getUnits=async(item,index)=>{
    const url = BASE_URL + "/settings/get-unit_item/"+item;
    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
    .then((res) => {      
      if(res.data){        
        newRow[index].item_units = res.data;
        setNewRow([...newRow]);
      }      
    }).catch((error) =>{
      console.log("check error",error);
    })
  }

  const handleSetUnit=(index,unit)=>{    
    newRow[index].unit=unit;
    newRow[index].conversion_qty=unit.qty;
    setNewRow([...newRow]);
  }

  const calBaseQty=(index)=>{
    let qty=newRow[index].qty;
    let conversion_qty=newRow[index].conversion_qty
    if(qty && conversion_qty){
      return qty * conversion_qty;
    }else{
      return "";
    }
  }

  const calBasePrice=(index)=>{
    let amount = handleGetAmount(index);
    let baseQty = calBaseQty(index);
    if(amount && baseQty){
      return amount/baseQty;
    }else{
      return 0;
    }
  }
  
  const addNewRow=()=>{ 
    let newID=rowId.current    
    setNewRow((pre)=>[...pre,{
      id:newID,
      item_name:"",
      qty:"",
      unit:"",
      rate:"",
      amount:"",
      discount_percent:"",
      discount:"",
      tax_percent:"",
      tax:"", 
      total:"",
      base_unit:"",
      base_qty:"",
      conversion_qty:"",
      isEdit:false,
    }])    
    rowId.current += 1
  }

  const delRow=(id)=>{
    let row=[...newRow]
    row= row.filter((item,i)=>id!==item.id);    
    setNewRow(row);
  }

  //subtotal
  const handleGetSubTotal = (index) => {
    let qty = 0;
    let rate = 0;
    let tax = 0;
    let discount = 0;

    if (newRow !== null && newRow[index].qty) {
      qty = parseFloat(newRow[index].qty);
    }
    if (newRow !== null && newRow[index].rate  !== "") {
      rate = parseFloat(newRow[index].rate);
    }
    if (newRow !== null && newRow[index].tax_percent !== "") {
      tax = parseFloat(newRow[index].tax_percent);
    }
    if (newRow !== null && newRow[index].discount_percent !== "") {
      discount = parseFloat(newRow[index].discount_percent);
    }    
    return (
      rate * qty + rate * qty * (tax / 100) - rate * qty * (discount / 100)
    );
  };

  // discount
  const handleDiscountItem = (index) => {
    let discount = 0;
    let rate = 0;
    let qty = 0;

    if (newRow !== null && newRow[index].qty) {
      qty = parseFloat(newRow[index].qty);
    }

    if (newRow !== null && newRow[index].discount_percent !== "") {
      discount = parseFloat(newRow[index].discount_percent);
    }
    if (newRow !== null && newRow[index].rate  !== "") {
      rate = parseFloat(newRow[index].rate);
    }
    return rate * qty * (discount / 100);
  };

  const handleTaxItem = (index) => {
    let tax = 0;
    let rate = 0;
    let qty = 0;

    if (newRow !== null && newRow[index].qty) {
      qty = parseFloat(newRow[index].qty);
    }

    if (newRow !== null && newRow[index].tax_percent !== "") {
      tax = parseFloat(newRow[index].tax_percent);
    }

    if (newRow !== null && newRow[index].rate  !== "") {
      rate = parseFloat(newRow[index].rate);
    }
    return rate * qty * (tax / 100);
  };

  const handleDisAmount = () => {
    let discount = 0;
    if (newRow !== null && newRow.length>0) {
      newRow.map((item) => {
        if (          
          item.discount_percent !== null && item.item_discount !== ""
          ) {
          discount =
            discount +
            item.rate *
              item.qty *
              (item.discount_percent / 100);
        }
      });
    }   
    return discount;
  };

  const handleTaxAmount = () => {
    let tax = 0;
    if (newRow !== null && newRow.length>0) {
      newRow.map((item) => {
        if (          
          item.tax_percent !== null && item.tax_percent !== ""
          ) {
          tax =
            tax +
            item.rate *
              item.qty *
              (item.tax_percent / 100);
        }
      });
    }
    return tax;
  };

  // total amount
  const handleAmount = () => {
    let total = 0;
    if (newRow !== null && newRow.length>0) {
      newRow.map((item) => {        
        if (
          parseFloat(item.qty) && parseFloat(item.rate)
        ) {
          total = total +  parseFloat(item.qty) * parseFloat(item.rate);
        }
      });
    }
    return total;
  };

  //total
  const handleGetTotal = () => {
    let total = 0;
    let tax = 0;
    let discount = 0;
    if (newRow !== null && newRow.length>0) {
      newRow.map((item) => {
        if (          
          item.tax_percent !== null && item.tax_percent !== ""
          ) {
          tax =
            item.rate *
            item.qty *
            (item.tax_percent / 100);
          
        }
        if (          
          item.discount_percent !== null && item.discount_percent !== ""
          ) {
          discount =
          item.rate *
          item.qty *
            (item.discount_percent / 100);
        }
        if (
          parseFloat(item.qty) && parseFloat(item.rate)
        ) {          
          total =
            total + item.qty * item.rate + tax - discount;
        }
      });
    }
    return total;
  };

  //subtotal
  const handleGetAmount = (index) => {
    let qty = 0;
    let rate = 0;
    if (newRow !== null && newRow[index].qty !== "") {
      qty = parseFloat(newRow[index].qty);
    }
    if (newRow !== null && newRow[index].rate !== "") {
      rate = parseFloat(newRow[index].rate);
    }
    return rate * qty;
  };

  //set each item qty
  const handleItemQty = (index,e) => {
    newRow[index].qty=e.target.value;
    setNewRow([...newRow]);
  };

  //set each item qty
  const handleItemRate = (index,e) => {
    newRow[index].rate=e.target.value;
    setNewRow([...newRow]);
  };

  //tax
  //set each item qty
  const handleTax = (index,e) => {
    newRow[index].tax_percent=e.target.value;
    setNewRow([...newRow]);
  };
  //discount
  const handleDiscount = (index,e) => {
    newRow[index].discount_percent=e.target.value;
    setNewRow([...newRow]);
  };

  //handle Set branch hook
  const handleSetBranch = (branch) => {
    // let theWorkPeriod =
    //   workPeriodWeb &&
    //   workPeriodWeb.find((thisItem) => {
    //     return parseInt(thisItem.branch_id) === parseInt(branch.id);
    //   });
    // if (theWorkPeriod !== undefined) {
    //   setNewItem({ ...newItem, branch });
    // } else {
    //   setNewItem({ ...newItem, branch: null });
    // }
    setNewItem({ ...newItem, branch });
  };

  //handle Set supplier hook
  const handleSetSupplier = (supplier) => {
    setNewItem({ ...newItem, supplier });
  };

  //post req of food item add
  const ingredientItemAxios = async () => {    
    let date =
      new Date(purchaseDate).getFullYear() +
      "-" +
      (new Date(purchaseDate).getMonth() + 1) +
      "-" +
      new Date(purchaseDate).getDate();

    let slugArray=[];
    newRow.map((newIngredientItem,index) => {
      if(newIngredientItem.item_name === "" || newIngredientItem.unit===""){
        toast.error(`${_t(t("Please select all dropdowns"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        
      }
      else{
        slugArray.push({
          qty: parseInt(newIngredientItem.qty),
          price: parseFloat(newIngredientItem.rate),
          item_id: newIngredientItem.item_name.id,
          item_name: newIngredientItem.item_name.name,          
          unit_id: newIngredientItem.isEdit ? newIngredientItem.unitId : newIngredientItem.unit.id,
          unit_name: newIngredientItem.isEdit ? newIngredientItem.unit : newIngredientItem.unit.name,
          amount: handleGetAmount(index),
          discount_percent: newIngredientItem.discount_percent !== "" ? parseFloat(newIngredientItem.discount_percent) : 0,
          discount: handleDiscountItem(index),
          tax_percent: newIngredientItem.tax_percent !== "" ?  parseFloat(newIngredientItem.tax_percent) : 0,
          tax: handleTaxItem(index),
          total: handleGetSubTotal(index),
          base_unit: newIngredientItem.base_unit,
          base_qty: calBaseQty(index),
          conversion_qty: parseFloat(newIngredientItem.conversion_qty),
          base_price: calBasePrice(index)
        });
      }
      });

    let formData = {
      invoice_number: newItem.invoice,
      purchase_id:params.id,
      branch_id: newItem.branch.id,
      supplier_id: newItem.supplier.id,      
      purchase_date: date,
      desc: newItem.description,
      item: slugArray,
      payment_type: newItem.paymentType,
      total_bill: handleGetTotal(),
      total_amount: handleAmount(),
      total_tax: handleTaxAmount(),
      total_discount: handleDisAmount(),
    };

    if(newRow.length === slugArray.length){
      setLoading(true);
      const url = BASE_URL + "/settings/update-ingredient_purchase";
      
    try {
      const res = await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      getIngredientItem();
      getPurchasedItem();
      setLoading(false);
      toast.success(`${_t(t("Purchase invoice has been updated"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    history.push("/dashboard/manage/stock/purchase-invoice");
    } catch (error) {
      console.log("check error==>",error);
      setLoading(false);
      toast.error(`${_t(t("Please try again later"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }
  };

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      newItem.branch !== null &&
      newItem.supplier !== null &&
      newItem.invoice !== null &&
      newItem.paymentType !== null &&
      purchaseDate !== null
    ) {
      if(newRow.length > 0){
        ingredientItemAxios();
      }
      else{
        toast.error(`${_t(t("Please add invoices"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      toast.error(`${_t(t("Please fill all the required fields"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  return (
  <>
      <Helmet>
        <title>{_t(t("Edit Purchase"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Edit Purchase Invoice"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="col-12">
                          <div className="row">
                              <div className="form-group col-3 mt-3">
                                <div className="mb-2">
                                  <label
                                    htmlFor="invoice"
                                    className="control-label"
                                  >
                                    {_t(t("Invoice"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control table-text"
                                    id="invoice"
                                    name="invoice"
                                    onChange={handleChange}
                                    value={newItem.invoice}
                                    placeholder="Auto no"
                                    required
                                  />
                                </div>
                              </div>

                              <div className="form-group col-3 text-right mt-3">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Purchase Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    selected={purchaseDate}
                                    onChange={(date) => setPurchaseDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("Purchase date"))}
                                    shouldCloseOnSelect={false}
                                  />
                                </div>
                              </div>

                              <div className="form-group mt-3 col-6">
                                <div className="mb-2">
                                  <label
                                    htmlFor="paymentType"
                                    className="control-label"
                                  >
                                    {_t(t("Payment Type"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div
                                  className="mb-2 d-flex align-items-center"
                                  style={{ height: "2rem"}}
                                >
                                  <div className="col-2 d-flex align-items-center">
                                    <input
                                      style={{fontSize:"1.2rem"}}
                                      type="radio"
                                      className="form-check-input pointer-cursor table-text"
                                      id="cash"
                                      name="paymentType"
                                      value="cash"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      checked={
                                        newItem.paymentType === "cash" ? 1 : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2 mt-2"
                                      htmlFor="cash"
                                      style={{fontSize:"0.9rem"}}
                                    >
                                      {_t(t("Cash"))}
                                    </label>
                                  </div>
                                  <div className="col-2 d-flex align-items-center">
                                    <input
                                      style={{fontSize:"1.2rem"}}
                                      type="radio"
                                      className="form-check-input pointer-cursor table-text"
                                      id="credit"
                                      name="paymentType"
                                      value="credit"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      checked={
                                        newItem.paymentType === "credit" ? 1 : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2 mt-2"
                                      htmlFor="credit"
                                      style={{fontSize:"0.9rem"}}
                                    >
                                      {_t(t("Credit"))}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">                              
                              <div className="form-group mt-2 col-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="branch"
                                      className="control-label"
                                    >
                                      {_t(t("Branch"))}
                                      <span className="text-danger">*</span>                                      
                                    </label>
                                  </div>
                                  <Select
                                    options={branchSearch ? branchSearch : []}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    value={newItem.branch || ""}
                                    classNamePrefix="select"
                                    onChange={handleSetBranch}
                                    maxMenuHeight="200px"
                                    styles={customStyle}
                                    placeholder={
                                      _t(t("Please select a branch")) + ".."
                                    }
                                  />
                              </div>  

                              <div className="form-group mt-2 col-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="branch"
                                      className="control-label"
                                    >
                                      {_t(t("Supplier"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <Select
                                    options={supplierForSearch ? supplierForSearch : []}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    value={newItem.supplier || ""}
                                    classNamePrefix="select"
                                    onChange={handleSetSupplier}
                                    maxMenuHeight="200px"
                                    styles={customStyle}
                                    placeholder={
                                      _t(t("Please select a supplier")) + ".."
                                    }
                                  />
                              </div>   

                              <div className="form-group mt-2 col-6">
                                <div className="mb-2">
                                  <label
                                    htmlFor="description"
                                    className="control-label"
                                  >
                                    {_t(t("Description"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <textarea
                                    type="text"
                                    className="form-control table-text"
                                    id="description"
                                    name="description"
                                    onChange={handleChange}
                                    value={newItem.description}
                                    placeholder="Short description"
                                    style={{height:"33px"}}
                                  />
                                </div>
                              </div>
                            </div>                                                                            
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-Child"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Rate"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Amount"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:'130px'}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Discount Percent"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:'130px'}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Discount Amount"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Tax Percent"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Tax Amount"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Total"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base unit"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base qty"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:"9rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Conversion unit"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:"9rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base price"))}
                                          </span>
                                        </th>
                                        {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (ingredientItem, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                          onClick={()=>{delRow(ingredientItem.id)}}
                                          ></i>
                                          </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {ingredientItem.isEdit ?
                                                <input
                                                readOnly
                                                type="text"
                                                className={`form-control text-center table-Input table-text`}
                                                value={ingredientItem.item_name?.name || ""}
                                                />
                                                : 
                                              <Select
                                                options={ingredientItemForSearch !== null ? ingredientItemForSearch : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleSetItems(index,e)}
                                                maxMenuHeight="12.8rem"                                                
                                                value={ingredientItem.item_name}
                                                styles={customStyle}
                                                isMenuOpen={true}
                                                menuPlacement={index >= 2 ? "top" : "bottom"} 
                                                menuPosition="fixed"
                                                className="bank-table-input"                                              
                                                placeholder={
                                                _t(t("Please select items")) + ".."
                                                }                                                
                                              />}
                                              </td>
                                           
                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={ingredientItem.id}
                                                  name={ingredientItem.id}
                                                  onChange={(e)=>handleItemQty(index,e)}
                                                  value={                                                    
                                                    ingredientItem.qty || ""
                                                  }
                                                  placeholder="Qty"
                                                  required                                                  
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {ingredientItem.isEdit ?
                                                <input
                                                readOnly
                                                type="text"
                                                className={`form-control text-center table-Input table-text`}
                                                value={ingredientItem.unit || ""}
                                                /> 
                                                :                                                
                                                <Select
                                                  menuPosition="fixed"
                                                  menuPlacement={index >= 2 ? "top" : "bottom"} 
                                                  options={ingredientItem.item_units ? ingredientItem.item_units : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  value={ingredientItem.unit}
                                                  classNamePrefix="select"
                                                  onChange={(e)=>handleSetUnit(index,e)}
                                                  className="bank-table-input"
                                                  maxMenuHeight="200px"
                                                  styles={customStyle}                                                  
                                                  placeholder={
                                                  _t(t("please select unit")) + ".."
                                                  }            
                                                  isDisabled={ingredientItem.item_units?false:true}                                        
                                                />}
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.001"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={ingredientItem.id}
                                                  name={ingredientItem.id}
                                                  onChange={(e)=>handleItemRate(index,e)}
                                                  value={                                                  
                                                    ingredientItem.rate || ""
                                                  }
                                                  placeholder="Price"
                                                  required                                                 
                                                />
                                              </td>
                                              <td className="table-text align-middle text-center">
                                                {formatPrice(
                                                  handleGetAmount(                                                    
                                                    index
                                                  )
                                                )}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className={`form-control text-center table-Input table-text ${                                                  
                                                    ""
                                                  }`}
                                                  id={ingredientItem.id}
                                                  name={ingredientItem.id}
                                                  onChange={(e)=>handleDiscount(index,e)}
                                                  value={                                                    
                                                    ingredientItem.discount_percent || ""
                                                  }
                                                  placeholder="Discount"
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {formatPrice(
                                                  handleDiscountItem(                                                    
                                                    index
                                                  )
                                                )}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.01"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className={`form-control text-center table-Input table-text ${                                                    
                                                    ""
                                                  }`}
                                                  id={ingredientItem.id}
                                                  name={ingredientItem.id}
                                                  onChange={(e)=>handleTax(index,e)}
                                                  value={                                                    
                                                    ingredientItem.tax_percent || ""
                                                  }
                                                  placeholder="Tax"
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {formatPrice(
                                                  handleTaxItem(                                                    
                                                    index
                                                  )
                                                )}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {formatPrice(
                                                  handleGetSubTotal(                                                    
                                                    index
                                                  )
                                                )}
                                              </td>

                                              {/* hidden columns */}
                                              
                                              <td className="table-text align-middle text-center">
                                                {ingredientItem.base_unit}
                                              </td> 
                                              
                                              <td className="table-text align-middle text-center">                                                
                                                {formatPrice(calBaseQty(index))}
                                              </td>
                                              
                                              <td className="table-text align-middle text-center">
                                                {ingredientItem.conversion_qty}
                                              </td> 

                                              <td className="table-text align-middle text-center">
                                                {formatPrice(calBasePrice(index))}
                                              </td> 
                                              
                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(ingredientItem.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}
                                    {newRow && newRow.length>0 ?
                                    <tfoot className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          colSpan="1"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        ></th>

                                        <th
                                          scope="col"
                                          colSpan="3"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        ></th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                              {_t(t("Total"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(handleAmount())}
                                          </span>
                                        </th>
                                        <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(handleDisAmount())}
                                          </span>
                                        </th>
                                        <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(handleTaxAmount())}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>                                            
                                            {formatPrice(handleGetTotal())}
                                          </span>
                                        </th>

                                        <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>

                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      </tr>
                                    </tfoot>
                                    :null}
                                  </table>
                              </div>
                              

                            <div className="form-group mt-4 pb-2">
                              {/* <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5 rounded"
                                  type="submit"                                  
                                >
                                  {_t(t("Save"))}
                                </button>
                                <button
                                  className="btn btn-primary px-5 mx-2 rounded"
                                  onClick={() =>
                                    history.push(
                                      "/dashboard/manage/stock/purchase-invoice"
                                    )
                                  }
                                >
                                  {_t(t("Back"))}
                                </button>
                              </div> */}
                              <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/purchase-invoice"))}
                                //   data-dismiss="modal"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default IngredientPurchaseEdit;
