import React, {
  useContext,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import Moment from "react-moment";
import QRCode from "react-qr-code";
import {
  _t,
  formatPrice,
  getSystemSettings,
  vatPercent,
} from "../../../../../functions/Functions";
//importing context consumer here
import { UserContext } from "../../../../../contexts/User";
import { SettingsContext } from "../../../../../contexts/Settings";
import { FoodContext } from "../../../../../contexts/Food";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

//after order submit or settle
//All Receipt Control from this handle

export const OrderPrint = ({
  newOrder,
  theVat,
  theVatSettle,
  totalSettle,
  newSettings,
  selectedPropertyGroup,
  cartTotal,
  returnMoneyUsd,
  paidMoney,
  orderDetails,
  srbInvoiceNum,
  fbrInvoiceNum,
  serviceCharges,
  deliveryCharges,
  isSettle,
  taxType,
  selectedDeliveryType,
  selectedParty,
  selectedDeptTag,
  foodGroupsToPrint,
  cartSubTotal,
  showPriceOfEachOrderItemPrint,
  customerPrint,
  component2Ref,
  con_Kot,
}) => {
  const {
    generalSettings,
  } = useContext(SettingsContext);
  const {
    authUserInfo,
   
  } = useContext(UserContext);

  const {
    //food

    propertyGroupForSearch,
  } = useContext(FoodContext);

  function bracketReplace(id) {
    let property_group_id = id.replace(/[\[\]']+/g, "");
    return property_group_id;
  }
  const { order_id } = useParams();

  let history = useHistory();

  const { t } = useTranslation();

   // show property price
   const showPropertyPrice=(qty,propQty,price)=>{
    let propPrice = 0;
    if (taxType === "inclusive") {
      propPrice = qty* propQty* parseFloat(price);
      let finalPrice = propPrice / (1 + orderDetails.branch?.branch_tax / 100);
      return formatPrice(finalPrice);
    }else{
      propPrice = qty*propQty*parseFloat(price);
      return formatPrice(propPrice);
    }
  }

 //item price for print
 const getPrintPrice = (item, variation = null) => {
  let price = variation
    ? parseFloat(variation.food_with_variation_price)
    : parseInt(item.item.has_variation) === 1
    ? parseFloat(item.variation.food_with_variation_price) * item.quantity
    : parseFloat(item.item.price) * item.quantity;
  if (taxType === "inclusive") {
    return (price / (1 + orderDetails.branch?.branch_tax / 100)).toFixed(2);
  } else {
    return price.toFixed(2);
  }
};

let printText = "";
let taxTaken = "";
let taxImage = "";
const qrPrint = (taxTaken,printText,InvoiceNum,taxImage) =>{

  return(
    <div className="mb-1">
      <p className="print-text fk-print-text text-capitalize text-center">
        {taxTaken} Invoice Number {InvoiceNum}
      </p>
      <div className="mb-1">
        <div className="float-left">
          <img
            src={taxImage}
            height="80"
            alt={`${taxTaken} Invoice`}
          />
        </div>
        <div className="float-right">
          {InvoiceNum && (
            <QRCode value={InvoiceNum} size="80" />
          )}
        </div>
        <div style={{ clear: "both" }}></div>
        </div>
        <p className="print-text fk-print-text text-center">
          {printText} <br></br> and win exciting prizes in
          draw.
        </p>
    </div>
  )
}

  return (
    <>
      {/* Print bill */}
      <div className="d-none">
        <div ref={customerPrint}>
          {newOrder && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {
                      parseInt(getSystemSettings(generalSettings, "print_logo")) === 1 && (
                        <div className="mx-auto" style={{ width: "120px" }}>
                          <img
                            src={`${getSystemSettings(
                              generalSettings,
                              "type_logo"
                            )}`}
                            style={{ width: "100%"}}
                            //height={100}
                          />
                        </div>
                      ) 
                    }
                    {
                       parseInt(getSystemSettings(generalSettings, "print_siteName")) === 1 && (
                         <span className="mt-1 d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mt-2">
                           {getSystemSettings(generalSettings, "siteName")}
                          
                         </span>
                       ) 
                    }
                    {
                      parseInt(getSystemSettings(generalSettings, "print_branchName")) === 1 && (
                        <span className="mt-1 d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mt-2">
                          {orderDetails &&
                            orderDetails.branch !== null &&
                            orderDetails.branch.name}
                        </span>
                      ) 
                     }
                    {
                      parseInt(getSystemSettings(generalSettings, "print_branchAddress")) === 1 && (
                        <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                          {orderDetails &&
                            orderDetails.branch !== null &&
                            orderDetails.branch.address}
                        </p>
                      )
                    }
                    {
                      parseInt(getSystemSettings(generalSettings, "print_branchPhone")) === 1 &&(
                        <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                          {_t(t("call"))}:{" "}
                          {orderDetails &&
                          orderDetails.branch != null &&
                          orderDetails.branch.phn_no
                            ? orderDetails.branch.phn_no
                            : ""}
                        </p>
                      )
                    }
                    {orderDetails && orderDetails.table !== null && orderDetails.table?.length > 0 ? (
                      <p className="mb-0 sm-text fk-print-text text-capitalize text-center">
                        {_t(t("Table # "))}: {orderDetails?.table_name}
                      </p>
                    ) : (
                      ""
                     )
                    }
                    {
                      parseInt(getSystemSettings(generalSettings, "print_heading")) === 1 && (
                        <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                          {getSystemSettings(generalSettings, "type_print_heading")}
                        </p>
                      )
                    }
                    {
                      !navigator.onLine && (
                        <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                          Offline
                        </p>
                      )
                    }

                   <div className = "d-flex align-items-center justify-content-between myBorderTopCustomer"> 
                      <span className="fk-print-text text-uppercase print-text pt-1 mr-1">
                        {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "}
                        {orderDetails?.token && orderDetails.token.id}
                      </span>

                      {
                        parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
                          <span className="fk-print-text text-uppercase text-right print-text pt-1 ml-1">
                            Invoice No - {" "}
                            {orderDetails?.order_No && orderDetails.order_No}
                          </span>
                        )
                      }
                   </div> 

                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "both" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                          {orderDetails &&
                            selectedDeliveryType &&
                            selectedDeliveryType.name + "-" + selectedParty?.name}
                        </p>
                      )
                    }
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "order_type" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                          {orderDetails &&
                            selectedDeliveryType &&
                            selectedDeliveryType.name}
                        </p>
                      )
                    }
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "party" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                          {orderDetails &&
                            selectedParty &&
                            selectedParty?.name}
                        </p>
                      )
                    }
                    <p className="mb-0 mt-0 print-text fk-print-text text-capitalize text-center">
                      {_t(t("Customer Copy"))}
                    </p>
                    {/* <p className="mb-0 print-text fk-print-text text-capitalize">
                      {_t(t("Vat reg"))}: {_t(t("Applied"))}
                    </p> */}
                    <div className="myBorder mb-2"></div>

                   
                      {
                        (orderDetails.newCustomerInfo?.name || orderDetails.customer?.name) &&(
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("Customer Name"))}:{" "}
                          {orderDetails && orderDetails.customer === null
                            ? orderDetails.newCustomerInfo?.name
                            : orderDetails.customer?.name}
                        </p>
                        )
                      }
                       {
                        (orderDetails.newCustomerInfo?.number || orderDetails.customer?.phn_no || orderDetails.customer?.number) && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Phone #"))}:{" "}
                            {orderDetails && orderDetails.customer === null
                              ? orderDetails.newCustomerInfo?.number
                              : (orderDetails.customer?.phn_no || orderDetails.customer?.number)}
                          </p>
                        )
                       }
                       {
                        (orderDetails.newCustomerInfo?.email || orderDetails.customer?.email) && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("Email"))}:{" "}
                          {orderDetails && orderDetails.customer === null
                            ? orderDetails.newCustomerInfo?.email
                            : orderDetails.customer?.email}
                         </p>
                        )
                       }
                       {
                        (orderDetails.newCustomerInfo?.address || orderDetails.customer?.address) && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Address "))}:{" "}
                            {orderDetails && orderDetails.customer === null
                              ? orderDetails.newCustomerInfo?.address
                              : orderDetails.customer?.address}
                          </p>
                        )
                       }
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          { "ref no : " +
                              (orderDetails.newCustomerInfo && 
                                orderDetails.newCustomerInfo.ref_no
                                ? orderDetails.newCustomerInfo.ref_no
                                : orderDetails.customer?.ref_no ? orderDetails.customer?.ref_no : "")}
                        </p>
                    
                
                    <p className="mb-0 md-text fk-print-text text-capitalize">
                      {getSystemSettings(
                        generalSettings,
                        "pre_settled_billed_heading"
                      )}
                    </p>

                    <p className="mb-0 print-text fk-print-text text-capitalize">
                      {_t(t("date"))}: <Moment format="LL">{new Date()}</Moment>
                      {", "}
                      {orderDetails.token && (
                        <Moment format="LT">{orderDetails.token?.time}</Moment>
                      )}
                    </p>
                    
                    {orderDetails && orderDetails.waiter !== null ? (
                      <p className="mb-0 print-text fk-print-text text-capitalize">
                      {_t(t("waiter name"))}: {orderDetails.waiter.name}
                      </p>
                      ) : (
                        ""
                    )}

                    {
                      selectedDeliveryType?.name === "Dine-In" && (

                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("guests"))}:{" "}
                          {orderDetails && orderDetails.total_guest}
                        </p>
                      )
                    }

                    {parseInt(getSystemSettings(generalSettings, "print_paymentType")) === 1 && (
                      isSettle === 1 ? (
                        <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                          {orderDetails?.payment_type &&
                            orderDetails.payment_type[0].name}{" "}
                          - PAID
                        </p>
                      ) : (
                        <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                          Un PAID
                        </p>
                      )
                    )}
                    <table className="table mb-0 table-borderless akash-table-for-print-padding">
                      <thead>
                        <tr>
                          <th
                          scope="col"
                          className="fk-print-text print-text text-capitalize "
                          >
                          {/*<div className="d-flex flex-wrap align-items-center">
                           <span style={{width:"10.5%"}} className="d-inline-block">
                            {_t(t("qty"))}
                            </span>
                            <span style={{width:"82%"}} className="d-inline-block">
                            {_t(t("item"))}
                             </span>
                            </div>*/}
                            {_t(t("qty"))}  {_t(t("item"))}
                          </th>
                          <th
                          scope="col"
                          className="fk-print-text print-text text-capitalize text-right"
                          >      
                          {_t(t("T"))}.{_t(t("price"))} 
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {newOrder.map((printItem, printItemIndex) => {
                          console.log(
                            "printItem ",
                            printItem,
                            " PropertyItemIndex : ",
                            printItemIndex,
                            " Selected Property Group ",
                            selectedPropertyGroup[printItemIndex]
                          );
                          return (
                            <>
                            <tr>
                                <td className="fk-print-text print-text text-capitalize">
                                <div className="d-flex flex-wrap align-items-center">
                                  <span style={{width:"9%"}} className="d-inline-block">
                                  {printItem.quantity}{" "}
                                  </span>
                                  <span style={{width:"82%"}} className="d-inline-block ml-2">
                                    {printItem.item.name}
                                    {parseInt(printItem.item.has_variation) ===
                                      1 &&
                                      printItem.variation &&
                                      "(" +
                                      printItem.variation.variation_name +
                                      ")"}
                                      </span>
                                  </div>   
                                </td>
                                <td className="fk-print-text print-text text-capitalize text-right">
                                    
                                    {
                                      getPrintPrice(printItem)
                                    }
                                </td>
                              </tr>
                            
                                {/* properties */}
                                {printItem.properties &&
                                  printItem.properties.length > 0 &&
                                  
                                    printItem.properties.map(
                                    (propertyName,thisIsGroupPaddingTopIndex) => {
                                      
                                      return (
                                      <tr>
                                        <td className="fk-print-text print-text text-capitalize ">
                                          <span className="text-capitalize print-text d-inline-block " style={{marginLeft:"13%"}}>
                                            -{printItem.quantity}
                                            {propertyName.quantity > 1
                                              ? "*" +
                                                propertyName.quantity
                                              : ""}
                                            {" " +propertyName.item.name}
                                            </span>
                                        </td>
                                        <td className="fk-print-text print-text text-capitalize text-right">
                                            {showPropertyPrice(
                                                printItem.quantity,
                                                propertyName.quantity,
                                                propertyName.item
                                                  .extra_price
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    
                                    }
                                  )}
                                  
                                 </>
                              );
                        })}
                      </tbody>
                    </table>
                    <div className="myBorder mb-1"></div>
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            <span className="d-block p-0 m-0">{_t(t("total"))}</span>
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(cartSubTotal)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {
                      isSettle === 0 ?
                       theVat > 0 && (
                        <table className="table mb-0 table-borderless">
                          <tbody>
                            {getSystemSettings(generalSettings, "vat_system") ===
                            "igst" ? (
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text text-uppercase">
                                   {
                                    orderDetails.branch?.print_tax
                                   }
                                    ({newSettings !== null ? parseInt(newSettings.vat) : orderDetails.branch.branch_tax}
                                    %)
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(theVat)}
                                </td>
                              </tr>
                            ) : getSystemSettings(
                                generalSettings,
                                "vat_system"
                              ) === "cgst" ? (
                              <>
                                <tr>
                                  <th className="py-0 pb-1 fk-print-text print-text">
                                    <span className="d-block print-text">
                                      CGST(
                                      {getSystemSettings(generalSettings, "cgst")}
                                      %)
                                    </span>
                                  </th>
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {formatPrice(
                                      cartSubTotal *
                                        (parseFloat(
                                          getSystemSettings(
                                            generalSettings,
                                            "cgst"
                                          )
                                        ) /
                                          100)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="py-0 pb-1 fk-print-text print-text">
                                    <span className="d-block print-text">
                                      SGST(
                                      {getSystemSettings(generalSettings, "sgst")}
                                      %)
                                    </span>
                                  </th>
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {formatPrice(
                                      cartSubTotal *
                                        (parseFloat(
                                          getSystemSettings(
                                            generalSettings,
                                            "sgst"
                                          )
                                        ) /
                                          100)
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text text-uppercase">
                                  { orderDetails.branch?.print_tax }
                                    ({newSettings !== null ? newSettings.tax : orderDetails.branch.branch_tax}
                                    %)
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(theVat)}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )
                      :
                       theVatSettle > 0 && (
                        <table className="table mb-0 table-borderless">
                          <tbody>
                            {getSystemSettings(generalSettings, "vat_system") ===
                            "igst" ? (
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text text-uppercase">
                                  {
                                     orderDetails.branch?.print_tax 
                                   }{theVatSettle > 0  && `(${vatPercent(cartSubTotal,theVatSettle)}%)`}
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(theVatSettle)}
                                </td>
                              </tr>
                            ) : getSystemSettings(
                                generalSettings,
                                "vat_system"
                              ) === "cgst" ? (
                              <>
                                <tr>
                                  <th className="py-0 pb-1 fk-print-text print-text">
                                    <span className="d-block print-text">
                                      CGST(
                                      {getSystemSettings(generalSettings, "cgst")}
                                      %)
                                    </span>
                                  </th>
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {formatPrice(
                                      cartSubTotal *
                                        (parseFloat(
                                          getSystemSettings(
                                            generalSettings,
                                            "cgst"
                                          )
                                        ) /
                                          100)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="py-0 pb-1 fk-print-text print-text">
                                    <span className="d-block print-text">
                                      SGST(
                                      {getSystemSettings(generalSettings, "sgst")}
                                      %)
                                    </span>
                                  </th>
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {formatPrice(
                                      cartSubTotal *
                                        (parseFloat(
                                          getSystemSettings(
                                            generalSettings,
                                            "sgst"
                                          )
                                        ) /
                                          100)
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text text-uppercase">
                                  {
                                    orderDetails.branch?.print_tax 
                                  }
                                    ({newSettings !== null ? newSettings.tax : orderDetails.payment_tax[orderDetails.payment_type[0].name]}
                                    %)
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(theVatSettle)}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )
                    }

                      <>
                        {deliveryCharges > 0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("D.Charge"))}
                                  </span>
                                </th>

                                {orderDetails && (
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {formatPrice(deliveryCharges)}
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        )}

                        {serviceCharges > 0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("S.Charge"))}
                                    {orderDetails &&
                                      "(" + orderDetails.branch.service_fees + "%)"}
                                  </span>
                                </th>

                                {orderDetails && (
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {formatPrice(
                                      serviceCharges
                                    )}
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        )
                      }
                        {orderDetails.scheme && parseFloat(orderDetails.scheme.discount_amount) > 0 ? (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("discount "))}{orderDetails?.scheme && orderDetails.scheme.discount_type == "percentage" && `(${orderDetails.discount}%)`}
                                  </span>
                                </th>
                                {orderDetails && (
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {
                                      orderDetails.scheme &&
                                       formatPrice(
                                        parseFloat(orderDetails.scheme.discount_amount)
                                        )
                                    }

                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        ):
                        <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("discount "))}{orderDetails?.scheme && orderDetails.scheme.discount_type == "percentage" && `(${orderDetails.discount}%)`}
                                  </span>
                                </th>
                                {orderDetails && (
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {
                                      orderDetails.scheme &&
                                      formatPrice(orderDetails.scheme.discount_amount)
                                    }

                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                      }
                      </>
                      
                     
                    <div className="myBorder mb-1"></div>
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        {(orderDetails?.branch?.fbr_integration === 1 || orderDetails?.branch?.pbr_integration === 1 || orderDetails?.branch?.sbr_integration === 1) && (fbrInvoiceNum || srbInvoiceNum)&&
                          paidMoney > 0 && (
                            <tr>
                              <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                <span className="d-block">
                                  {_t(t("POS Invoice Fee"))}
                                </span>
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                               {_t(t("1.00"))}
                              </td>
                            </tr>
                          )}
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            <span className="d-block">
                              {_t(t("grand total"))}
                            </span>
                          </th>
                          <td className=" py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {
                            isSettle === 0 ?  
                            paidMoney > 0 && (orderDetails?.branch?.fbr_integration || orderDetails?.branch?.pbr_integration === 1 || orderDetails?.branch?.sbr_integration) && 
                            (fbrInvoiceNum || srbInvoiceNum)
                              ? formatPrice(cartTotal + 1)
                              :
                              formatPrice(cartTotal)
                            :
                           
                               formatPrice(totalSettle)

                            }
                          </td>
                        </tr>
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            <span className="d-block">
                              {_t(t("Paid Amount"))}
                            </span>
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(paidMoney)}
                          </td>
                        </tr>
                        <tr>
                          <th className="py-0 fk-print-text print-text text-capitalize">
                            <span className="d-block p-0 m-0">
                              {_t(t("Return Amount"))}
                            </span>
                          </th>
                          <td className="py-0 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(returnMoneyUsd)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    { (orderDetails?.branch?.fbr_integration === 1 || orderDetails?.branch?.pbr_integration === 1) &&
                      (fbrInvoiceNum !== "" && fbrInvoiceNum !== null) &&
                      paidMoney > 0 && (
                         printText = `Verify this invoice through ${orderDetails.branch?.fbr_integration ? "FBR" : "PRA"} TaxAsaan MobileApp or SMS at 9966`,
                         taxTaken = orderDetails.branch?.fbr_integration ? "FBR" : "PBR",
                         taxImage = orderDetails.branch?.fbr_integration ? "/assets/img/pos-invoice-system.png" : "/assets/img/PRA.png",
                         qrPrint(taxTaken,printText,fbrInvoiceNum,taxImage)                       
                      )}
                      {
                        (orderDetails?.branch?.fbr_integration === 1 || orderDetails?.branch?.pbr_integration === 1) &&
                         <div className="myBorder"></div>
                      }
                      
                    {
                      //orderDetails?.branch?.sbr_integration === 1 &&
                      (srbInvoiceNum !== "" && srbInvoiceNum !== null) &&
                      paidMoney > 0 && (
                         printText = "Verify this invoice through eSRB MobileApp or SMS at 9697",
                         taxTaken = "SBR",
                         taxImage = "/assets/img/srb_pos_image.png",
                         qrPrint(taxTaken,printText,srbInvoiceNum,taxImage)    
                        
                      )}

                    <div className="myBorder mt-1"></div>
                    {orderDetails?.note_to_rider && orderDetails?.note_to_rider !== "" && 
                      <p className="mb-1 print-text fk-print-text text-capitalize mx-1">
                      {"Rider Note : " +
                          (orderDetails.note_to_rider && 
                            orderDetails.note_to_rider !== "" &&
                            orderDetails.note_to_rider
                        )}
                      </p>    
                     }
                    <p style={{ whiteSpace: "pre-line",lineHeight:"0.8rem" }} className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {getSystemSettings(generalSettings, "type_print_footer")}
                    </p>
                    <p className="mb-0 sm-text fk-print-text fk-print-text--bold text-capitalize text-center">
                      {_t(t("bill prepared by"))}:{" "}
                      {authUserInfo &&
                        authUserInfo.details &&
                        authUserInfo.details.name}
                    </p>
                    <p className="mb-0 sm-text fk-print-text fk-print-text--bold text-capitalize text-center">
                      {_t(t("powered by indolj"))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Print bill kitchen */}
      <div className="d-none">
        <div ref={component2Ref}>
          {/* {newOrder && ( */}
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12 mt-3">
                  <span className="d-block fk-print-text fk-print-text--bold text-uppercase text-center mt-3 lg-text">
                    {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "}
                    {orderDetails?.token && orderDetails.token.id}
                  </span>
                  {
                    parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
                      <span className="d-block fk-print-text fk-print-text--bold text-uppercase text-center mt-1 lg-text">
                        Invoice No - {" "}
                        {orderDetails?.order_No && orderDetails.order_No}
                      </span>
                    )
                  }
                  <p className="mb-0 fk-print-text text-capitalize lg-text fk-print-text--bold">
                    {orderDetails && selectedDeptTag && selectedDeptTag.name}
                  </p>
                  <p className="mb-0 mt-0 fk-print-text fk-print-text--bold text-capitalize text-center">
                    {_t(t("kitchen orders"))}
                    {foodGroupsToPrint &&
                    Object.values(foodGroupsToPrint)?.length &&
                    Object.values(foodGroupsToPrint)[0][0] &&
                    Object.values(foodGroupsToPrint)[0][0].item?.kot &&
                    Object.values(foodGroupsToPrint)[0][0].item.kot !== "No" ? (
                      <span>
                        {" "}
                        (
                        {_t(t(Object.values(foodGroupsToPrint)[0][0].item.kot))}
                        )
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="mb-0 mt-0 fk-print-text text-capitalize text-center">
                    {orderDetails &&
                      selectedDeliveryType &&
                      selectedParty?.name +
                        " - ref no : " +
                        ( orderDetails.newCustomerInfo &&
                          orderDetails.newCustomerInfo.ref_no
                          ? orderDetails.newCustomerInfo.ref_no
                          : orderDetails.customer?.ref_no ? orderDetails.customer?.ref_no : "")}
                  </p>
                  {orderDetails && orderDetails.table !== null && orderDetails.table?.length > 0 ? (
                    <p className="mb-0 mt-0 fk-print-text text-capitalize text-center">
                      {_t(t("Table # "))}: {orderDetails?.table_name}
                    </p>
                  ) : (
                    ""
                  )}

                  {con_Kot?.current && con_Kot?.current !== "" &&
                    <p className="mb-0 mt-0 fk-print-text fk-print-text--bold text-capitalize text-center border border-dark">
                      {con_Kot.current}
                    </p>
                    }

                  <table className="table mb-0 table-borderless">
                    <tbody>
                      <tr className="myBorderPrintBottom">
                      <th
                        className="fk-print-text text-capitalize"
                        >
                        <div className="d-flex flex-wrap align-items-center">
                          <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-20" >
                          {_t(t("Qty"))}
                          </span>
                    
                          <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-80" >
                          {_t(t("Item"))}
                              </span> 
                            </div>
                          </th>
                        </tr>
                      {foodGroupsToPrint &&
                        Object.keys(foodGroupsToPrint)?.length &&
                        Object.keys(foodGroupsToPrint).map(
                          (theGrp, grpIndex) => {
                            return (
                              <>
                                <tr>
                                  <td className="text-center">
                                    <div
                                      className={`${
                                        grpIndex === 0
                                          ? "myBorder"
                                          : "myBorderBottom"
                                      } lg-text fk-print-text--bold fk-print-text`}
                                    >
                                      {theGrp}
                                    </div>
                                  </td>
                                </tr>
                            
                                {foodGroupsToPrint[theGrp].map(
                                  (printItem, printItemIndex) => {
                                    return (
                                      <> 
                                      
                                        <tr className="">
                                          <th className="fk-print-text text-capitalize">
                                            {order_id ? (
                                              <div className="d-block w-100 p-0 m-0">
                                                <span className="d-inline-block lg-text fk-print-text--bold fk-print-text--bold-800 fk-print-text pl-1">
                                                  {printItem.reason  ||
                                                    printItem.status
                                                    ? "CANCELLED"
                                                    : "ADDED"}
                                                  {":"}
                                                </span>
                                                {printItem.reason ? (
                                                  <span className="d-inline-block lg-text fk-print-text--bold fk-print-text pl-1">
                                                    {" "}
                                                    (Reason: {printItem.reason})
                                                  </span>
                                                ) : null}
                                              </div>
                                            ) : null}
                                          { printItem.item.is_special != "1" || !printItem.hasOwnProperty('property') ? (
                                            <>
                                            <div className="d-flex flex-wrap align-items-center">
                                              <span className="print_w-20 ml-1 pl-1">
                                               {printItem.quantity}
                                              </span>
                                              <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-80 p-0 m-0">
                                                {printItem.item.name}{" "}
                                                {/* {printItem.item.kot} */}
                                                {parseInt(
                                                  printItem.item.has_variation
                                                ) === 1 &&
                                                  printItem.variation && (
                                                    <span className="d-inline-block lg-text fk-print-text--bold fk-print-text mr-1" >
                                                      (
                                                      {
                                                        printItem.variation
                                                          .variation_name
                                                      }
                                                      )
                                                    </span>
                                                  )}
                                                  <span className="d-inline-block lg-text fk-print-text--bold fk-print-text pl-1 ml-2" >
                                                  {printItem.comments ? `(${printItem.comments})` : ""}
                                                  </span>
                                              </span>
                                              
                                            </div>
                                           
                                            {printItem.item.item_description && parseInt(getSystemSettings(generalSettings, "order_no")) === 0 ? (
                                              <div className="d-flex flex-wrap align-items-center">
                                              <span className="print_w-20"></span>
                                                <span  style={{ whiteSpace: "pre-line" }} className="d-inline-block lg-text ml-1 fk-print-text--bold fk-print-text print_w-80">
                                                  {
                                                    printItem.item
                                                      .item_description
                                                  }
                                                </span>
                                              </div>
                                            ) : null}
                                            {/* properties */}
                                            {
                                              printItem.properties &&
                                              printItem.properties.length >
                                                0 ? (
                                                <div className="pl-2 d-flex flex-wrap align-items-center">
                                                  {printItem.item.property_groups.map(
                                                    (
                                                      propertyGrpName,
                                                    ) => {
                                                        let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return propertyGrpName === (theItem?.id) ;
                                                          }
                                                        );

                                                        let propertWithGroup = []
                                                        printItem.properties.forEach(prop => {
                                                          const propertyGroupIds = JSON.parse(prop.item.property_group_id);
                                                          if(propertyGroupIds.includes(propertyGrpName)){
                                                            propertWithGroup.push(prop);
                                                          }
                                                        });
                                                       
                                                      return (
                                                        <>
                                                         {
                                                          //IsGroup && IsGroup?.length > 0 && IsGroup[0] === propertyGrpName ?
                                                          propertWithGroup && propertWithGroup?.length > 0 && theGroup &&
                                                          <>
                                                           <span className="print_w-20"></span>
                                                           <span className=" d-inline-block mr-1 fk-print-text lg-text fk-print-text--bold print_w-80">
                                                            {theGroup &&
                                                              theGroup.name + " :"}
                                                            
                                                            </span>
                                                          </>
                                                            
                                                         }
                                                         {propertWithGroup && propertWithGroup?.map(
                                                           (
                                                             propertyName,
                                                             propertyIndex
                                                             ) => {
                                                               
                                                               if (
                                                                 printItem.properties &&
                                                                 parseInt(
                                                                   bracketReplace(
                                                                     propertyName
                                                                     .item
                                                                     .property_group_id
                                                                     )
                                                                     ) ===
                                                                     theGroup?.id
                                                                     ) {
                                                                       return(
                                                                         
                                                                   <>
                                                                    <span className="print_w-20"></span>
                                                                    <span className="text-capitalize d-inline-block mr-1 fk-print-text lg-text fk-print-text--bold print_w-80" >
                                                                    <span>
                                                                    ({printItem.quantity >
                                                                        1 ?
                                                                        printItem.quantity * propertyName.quantity
                                                                        : propertyName.quantity
                                                                          }){" "}
                                                                    </span>
                                                                    {
                                                                      propertyName.item
                                                                        .name
                                                                    }{" "}
                                                                    {
                                                                      propertyIndex == propertWithGroup?.length - 1
                                                                       ? "." : ","}
                                                                    </span>
                                                                   </>
                                                                  )
                                                                  
                                                                }else {
                                                              return true;
                                                            }
                                                           })}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                  </div>
                                              ) : (
                                                ""
                                              )
                                            }
                                           </>
                                            ) :
                                            (
                                             <div>                                              
                                              <div className="d-flex flex-wrap align-items-center">
                                               <span className="print_w-20 ml-1 pl-1">
                                                {printItem.quantity * printItem.property.quantity}
                                                </span>
                                                 <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-80 p-0 m-0">
                                                   {printItem.property.item.name}{" "}
                                                 
                                                  <span className="d-inline-block lg-text fk-print-text--bold fk-print-text pl-1 ml-2" >
                                                   {printItem.comments ? `(${printItem.comments})` : ""}
                                                  </span>
                                                 </span>
                                               </div>
                                              </div> 
                                             )
                                           }
                                          </th>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                                {/* )} */}
                              </>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                  <div className="myBorderBottom"></div>
                  <div className="">
                    <p className="mb-0 print-text fk-print-text text-capitalize lg-text fk-print-text--bold">
                      {_t(t("date"))}: <Moment format="LL">{new Date()}</Moment>
                    </p>
                    <p className="mb-0 print-text fk-print-text text-capitalize lg-text fk-print-text--bold">
                      {_t(t("Time"))}:
                      {orderDetails?.token && (
                        <Moment format="LT">{orderDetails.token.time}</Moment>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}
