import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FoodContext } from "../../../../../../contexts/Food";
import { getParsedProperties } from "../helpers";
import "./itemModal.css";
import PropertyGroup from "./PropertyGroup";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { validationGroup } from "../helpers";

const ItemModal = ({
  closeModal,
  showModal,
  itemId,
  itemData,
  addToCart,
  buttonDisabled,
  menuWisePrice,
  menuId
}) => {
  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };
  console.log(itemData);
  // context
  const { propertyGroupForSearch: propertyGroups } = useContext(FoodContext);

  // states
  const [properties, setProperties] = useState();
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(null);
  const [variationsSelected, setVariationsSelected] = useState(false);
  const [pickedProperties, setPickedProperties] = useState({});
  const [pickedVariation, setPickedVariation] = useState({});
  //validation
  const [required, setRequired] = useState(false);
  const [tempQty, setTempQty] = useState(0);
  const [selectGroup, setSelectGroup] = useState({});

  // useeffects
  useEffect(() => {
    // reset modal state
    setProperties(null);
    setSelectedPropertyGroup(null);
    setVariationsSelected(false);
    setPickedProperties({});
    setPickedVariation({});
    if (!itemData) return;
    if (itemData.item.has_variation === "1") {
      setVariationsSelected(true);
      if (itemData.hasOwnProperty("variation")) {
        setPickedVariation({
          [itemData.variation.food_with_variation_id]: itemData.variation
        });
      } else {
        itemData.item.variations && itemData.item.variations[0] && setPickedVariation({
          [itemData.item.variations[0].food_with_variation_id]:
            itemData.item.variations[0],
        });
      }
    }

    if (itemData.item.has_property === "1" && itemData.item.properties && itemData.item.properties.length) {
      const parsedProperties = getParsedProperties(itemData, propertyGroups, menuWisePrice, menuId);
      setProperties(parsedProperties);
      if (itemData.item.has_variation !== "1") {
        setSelectedPropertyGroup(itemData.item?.property_groups[0]);
      }
      if (
        itemData.hasOwnProperty("properties") &&
        itemData.properties
      ) {
        setPickedProperties(itemData.properties);
      }
    }
  }, [itemData, showModal, propertyGroups]);

  // helpers
  const handleSelectVariations = () => {
    setSelectedPropertyGroup(null);
    setVariationsSelected(true);
  };

  const handleSelectPropertyGroup = (id) => {
    if (!required) {
      setSelectedPropertyGroup(id);
      setVariationsSelected(false);
      setTempQty(0);
    } else {
      toast.error("You have to pick one this is required!");
    }
  };
  const handleSelectSubItem = (item, qty=null) => {
    if (variationsSelected) {
      setPickedVariation({ [item.food_with_variation_id]: item });
    } else {
      let newData;
      if (pickedProperties[selectedPropertyGroup]) {
        if (
          (pickedProperties[selectedPropertyGroup][item.id] && !qty)
        ) {
          newData = { ...pickedProperties };
          delete newData[selectedPropertyGroup][item.id];
          if (!Object.keys(newData[selectedPropertyGroup]).length)
            delete newData[selectedPropertyGroup];
        } else {
          newData = {
            ...pickedProperties,
            [selectedPropertyGroup]: {
              ...pickedProperties[selectedPropertyGroup],
              [item.id]: {
                item,
                quantity: qty ? qty : 1,
              },
            },
          };
        }
      } else {
        newData = {
          ...pickedProperties,
          [selectedPropertyGroup]: {
            [item.id]: {
              item,
              quantity: qty ? qty : 1,
            },
          },
        };
      }
      setPickedProperties(newData);
    }
  };

  useEffect(() => {
    // validation item properties
    const item = itemData?.item.property_groups_validation;
    const selectGrop = selectedPropertyGroup && item[selectedPropertyGroup];
    const itemGroup = pickedProperties && pickedProperties[selectedPropertyGroup];
    
    setSelectGroup(selectGrop);
    validationGroup(
      tempQty,
      setTempQty,
      selectGrop,
      itemGroup,
      setRequired,
    );
  }, [pickedProperties, tempQty, selectedPropertyGroup]);

  useEffect(()=>{
    // auto select 1 propety item if has only 1 property
    if(properties && properties[selectedPropertyGroup] && properties[selectedPropertyGroup].properties?.length === 1 && !pickedProperties[selectedPropertyGroup]){
      let item = properties[selectedPropertyGroup].properties[0];
      const newData = {
        ...pickedProperties,
        [selectedPropertyGroup]: {
          [item.id]: {
            item,
            quantity: 1,
          },
        },
      };
      setPickedProperties(newData);
    }
  },[properties,selectedPropertyGroup])

  const handleSubmit = () => {
    const data = {
      ...itemData,
      item: {
        ...itemData.item,
        price:
        itemData.item.has_variation === "1"
          ? Object.values(pickedVariation)[0].food_with_variation_price
          : itemData.item.price,
      },
      variation:
        itemData.item.has_variation === "1" ? Object.values(pickedVariation)[0] : null,
      properties:
        itemData.item.has_property === "1" &&
        Object.keys(pickedProperties).length ?
        pickedProperties : [],
    };
    const edit = Boolean(
      itemData.properties || itemData.variation
    );
    let checkValid;
    let groupName = "";
    const piked = data.item.property_groups_validation;
    const isValid = [];
    // check property if required
    for (const key in piked) {
      // const picked = [];
      const items = piked[key];
      if (items.required === 1) {
        // let qty = 0;
        const picked = pickedProperties?.[key] ? Object.values(pickedProperties?.[key]) : [];
        const qty = picked.reduce((acc, itm) => acc + (itm?.quantity || 1), 0);
        
        if(items.multi_select === "multiple"){
          if(items.limit > 0 && qty !== items.limit){
            groupName += `You have to pick atleast ${items.limit} item from ${properties && properties[key]?.details.name}.\n`;
            checkValid = false;
            isValid.push(parseInt(key));
          }else if(items.limit == 0 && qty <= 0){
            groupName += `You have to pick atleast one item from ${properties && properties[key]?.details.name}.\n`;
            checkValid = false;
            isValid.push(parseInt(key));
          }
        }else{
          if(qty <= 0){
            groupName += `You have to pick atleast one item from ${properties && properties[key]?.details.name}.\n`;
            checkValid = false;
            isValid.push(parseInt(key));
          }
        }
      }
    }
    if(checkValid === false || (groupName?.length > 0 && isValid?.length > 0)){
      if(
        //Object.keys(pickedProperties)?.length && 
        (data.item?.property_groups_validation !== null || data.item?.property_groups_validation !== undefined)){
        let item = null;
        const itemGrp = data?.item?.property_groups_validation;
        const propertyGrp = data?.item?.property_groups;
        for(const group of propertyGrp){
          const validGrp = itemGrp[group];
          if(isValid.includes(group) && validGrp.required == 1){
            item = parseInt(group);
            break
          }
        }
        setSelectedPropertyGroup(item ? item : propertyGrp && propertyGrp[0]);
        setVariationsSelected(false);
      }
      toast.error(groupName, { whiteSpace: "pre-line" });

    }else{
      !buttonDisabled && addToCart(itemId, data, edit, properties);
      setTempQty(0);
      setSelectGroup({});
      setPickedProperties({});

    }
  
    //closeModal();
  };

  if (showModal && itemData)
    return (
      <>
        <div className="d-flex align-items-center justify-content-center"></div>
        <Modal size="xl" show={showModal} onHide={() => closeModal()}>
          {" "}
          {/* modal dialog modal-lg */}
          <Modal.Body className="p-0 pt-0">
            <div className="row m-0">
             {
              itemData?.item &&(
              <div className="modal-item">
              <div className="modal-item-name">
               {itemData.item.name}
              </div>
              <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
              </div>
              )
             }
              {" "}
              {/* m-0 mh-inherit */}
              <div
                className="col-2 p-0 text-center modal-left-col" // mh-inherit
              >
                <div
                  className="list--menu-nav d-flex flex-column"
                  style={{ overflow: "auto" }}
                >
                  <ul className="list-group">
                    {/* variations */}
                    {itemData.item.has_variation === "1" &&
                     itemData.item.variations ? (
                      <li
                        className={`list-group-item modal-list-item my-1 py-2 green h6 border-0 pointer property-group ${
                          variationsSelected ? "property-group-selected" : ""
                        }`}
                        onClick={handleSelectVariations}
                      >
                        Variations
                      </li>
                    ) : null}
                    {/* properties */}
                    {itemData.item.has_property === "1" &&
                    properties &&
                    Object.keys(properties).length
                      ? itemData.item.property_groups
                      //Object.keys(properties)
                      // .sort((a, b) =>
                      //  // properties[a].details.sorting - properties[b].details.sorting 
                      //   //? 1 : -1
                      //  )
                      .map((propertyGroupId) => (
                        properties[propertyGroupId]?.details && (
                          <li
                          className={`list-group-item my-1 py-3 green h6 border-0 pointer property-group
                          ${
                            selectedPropertyGroup === propertyGroupId
                            ? "property-group-selected"
                            : ""
                          }`}
                          key={propertyGroupId}
                          onClick={() =>
                            handleSelectPropertyGroup(propertyGroupId)
                          }
                          >
                            {properties[propertyGroupId]?.details?.name}
                          </li>
                          )
                        ))
                      : null}
                  </ul>
                </div>
              </div>
              <div className="col-10 modal-right-col">
                <div>
                  {selectedPropertyGroup ? (
                    <PropertyGroup
                      name={properties[selectedPropertyGroup].details.name}
                      items={properties[selectedPropertyGroup].properties}
                      menuId={menuId}
                      nameKey="name"
                      priceKey="extra_price"
                      menuWisePrice = {menuWisePrice}
                      idKey="id"
                      setOnClick={handleSelectSubItem}
                      selected={
                        pickedProperties &&
                        pickedProperties[selectedPropertyGroup]
                      }
                      showCounter={true}
                      tempQty={tempQty}
                      setTempQty={setTempQty}
                      selectGroup={selectGroup}
                      isProperty
                    />
                  ) : variationsSelected ? (
                    <PropertyGroup
                      name="Variations"
                      items={itemData.item.variations}
                      menuId={menuId}
                      menuWisePrice = {0}
                      nameKey="variation_name"
                      priceKey="food_with_variation_price"
                      idKey="food_with_variation_id"
                      setOnClick={handleSelectSubItem}
                      selected={pickedVariation && pickedVariation}
                      showCounter={false}
                      tempQty={tempQty}
                      setTempQty={setTempQty}
                      selectGroup={selectGroup}
                    />
                  ) : null}
                  <div className="row mx-2">

                    <div className="mb-3 row justify-content-end">
                      <div className="col-sm-3 modal-btn-2">
                        <button
                          type="button"
                          className="my-3 btn pos--btn btn-submit btn-lg btn-block bg-success"
                          onClick={handleSubmit}
                        >
                          Add To Cart
                        </button>
                      </div>
                      <div className="col-sm-3 pl-0 modal-btn">
                        <button
                          type="button"
                          className="m-2 my-3 btn pos--btn btn-cancel btn-lg btn-block"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  else return null;
};

export default ItemModal;
