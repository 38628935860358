import React, { useEffect, useContext, useState } from "react";


//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";
import "./stock.css"
import moment from "moment";

const StockReport = ({
 name,
 stockPrint,
 newItem,
//  qtyOfItem,
//  rateOfItem,
 handleGetTotal,
 handleAmount,
 handleTaxAmount,
 handleDisAmount,
 handleGetAmount,
 purchaseDate,
 newRow,
 invoiceNo,
 inoviceDate
}) => {
    const { t } = useTranslation();
    const {
        //common
        generalSettings,
      } = useContext(SettingsContext);
      // console.log("check supp in stock==>",newItem.supplier)
  return (
    
    <>
       {/* print */}
       <div className="d-none">
       <div ref={stockPrint}>
         {newItem && (
           <div className="fk-print" style={{margin:"20px 30px"}}>
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   <div className="mx-auto" style={{ width: "90px" }}>
                     <img
                       src={`${getSystemSettings(
                         generalSettings,
                         "type_logo"
                       )}`}
                       style={{ width: "100%" }}
                     />
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {newItem.branch && newItem.branch?.name}
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {name}
                   </div>

                   <div className="mt-4 my-2 d-flex print-text-stock justify-content-between text-capitalize">
                     <div className="print-text-stock--bold">{invoiceNo}: {newItem?.invoice}</div>
                     <div className="print-text-stock--bold">{inoviceDate} : {purchaseDate && purchaseDate?.toDateString()}</div>
                   </div>
                   
                   {newItem.supplier ? 
                   <div className="mb-2 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">
                    <div className="print-text-stock--bold">
                     Supplier Name :{" "}
                     {newItem.supplier && newItem.supplier?.name}
                     </div>
                   </div>:
                    null}

                  {newItem.description ? 
                   <div className="mb-4 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">
                    <div className="print-text-stock--bold">
                     Description :{" "}
                     {newItem.description && newItem.description}
                     </div>
                     {/* <div className="print-text-stock--bold">
                      Issue Date :{" "}
                     {new Date().toDateString()}
                    </div> */}
                   </div>:
                    null}

                  {newItem.remarks ? 
                   <div className="mb-4 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">
                    <div className="print-text-stock--bold">
                     Remarks :{" "}
                     {newItem.remarks && newItem.remarks}
                     </div>
                   </div>:
                    null}
                   
                   {newRow !== null && [
                     newRow.length > 0 && (
                       <div className=" mt-3">
                         <table className="table table-bordered-sm table-hover ">
                           <thead className="align-middle">
                             <tr>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border table-Child"
                                 style={{minWidth:"180px"}}
                               >
                                 {_t(t("Name"))}
                               </th>
                               {

                               }
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Qty"))}
                               </th>

                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Unit"))}
                               </th>

                               {name === "Stock Transfer" ?
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("From branch"))}
                               </th>:null}
                              
                               {name === "Stock Transfer" ?
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("To branch"))}
                               </th>:null}

                               {name === "Stock Transfer" ? null :
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                 style={{minWidth:"150px"}}
                               >
                                 {_t(t("Rate"))}
                               </th>}

                                {handleGetAmount ? 
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                               {_t(t("Amount"))}
                               </th>:
                               null}
                               
                             </tr>
                           </thead>

                           <tbody className="align-middle">
                             {newRow?.map((ingredientItem, index) => {
                               return (
                                 <tr className="align-middle" key={index}>
                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {ingredientItem.ingredient_name}
                                   </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {/* {qtyOfItem &&
                                     qtyOfItem[ingredientItem?.id]
                                       ? qtyOfItem[ingredientItem?.id]
                                       : ""} */}
                                       {ingredientItem.qty}
                                   </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {ingredientItem.unit_name}
                                   </td>

                                   {ingredientItem.rate && name !== "Stock Transfer" ? 
                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                     
                                       {ingredientItem.rate}
                                   </td> : null }

                                   {ingredientItem.to_branch ?
                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                    
                                      {newItem.branch.name}
                                   </td>:null}

                                   {ingredientItem.to_branch ?
                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                    
                                      {ingredientItem.to_branch}
                                   </td>:null}

                                   {handleGetAmount ? 
                                   <td className="print-text-stock border-dark border-1 align-middle text-center">
                                     {formatPrice(
                                       handleGetAmount(index)
                                     )}
                                   </td>: null}
                    
                                 </tr>
                               );
                             })}
                           </tbody>
                           
                           <tbody className="align-middle">
                           {typeof(handleAmount) == "function" || newItem?.total_amount && (
                             <tr>
                               <th
                                 scope="col"
                                 colSpan="3"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               ></th>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Net Amount"))}
                               </th>
                      
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {newItem && newItem?.total_amount ?
                                 parseFloat(newItem?.total_amount).toFixed(2):
                                 formatPrice(handleAmount())
                                 }
                               </th>
                               </tr>
                               )}
                               {typeof(handleDisAmount) == "function" || newItem?.total_discount && (
                                 <>
                                 <tr>
                                 <th
                                 scope="col"
                                 colSpan="3"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               ></th>
                                <th
                                scope="col"
                                className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {_t(t("Discount amount"))}
                              </th>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {newItem && newItem?.total_discount ?
                                 parseFloat(newItem?.total_discount).toFixed(2): 
                                 formatPrice(handleDisAmount()) }
                               </th>
                               </tr>
                               </>
                               )}
                               {typeof(handleTaxAmount) == "function" || newItem?.total_tax && (
                                 <>
                                 <tr>
                                 <th
                                 scope="col"
                                 colSpan="3"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               ></th>
                                <th
                                scope="col"
                                className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {_t(t("Tax amount"))}
                              </th>
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {newItem && newItem?.total_tax ?
                                 parseFloat(newItem?.total_tax).toFixed(2):
                                formatPrice(handleTaxAmount())}
                              </th>
                              </tr>
                              </>
                              )}
                              {typeof(handleGetTotal) == "function" || newItem?.total_bill && (
                               <tr>
                               <th
                               scope="col"
                               colSpan="3"
                               className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                             ></th>
                               <th
                               scope="col"
                               className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                             >
                               {_t(t("Total amount"))}
                             </th>

                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {newItem && newItem?.total_bill ?
                                 newItem?.total_bill:
                                 formatPrice(handleGetTotal())}
                               </th>
                             </tr>)}
                           </tbody>
                         </table>
                       </div>
                     ),
                   ]}
                 </div>
               </div>
             </div>
           </div>
         )}
       </div>
     </div>
    </>
  )
}

export default StockReport
