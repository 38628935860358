import React, { useState, useContext, useEffect,useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import Switch from "react-switch";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import {
  BASE_URL,
  SAAS_APPLICATION,
  saas_apiUrl,
  saas_apiParams,
  saas_form_data,
} from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";

import Select from "react-select";
const BranchAction = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);

    let {adminStaffForSearch} = useContext(UserContext)
  
    let {
      //branch
      getBranchList,
      setGetBranchList,
      setPaginatedBranch,
      getBranchForSearch,
      setGetBranchforSearch,
      paymentTypeForSearch,
      paymentTypeTaxForSearch,
      //pagination
      dataPaginating,
    } = useContext(RestaurantContext);
  
  
    // States hook here
    //new group
    let [newBranch, setNewBranch] = useState({
      name: "",
      phn_no: "",
      address: "",
      delivery_time: "0",
      branch_tax: "0",
      consolidated_ip:"",
      consolidated_kot:false,
      auto_accept: false,
      paymentType : false,
      edit: false,
      editSlug: null,
      fbrIntegration: false,
      fbrPosRegistrationNo: "",
      fbrPosToken: "",
      fbrPctCode: "",
      sbrIntegration: false,
      pbrIntegration: false,
      sbrPaymentType: [],
      print_tax:"",
      sbrNtn: "",
      sbrPos: "",
      sbrUserId: "",
      sbrUserPassword: "",
      uploading: false,
      paymentTypeTax: {
        paymentType: "",
        tax: 0,
      },
      status: true,
      customer_counter_ip: "",
      kitchen_printer_ip: "",
      service_fees_person: "0",
      service_fees: "0",
    });
    let [sbrPaymentType, setSbrPaymentType] = useState([]);
  
    //search result
    let [searchedBranch, setSearchedBranch] = useState({
      list: null,
      searched: false,
    });
    // branch limit check
    let [checkBranchLimitState, setCheckBranchLimitState] = useState("");
    let [paymentTypeTax, setPaymentTypeTax] = useState([]);
       //new Row
   const [newRow,setNewRow]=useState([]);
   let rowId= useRef(0);

   const addNewRow =()=> {
     let newId = rowId.current
     // if(newOpeningStock.branch !== null){
       setNewRow((pre)=>[...pre,{
         id: newId,
         user : null,
         printer_ip: ""
       }])
       rowId.current += 1;
   }

   const delRow=(id)=>{
    let row=[...newRow]
    row= row.filter((item,i)=>id!==item.id);
    setNewRow(row);
  }

  //set each branch user wise 
  const handleSetUser = (index,user) =>{
    newRow[index].user = user;
    setNewRow([...newRow]);
  }
   //set each branch ip
  const handlePrinterIp = (index,e) => {
    newRow[index].printer_ip = e.target.value;
    setNewRow([...newRow]);
  };
  
    // check branch limit
    const checkBranchLimit = () => {
      // check how many orders are left
      const url = saas_apiUrl + "/api/user-branch-limit-check?" + saas_apiParams;
      axios
        .get(url)
        .then((res) => {
          setCheckBranchLimitState(res.data);
        })
        .catch(() => {
          return "NO data";
        });
    };
  
    //useEffect == componentDidMount
    useEffect(() => {
      console.log("payments", paymentTypeForSearch);
      // check expiry
      if (SAAS_APPLICATION == "YES") {
        (async () => {
          const saasBranchToken = setInterval(checkBranchLimit, 7200000);
          checkBranchLimit();
  
          return () => {
            clearInterval(saasBranchToken);
          };
        })();
      }
      // if(params.id && adminStaffForSearch){
      //   handleSetEdit(params.id);
      // }
    }, []);

    useEffect(()=>{
      if(params.id && adminStaffForSearch){
        handleSetEdit(params.id);
      }
    },[adminStaffForSearch])
  
    //set Fbr Integration
    const handleFbrIntegration = () => {
      setNewBranch({
        ...newBranch,
        fbrIntegration: !newBranch.fbrIntegration,
        sbrIntegration: false,
        pbrIntegration: false,
      });
    };
  
    //set Fbr Integration
    const handleSbrIntegration = () => {
      setNewBranch({
        ...newBranch,
        pbrIntegration: false,
        fbrIntegration: false,
        sbrIntegration: !newBranch.sbrIntegration,
      });
    };
  
    //set Pbr Integration
    const handlePbrIntegration = () =>{
      setNewBranch({
        ...newBranch,
        fbrIntegration: false,
        sbrIntegration: false,
        pbrIntegration: !newBranch.pbrIntegration,
      });
    }
  
     // on change send req
     const handleChangeStatus = (newChecked) => {
      setNewBranch({
        ...newBranch, 
        status: !newBranch.status,
      })
    };
  
      // on change send req
      const handleChecked = (newChecked) => {
        setNewBranch({
          ...newBranch, 
          auto_accept: !newBranch.auto_accept,
        })
      };
  
    const handleKotCheck = () => {
      setNewBranch({
        ...newBranch, 
        consolidated_kot: !newBranch.consolidated_kot,
      })
    };
  
    //set each variation price
    const handlePaymentTypeTax = (e) => {
      setPaymentTypeTax({
        ...paymentTypeTax,
        [e.target.name]: e.target.value,
      });
  
      console.log("check 2", paymentTypeTax);
    };
  
    //set name, phn no, address hook
    const   handleSetNewBranch = (e) => {
      setNewBranch({ ...newBranch, [e.target.name]: e.target.value });
    };
  
    const handleSetPaymentType = (paymentTypes) => {
      console.log("paymentTypes", paymentTypes);
      // setNewBranch({
      //   ...newBranch,
      //   sbrPaymentType: [paymentTypes],
      // });
      setSbrPaymentType(paymentTypes);
    };
   
    //Save New branch
    const handleSaveNewBranch = (e) => {
        console.log("bn", newBranch, paymentTypeTax);
        e.preventDefault();
        let deliveryIp = {}
        newRow?.length && newRow.forEach((ip)=>{
          if(ip.user !== null && ip.user !== "" && typeof(ip.user) == "object"){
            deliveryIp = {
              ...deliveryIp,
              [ip.user.id]: ip.printer_ip,
            }
          } 
        })
        if(Object.keys(deliveryIp)?.length !== newRow?.length){
          toast.error(`${_t(t("Select User/Can't select user multiple time"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          return false
        }
        setNewBranch({
          ...newBranch,
          uploading: true,
        });
        const branchUrl = BASE_URL + `/settings/new-branch`;
        let formData = new FormData();
        formData.append("name", newBranch.name);
        formData.append("phn_no", newBranch.phn_no);
        formData.append("address", newBranch.address);
        formData.append("delivery_time", newBranch.delivery_time);
        formData.append("branch_tax", newBranch.branch_tax);
        formData.append("fbr_integration", newBranch.fbrIntegration);
        formData.append("pbr_integration", newBranch.pbrIntegration);
        formData.append("fbr_pos_registration_no", newBranch.fbrPosRegistrationNo);
        formData.append("fbr_pos_token", newBranch.fbrPosToken);
        formData.append("fbr_pct_code", newBranch.fbrPctCode);
        formData.append("sbr_integration", newBranch.sbrIntegration);
        formData.append("status", newBranch.status);
        //   formData.append("sbr_payment_type", newBranch.sbrPaymentType);
        formData.append("sbr_ntn", newBranch.sbrNtn);
        formData.append("sbr_pos_id", newBranch.sbrPosId);
        formData.append("sbr_user_id", newBranch.sbrUserId);
        formData.append("sbr_user_password", newBranch.sbrUserPassword);
        formData.append("customer_counter_ip", newBranch.customer_counter_ip);
        formData.append("kitchen_printer_ip", JSON.stringify(deliveryIp));
        formData.append("service_fees_person", newBranch.service_fees_person);
        formData.append("service_fees", newBranch.service_fees);
        formData.append("print_tax", newBranch.print_tax);
        formData.append("auto_accept", newBranch.auto_accept ? 1 : 0);
        formData.append("consolidated_kot", newBranch.consolidated_kot ? 1 : 0); 
        formData.append("consolidated_ip", newBranch.consolidated_ip);  
    
        let tempArray = [];
        sbrPaymentType.map((Item) => {
          tempArray.push(Item.id);
        });
        formData.append("payment_type_id", tempArray);
    
        let tempData = Object.entries(paymentTypeTax);
        tempData.map((item) => {
          formData.append("payment_type_tax[]", item);
        });
        console.log("formdata ", formData);
    
        return axios
          .post(branchUrl, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            if (SAAS_APPLICATION == "YES") {
              // after send api req decremeny by one
              const url = saas_apiUrl + "/api/user-branch-limit-decrement"; // replace with base url (prince.thetestserver.xyz)
              axios
                .post(url, saas_form_data)
                .then((res) => {
                  console.log(res);
                })
                .catch(() => {
                  return "NO data";
                });
            }
            setNewBranch({
              name: "",
              phn_no: "",
              address: "",
              delivery_time: "0",
              branch_tax: "0",
              consolidated_ip:"",
              auto_accept: false,
              consolidated_kot: false,
              paymentType : false,
              status: true,
              edit: false,
              fbrIntegration: false,
              pbrIntegration:false,
              fbrPosRegistrationNo: "",
              fbrPosToken: "",
              fbrPctCode: "",
              sbrIntegration: false,
              sbrPaymentType: [],
              sbrNtn: "",
              sbrPosId: "",
              sbrUserId: "",
              sbrUserPassword: "",
              editSlug: null,
              uploading: false,
              kitchen_printer_ip: "",
              service_fees_person: "0",
              customer_counter_ip: "",
              service_fees: "0",
              print_tax:"",
            });
            setNewRow([]);
            setGetBranchList(res.data[0]);
            setGetBranchforSearch(res.data[1]);
            setSearchedBranch({
              ...searchedBranch,
              list: res.data[1],
            });
            setLoading(false);
            toast.success(`${_t(t("Branch has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            history.push("/dashboard/manage/restaurant/branches");     
          })
          .catch((error) => {
            console.log("check error==>",error)
            setLoading(false);
            setNewBranch({
              ...newBranch,
              uploading: false,
            });
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          });
    };
  
    //set edit true & values
    const handleSetEdit = (slug) => {
      let branch = getBranchForSearch && getBranchForSearch.filter((item) => {
        return item.slug === slug;
      });
  
      console.log("branch data ", branch);
  
      let selectOptionsPayment = [];
      const paymentArray = JSON.parse(branch[0].payment_type_id);
      console.log("paymentArray ", paymentArray);
      console.log("check paymentypeforsearch==>",paymentTypeForSearch)
      if (paymentArray != null) {
        if (paymentArray.length !== 0) {
          for (var property of paymentArray) {
            for (var i = 0; i < paymentTypeForSearch.length; i++) {
              if (paymentTypeForSearch[i].id == property) {
                selectOptionsPayment.push(paymentTypeForSearch[i]);
              }
            }
            setSbrPaymentType(selectOptionsPayment);
          }
        } else {
          setSbrPaymentType([]);
        }
      }

      let deliveryIp = JSON.parse(branch[0].kitchen_printer_ip);
      deliveryIp && adminStaffForSearch && Object.entries(deliveryIp).forEach((key)=>{
        let user = adminStaffForSearch.find((users)=> users.id == key[0])
        let newId = rowId.current
        setNewRow((pre)=>
         [...pre,{
          id: newId,
          printer_ip: key[1],
          user: user,
        }])
        rowId.current += 1;
      })
      // let abc = paymentTypeTaxForSearch.filter((item) => {
      //   if (item.branch_id == branch[0].id) {
      //     let newobj = {
      //       [item.payment_type]: item.tax,
      //     };
      //     let newarr = [];
      //     console.log("newobjj", newobj);
      //     console.log("newobjj", newarr.push(newobj));
      //     return newobj;
      //   }
      // });
  
      // console.log("abc", abc);
      // // setPaymentTypeTax(abc);
      // setTimeout(() => {
      //   console.log("check", abc, paymentTypeTax);
      // }, 5000);
  
      // let tempPaymentAmount1 = [];
      // paymentTypeTaxForSearch.filter((item) => {
      //   if (item.branch_id == branch[0].id) {
      //     let tempPaymentAmount = {
      //       [item.payment_type]: item.tax,
      //     };
      //     tempPaymentAmount1.push(tempPaymentAmount);
      //     setPaymentTypeTax(tempPaymentAmount);
      //     console.log("check", tempPaymentAmount);
      //   }
      // });
  
      // // setPaymentTypeTax({
      // //   ...paymentTypeTax,
      // //   tempPaymentAmount1,
      // // });
      // console.log("check", tempPaymentAmount1, paymentTypeTax);
      console.log("branch[0]", branch[0]);
      setNewBranch({
        ...newBranch,
        name: branch[0].name,
        phn_no: branch[0].phn_no,
        address: branch[0].address,
        delivery_time: branch[0].delivery_time,
        branch_tax: branch[0].branch_tax,
        auto_accept: JSON.parse(branch[0].auto_accept),
        consolidated_kot: JSON.parse(branch[0].consolidated_kot),
        consolidated_ip: branch[0].consolidated_ip,
        paymentType : false,
        editSlug: branch[0].slug,
        status: JSON.parse(branch[0].status),
        fbrIntegration: branch[0].fbr_integration === 1 ? true : false,
        pbrIntegration: branch[0].pbr_integration === 1 ? true : false,
        fbrPosRegistrationNo: branch[0].fbr_pos_registration_no,
        fbrPosToken: branch[0].fbr_pos_token,
        fbrPctCode: branch[0].fbr_pct_code,
        payment_tax: branch[0].payment_tax,
        sbrIntegration: branch[0].sbr_integration === 1 ? true : false,
        sbrPaymentType: branch[0].sbr_payment_type,
        sbrNtn: branch[0].sbr_ntn,
        sbrPosId: branch[0].sbr_pos_id,
        sbrUserId: branch[0].sbr_user_id,
        sbrUserPassword: branch[0].sbr_user_password,
        customer_counter_ip: branch[0].customer_counter_ip,
        kitchen_printer_ip: branch[0]?.kitchen_printer_ip,
        service_fees_person: branch[0].service_fees_person,
        service_fees: branch[0].service_fees,
        print_tax: branch[0].print_tax,
        edit: true,
      });
  
      setPaymentTypeTax(branch[0].payment_tax);
    };
  
    //update branch
    const handleUpdateBranch = (e) => {
      e.preventDefault();
      let deliveryIp = {}
        newRow?.length && newRow.forEach((ip)=>{
          if(ip.user !== null && ip.user !== "" && typeof(ip.user) == "object"){
            deliveryIp = {
              ...deliveryIp,
              [ip.user.id]: ip.printer_ip,
            }
          } 
        })
        if(Object.keys(deliveryIp)?.length !== newRow?.length){
          toast.error(`${_t(t("Select User/Can't select user multiple time"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          return false
        }
        setNewBranch({
          ...newBranch,
          uploading: true,
        });
      const branchUrl = BASE_URL + `/settings/update-branch`;
      let formData = new FormData();
      formData.append("name", newBranch.name);
      formData.append("phn_no", newBranch.phn_no);
      formData.append("address", newBranch.address);
      formData.append("delivery_time", newBranch.delivery_time);
      formData.append("branch_tax", newBranch.branch_tax);
      formData.append("editSlug", newBranch.editSlug);
      formData.append("fbr_integration", newBranch.fbrIntegration);
      formData.append("pbr_integration", newBranch.pbrIntegration);
      formData.append("fbr_pos_registration_no", newBranch.fbrPosRegistrationNo);
      formData.append("fbr_pos_token", newBranch.fbrPosToken);
      formData.append("fbr_pct_code", newBranch.fbrPctCode);
      formData.append("status", newBranch.status);
      formData.append("sbr_integration", newBranch.sbrIntegration);
      //formData.append("sbr_payment_type", newBranch.sbrPaymentType);
      formData.append("sbr_ntn", newBranch.sbrNtn);
      formData.append("sbr_pos_id", newBranch.sbrPosId);
      formData.append("sbr_user_id", newBranch.sbrUserId);
      formData.append("sbr_user_password", newBranch.sbrUserPassword);
      formData.append("customer_counter_ip", newBranch.customer_counter_ip);
      formData.append("kitchen_printer_ip", JSON.stringify(deliveryIp));
      formData.append("service_fees_person", newBranch.service_fees_person);
      formData.append("service_fees", newBranch.service_fees);
      formData.append("print_tax", newBranch.print_tax);
      formData.append("auto_accept", newBranch.auto_accept ? 1 : 0);
      formData.append("consolidated_kot", newBranch.consolidated_kot ? 1 : 0);
      formData.append("consolidated_ip", newBranch.consolidated_ip);  
  
      let tempArray = [];
      sbrPaymentType.map((Item) => {
        tempArray.push(Item.id);
      });
      formData.append("payment_type_id", tempArray);
  
      let tempData = Object.entries(paymentTypeTax);
      tempData.map((item) => {
        formData.append("payment_type_tax[]", item);
      });
  
      return axios
        .post(branchUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewBranch({
            name: "",
            phn_no: "",
            address: "",
            delivery_time: "0",
            consolidated_ip:"",
            auto_accept: false,
            consolidated_kot:false,
            paymentType : false,
            edit: false,
            fbrIntegration: false,
            pbrIntegration:false,
            fbrPosRegistrationNo: "",
            fbrPosToken: "",
            fbrPctCode: "",
            status:true,
            sbrIntegration: false,
            sbrPaymentType: [],
            sbrUserId: "",
            sbrUserPassword: "",
            editSlug: null,
            uploading: false,
            print_tax:"",
          });
          setNewRow([]);
          setGetBranchList(res.data[0]);
          setGetBranchforSearch(res.data[1]);
          setSearchedBranch({
            ...searchedBranch,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Branch has been updated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });  
          history.push("/dashboard/manage/restaurant/branches");         
        })
        .catch(() => {
          setLoading(false);
          setNewBranch({
            ...newBranch,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    };
  
    return (
        <>
          <Helmet>
            <title>{_t(t("Branches"))}</title>
          </Helmet>
    
          {/* Add modal */}          
          {/* Add modal Ends*/}
    
          {/* main body */}
          <main id="main" data-simplebar>
            <div className="container-fluid">
              <div className="row t-mt-10 gx-2">
                {/* left Sidebar */}
                <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                  <ManageSidebar />
                </div>
                {/* left Sidebar ends */}
    
                {/* Rightbar contents */}
                <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                  <div className="t-bg-white">
                    <div className="fk-scroll--pos-menu" data-simplebar>
                      <div className="t-pl-15 t-pr-15">
                        {/* Loading effect */}
                        {newBranch.uploading === true || loading === true ? (
                          tableLoading()
                        ) : (
                          <div key="fragment3">
                            {/* next page data spin loading */}
                            {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                            {/* spin loading ends */}
    
                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                              <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                <ul className="t-list fk-breadcrumb">
                                  <li className="fk-breadcrumb__list">
                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {!newBranch.edit
                                    ? _t(t("Add new branch"))
                                    : _t(t("Update branch"))}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6 col-lg-7">
                                <div className="row gx-3 align-items-center">                                  
                                </div>
                              </div>
                            </div>
                            {/* form start here */}
                            <div key="fragment-branch-1">
                      <form
                        onSubmit={
                          !newBranch.edit ? handleSaveNewBranch : handleUpdateBranch
                        }
                        className="row card p-2 mx-3 mb-5 new-text-style"
                        autoComplete="off"
                      >
                        <div className="row">
                            <div className="form-group col-6 mt-3">
                                <label htmlFor="name" className="form-label">
                                    {_t(t("Name"))}{" "}
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    id="name"
                                    name="name"
                                    placeholder="e.g. Uttara Branch"
                                    value={newBranch.name || ""}
                                    required
                                    onChange={handleSetNewBranch}
                                />
                            </div>                            
                            <div className="form-group mt-3 col-6">
                                <label htmlFor="address" className="form-label">
                                    {_t(t("Address"))}
                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    id="address"
                                    name="address"
                                    placeholder="Type branch address"
                                    value={newBranch.address || ""}
                                    onChange={handleSetNewBranch}
                                />
                            </div>
                        </div>
    
                        <div className="row">
                        <div className="form-group mt-3 col-3">
                                <label htmlFor="phn_no" className="form-label">
                                    {_t(t("Phone number"))}
                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    id="phn_no"
                                    name="phn_no"
                                    placeholder="e.g. 01xxx xxx xxx"
                                    value={newBranch.phn_no || ""}
                                    onChange={handleSetNewBranch}
                                />
                            </div>
                        <div className="col-3">
                            <div className="mt-3">
                              <label htmlFor="branch_tax" className="form-label">
                                {_t(t("Branch Tax"))}
                              </label>
                              <input
                                type="number"
                                className="form-control "
                                id="branch_tax"
                                name="branch_tax"
                                maxLength={99}
                                onChange={handleSetNewBranch}
                                value={newBranch.branch_tax }
                                placeholder="e.g. branch_tax"
                                required
                              />{" "}
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mt-3">
                              <label htmlFor="delivery_time" className="form-label">
                                {_t(t("Delivery Time"))}
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="delivery_time"
                                name="delivery_time"
                                onChange={handleSetNewBranch}
                                value={newBranch.delivery_time || ""}
                                placeholder="e.g. delivery time"
                                required
                              />{" "}
                            </div>
                          </div>                          
                          <div className="col-3">
                            <div className="mt-3">
                              <label htmlFor="service_fees" className="form-label">
                                {_t(t("Service Charges"))}
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="service_fees"
                                name="service_fees"
                                onChange={handleSetNewBranch}
                                value={newBranch.service_fees || ""}
                                placeholder="e.g. 10"
                              />{" "}
                            </div>
                        </div>
                         
                        </div>
                        <div className="row">
                        <div className="col-3">
                            <div className="mt-3">
                              <label htmlFor="service_fees_person" className="form-label">
                                {_t(t("Service Charges on Person"))}
                              </label>
                              <input
                                type="number"
                                className="form-control "
                                id="service_fees_person"
                                name="service_fees_person"
                                maxLength={99}
                                onChange={handleSetNewBranch}
                                value={newBranch.service_fees_person || ""}
                                placeholder="e.g. 7"
                              />{" "}
                            </div>
                        </div> 
                        <div className="col-3">
                          <div className="mt-3">
                            <label htmlFor="branch_tax" className="form-label">
                              {_t(t("Print Tax"))}
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              id="print_tax"
                              name="print_tax"
                              maxLength={50}
                              onChange={handleSetNewBranch}
                              value={newBranch.print_tax }
                              placeholder="e.g. GST"
                              required
                            />{" "}
                          </div>
                        </div>
                        <div className="col-3">
                            <div className="mt-3">
                              <label
                                htmlFor="customer_counter_ip"
                                className="form-label"
                              >
                                {_t(t("Delivery Counter IP"))}
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="customer_counter_ip"
                                name="customer_counter_ip"
                                onChange={handleSetNewBranch}
                                value={newBranch.customer_counter_ip || ""}
                                placeholder="e.g. 123.168.0.1"
                                // required
                              />{" "}
                            </div>
                          </div>
                          {
                            /*
                          <div className="col-3">
                            <div className="mt-3">
                              <label
                                htmlFor="kitchen_printer_ip"
                                className="form-label"
                              >
                                {_t(t("Kitchen Printer IP"))}
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="kitchen_printer_ip"
                                name="kitchen_printer_ip"
                                onChange={handleSetNewBranch}
                                value={newBranch.kitchen_printer_ip || ""}
                                placeholder="e.g. 123.168.0.1"
                                // required
                              />{" "}
                            </div>
                          </div> 
                          */}                       
                        </div>
                      <div className="row">  
                        <div className="ml-0 col-8 table-responsive mt-3 pr-1" style={{maxHeight:"15rem",overflow: "auto"}}>
                          <table className="table table-bordered-sm table-hover">
                            <thead className="align-middle">
                              <tr>
                              {newRow.length == 0 ?<th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center"
                                >
                                  <span className="t-mr-10" onClick={()=>addNewRow()}>
                                    <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                  </span>
                                </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                <th className="border" style={{textAlign:"center"}}>Select User</th>
                                <th className="border" style={{textAlign:"center"}}>Customer Counter Ip</th>
                              </tr> 
                            </thead>  
                          {newRow && newRow.length>0 ?
                            <tbody className="align-middle">
                              {
                                newRow?.map(
                                (printerIp, index) => {
                                  return (
                                    <tr
                                      className="align-middle"
                                      key={index}
                                    >
                                      <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                        {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                            <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                        </span>}&nbsp;
                                        <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                        onClick={()=>{delRow(printerIp.id)}}
                                        ></i>
                                      </td>
                                      <td className="table-text text-capitalize align-middle text-center table-Child">
                                          <Select
                                          options={adminStaffForSearch !== null ? adminStaffForSearch : []}
                                          getOptionLabel={(option) => option.name}
                                          getOptionValue={(option) => option.id}
                                          classNamePrefix="select"
                                          onChange={(e)=>handleSetUser(index,e)}
                                          value={printerIp.user}
                                          styles={customStyle}
                                          isMenuOpen={true}
                                          menuPosition="fixed"
                                          placeholder={
                                          _t(t("Please select items")) + ".."
                                          }                                                
                                        />
                                      </td>
                                      <td className="table-text text-capitalize align-middle text-center table-Child">
                                        <input 
                                          type="text"
                                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                          className="form-control table-Input lg-text"
                                          id={printerIp.id}
                                          name={printerIp.id}
                                          onChange={(e)=>handlePrinterIp(index,e)}
                                          value={printerIp.printer_ip ? printerIp.printer_ip : ""}
                                          placeholder="e.g: 192.168..."
                                          required
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                            : null }
                          </table>
                         </div>  
                      </div>
                        
                        <div className="row">
                        <div className="col-3">
                          <div className="mt-3 ml-1">
                            <label htmlFor="auto_accept" className="form-label">
                                {_t(t("Auto Acceptance"))}
                            </label>
                            <br/>
                             {/*<input
                              type="checkbox"
                              className="ml-3 form-check-input pointer-cursor"
                              id="auto_accept"
                              name="auto_accept"
                              style={{marginTop:"1.35rem",marginBottom:"0"}}
                              onChange={(e) => handleChecked(e)}
                              checked={ 
                                newBranch.auto_accept == "1"
                                  ? 1
                                  : 0}
                              />{" "}*/}
                              <Switch
                              onChange={handleChecked}
                              checked={newBranch.auto_accept}
                              className="react-switch ml-1"
                              name="auto_accept"
                              height={20}
                              width={42}
                              offColor="#ee5253"                        
                            />
                          </div>
                        </div>
                          <div className="col-3">
                          <div className="mt-3">
                            <label htmlFor="consolidated_kot" className="form-label">
                              {_t(t("Consolidated Kot"))}
                            </label>
                            <br/>
                            <span>kot ip print?</span>
                            <input
                              type="checkbox"
                              className="ml-2 form-check-input"                              
                              id="consolidated_kot"
                              name="consolidated_kot"
                              onChange={handleKotCheck}
                              checked={ 
                                newBranch.consolidated_kot 
                                  ? 1
                                  : 0}
                             
                            />{" "}
                          </div>
                          </div>
                          <div className="col-3" style={{height:"5rem"}}>
                          {newBranch.consolidated_kot &&
                            <div className="mt-3 ml-1">
                              <label htmlFor="ip" className="form-label">
                                  {_t(t("Consolidated Kot ip"))}
                              </label>
                               <input
                                type="textbox"
                                className="form-control pointer-cursor "
                                id="consolidated_ip"    
                                name="consolidated_ip"
                                onChange={(e) => handleSetNewBranch(e)}
                                value={newBranch.consolidated_ip || ""}
                                placeholder="192.168..."
                                required
                                />{" "}
                            </div>
                            }
                          </div>

                          <div className="col-3">
                          <div className="mt-3">
                            <label htmlFor="paymentType" className="form-label">
                              {_t(t("Show Payment Type Tax?"))}
                            </label>
                            <br/>
                            <input
                              type="checkbox"
                              className="ml-2 form-check-input"                              
                              id="paymentType"
                              name="paymentType"
                              onChange={(e)=>{
                                setNewBranch({
                                  ...newBranch,
                                  paymentType: !newBranch.paymentType,
                                })
                              }}
                              checked={ 
                                newBranch.paymentType 
                                  ? 1
                                  : 0}
                             
                            />{" "}
                          </div>
                          </div>

                        </div>

                        {
                          newBranch.paymentType === true && (
                            <div className="row">
                              <div className="mt-3">
                                <table className="table table-bordered table-hover min-table-height">
                                  <thead className="align-middle">
                                    <tr>
                                      <th
                                        scope="col"
                                        className=" text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Payment type"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className=" text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Tax Percent"))}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                    {paymentTypeForSearch.map((item, i) => {
                                      return (
                                        <tr>
                                          <td
                                            scope="row"
                                            className="x text-capitalize align-middle text-center"
                                          >
                                            {item.name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="x text-capitalize align-middle text-center"
                                          >
                                            <input
                                              type="number"
                                              className="form-control"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }                                              
                                              id={item.name}
                                              name={item.name}
                                              onChange={handlePaymentTypeTax}
                                              placeholder="e.g. branch_tax"
                                              value={paymentTypeTax[item.name] || ""}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )
                        }

                        <div className="mt-3">
                        <Switch
                        onChange={handleChangeStatus}
                        checked={newBranch.status}
                        className="react-switch"
                        name="status"
                        height={22}
                        width={44}
                        offColor="#ee5253"                        
                      />
                      <label className="pl-2 align-top">Status</label>
                        </div>
                        <div className="mt-3 d-flex align-items-center">
                          {/* FBR Integration Checkbox */}
                          <input
                            type="checkbox"
                            className="form-check-input mr-1 mt-0"
                            id="fbrIntegration"
                            checked={newBranch.fbrIntegration}
                            onChange={handleFbrIntegration}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="fbrIntegration"
                          >
                            {_t(t("FBR Integration"))}
                          </label>
    
                          {/* SBR Integration Checkbox */}
                          <input
                            type="checkbox"
                            className="form-check-input mr-1 ml-3 mt-0"
                            id="sbrIntegration"
                            checked={newBranch.sbrIntegration}
                            onChange={handleSbrIntegration}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="sbrIntegration"
                          >
                            {_t(t("SBR Integration"))}
                          </label>
    
                            {/* PBR Integration Checkbox */}
                            <input
                            type="checkbox"
                            className="form-check-input mr-1 ml-3 mt-0"
                            id="pbrIntegration"
                            checked={newBranch.pbrIntegration}
                            onChange={handlePbrIntegration}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="pbrIntegration"
                          >
                            {_t(t("PBR Integration"))}
                          </label>
    
                        </div>
                       
                        {((newBranch.fbrIntegration === true ||
                          newBranch.fbrIntegration === 1) || (newBranch.pbrIntegration === true || newBranch.pbrIntegration === 1)) && (
                            <>
                              <div className="row">
                              <div className="mt-3 col-4 d-none">
                                <label className="form-label">
                                  {_t(t("Payment Type"))}
                                  {""}
                                  <small className="text-danger">*</small>
                                </label>
                                <Select
                                  value={sbrPaymentType}
                                  options={paymentTypeForSearch}
                                //   components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.name}
                                  className="basic-multi-select table-text"
                                  classNamePrefix="select"
                                  isMulti={true}
                                  onChange={handleSetPaymentType}
                                                                    
                                  // placeholder={_t(t("Please select "))}
                                />
                              </div>

                              <div className="mt-3 col-4">
                                <label
                                  htmlFor="fbrPosRegistrationNo"
                                  className="form-label"
                                >
                                  {_t(t("POS Registration No"))}{" "}
                                  <small className="text-danger">*</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  id="fbrPosRegistrationNo"
                                  name="fbrPosRegistrationNo"
                                  fbrPosRegistrationNo="fbrPosRegistrationNo"
                                  placeholder="e.g. 123456"
                                  value={newBranch.fbrPosRegistrationNo || ""}
                                  required
                                  onChange={handleSetNewBranch}
                                />
                              </div>
    
                              <div className="mt-3 col-4">
                                <label htmlFor="fbrPosToken" className="form-label">
                                  {_t(t("Pos Token"))}{" "}
                                  <small className="text-danger">*</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  id="fbrPosToken"
                                  name="fbrPosToken"
                                  fbrPosToken="fbrPosToken"
                                  placeholder="e.g. 6baffg110-386a-3f83-a8re-ff84f548351"
                                  value={newBranch.fbrPosToken || ""}
                                  required
                                  onChange={handleSetNewBranch}
                                />
                              </div>
    
                              <div className="mt-3 col-4">
                                <label htmlFor="fbrPctCode" className="form-label">
                                  {_t(t("PCT Code"))}{" "}
                                  <small className="text-danger">*</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  id="fbrPctCode"
                                  name="fbrPctCode"
                                  fbrPctCode="fbrPctCode"
                                  placeholder="e.g. 232329"
                                  value={newBranch.fbrPctCode || ""}
                                  required
                                  onChange={handleSetNewBranch}
                                />
                              </div>
                              </div>
                            </>
                          )}
    
                        {(newBranch.sbrIntegration === true || newBranch.sbrIntegration === 1) && (
                          <>
                           <div className="row">
                            <div className="mt-3 col-4 d-none">
                              <label className="form-label">
                                {_t(t("Payment Type"))}
                                {""}
                                <small className="text-danger">*</small>
                              </label>
                              <Select
                                value={sbrPaymentType}
                                options={paymentTypeForSearch}
                                // components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                className="basic-multi-select table-text"
                                classNamePrefix="select"
                                isMulti={true}
                                onChange={handleSetPaymentType}
                                   
                                // placeholder={_t(t("Please select "))}
                              />
                            </div>
                            <div className="mt-3 col-3">
                              <label htmlFor="sbrNtn" className="form-label">
                                {_t(t("NTN"))}{" "}
                                <small className="text-danger">*</small>
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="sbrNtn"
                                name="sbrNtn"
                                sbrUserId="sbrNtn"
                                value={newBranch.sbrNtn || ""}
                                required
                                onChange={handleSetNewBranch}
                              />
                            </div>
                            <div className="mt-3 col-3">
                              <label htmlFor="sbrPosId" className="form-label">
                                {_t(t("Pos ID"))}{" "}
                                <small className="text-danger">*</small>
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="sbrPosId"
                                name="sbrPosId"
                                sbrPosId="sbrPosId"
                                value={newBranch.sbrPosId || ""}
                                required
                                onChange={handleSetNewBranch}
                              />
                            </div>  
                            <div className="mt-3 col-3">
                              <label htmlFor="sbrUserId" className="form-label">
                                {_t(t("User Id"))}{" "}
                                <small className="text-danger">*</small>
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="sbrUserId"
                                name="sbrUserId"
                                sbrUserId="sbrUserId"
                                // placeholder="e.g. 6baffg110-386a-3f83-a8re-ff84f548351"
                                value={newBranch.sbrUserId || ""}
                                required
                                onChange={handleSetNewBranch}
                              />
                            </div>
    
                            <div className="mt-3 col-3">
                              <label
                                htmlFor="sbrUserPassword"
                                className="form-label"
                              >
                                {_t(t("Password"))}{" "}
                                <small className="text-danger">*</small>
                              </label>
                              <input
                                type="password"
                                className="form-control "
                                id="sbrUserPassword"
                                name="sbrUserPassword"
                                sbrUserPassword="sbrUserPassword"
                                // placeholder="e.g. 232329"
                                value={newBranch.sbrUserPassword || ""}
                                required
                                onChange={handleSetNewBranch}
                              />
                            </div>                          
                           </div>                           
                          </>
                        )}
    
                        <div className="row mt-4">
                          <div className="d-flex justify-content-end">
                            <div className="col-3 d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/restaurant/branches"))}
                              >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                              </button>
                              {SAAS_APPLICATION == "YES"
                                ? [
                                    newBranch.edit
                                      ? [
                                          <button
                                            type="submit"
                                            className="btn btn-primary rounded"
                                          >
                                            <i class="fa fa-floppy-o" aria-hidden="true"></i> Update
                                          </button>,
                                        ]
                                      : [
                                          checkBranchLimitState == "HAS-LIMIT" ? (
                                            <button
                                              type="submit"
                                              className="btn btn-primary rounded"
                                            >
                                              <i class="fa fa-floppy-o" aria-hidden="true"></i> {!newBranch.edit
                                                ? _t(t("Save"))
                                                : _t(t("Update"))}
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-primary rounded"
                                              onClick={() => {
                                                toast.error(
                                                  `${"Your limit has expired"}`,
                                                  {
                                                    position: "bottom-center",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    className:
                                                      "text-center toast-notification",
                                                  }
                                                );
                                              }}
                                            >
                                              {_t(t("disable"))}
                                            </button>
                                          ),
                                        ],
                                  ]
                                : [
                                    <button
                                      type="submit"
                                      className="btn btn-primary rounded"
                                    >
                                      <i class="fa fa-floppy-o" aria-hidden="true"></i> {!newBranch.edit ? _t(t("Save")) : _t(t("Update"))}
                                    </button>,
                                  ]}
                            </div>
                            {/* <div className="col-3">
                              <button
                                type="button"
                                className="btn btn-primary w-100 x text-uppercase t-width-max rounded"
                                onClick={()=>(history.push("/dashboard/manage/restaurant/branches"))}
                              >
                                {_t(t("BACK"))}
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Rightbar contents end*/}
              </div>
            </div>
          </main>
          {/* main body ends */}
        </>
      );
}

export default BranchAction;