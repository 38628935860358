import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const DiscountReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    let { branchForSearch } = useContext(RestaurantContext);
  
    //all data
    const [reportData, setReportData] = useState(null);
    const componentRef = useRef();
    const componentRefThermal = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [option,setOption] = useState("excel");

    const [branchSearch, setBranchSearch] = useState(null);
    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
          setBranchSearch(
            branchForSearch.filter(
              (branch) => branch.id === authUserInfo.details.branch_id
            )
          );
        } else {
          setBranchSearch(branchForSearch);
        }
    
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, [generalSettings]);

      const thermalPrint = useReactToPrint({
        content: () => componentRefThermal.current,
      });

      const handleBranch=(brnch)=>{
        setBranch(brnch)
      }


      const handleOption  = (e) =>{
        setOption(e.target.value)
      }
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null &&
        branch !== null
      ) {
        setDataPaginating(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-passcode-discount-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch?.id);
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("ReportResult", res);
            setGeneratedReport(true);
            setReportData(res.data)
            setDataPaginating(false);
            if(option==="excel"){
              componentRef.current.handleDownload();
            }else{
              thermalPrint();
            }
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and branch"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Discount Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Discount Report"
          sheet="sheet 1"
          buttonText="Discount Detail"
          ref={componentRef}
        />        
  
         {/* Excel Report*/}
         <div className="d-none">
         <div>           
           <div className="fk-print">
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                       <>
                         <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                           <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="6" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Discount Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                {_t(t("Manager Name"))}
                                </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Token"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("User Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Branch"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Order Type"))}
                                </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Party Name"))}
                                </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Scheme Name"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                  {_t(t("Discount Amount"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Sub Total"))}
                                </th> 
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                    {_t(t("Tax"))}
                                </th>  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total"))}
                                  </th>                                  
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* {console.log("309 ", reportData)} */}
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).length>0 && Object.keys(reportData).map(
                                    (group_name, index) => {
                                      let discount = 0;  
                                      let totalAmount = 0;                                      
                                      return(
                                      <div key={index}>
                                    
                                      <tr>
                                      <th
                                        scope="row"
                                        className="lg-text text-capitalize align-middle text-center"
                                      >
                                        {group_name}
                                      </th>
                                    </tr>
                                {reportData[group_name]?.length > 0 &&reportData[group_name].map((item, i) => {
                                  discount += parseFloat(item.discount_amount)
                                  totalAmount += parseFloat(item.sub_total);                                
                                  
                                  return (
                                    <tr>
                                    <td></td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center "
                                      >
                                        {i + 1}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.token_no}                                        
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.order_date}    
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.user_name}                                        
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.branch_name}                                        
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                     >
                                     {item.order_type}
                                     </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.party_name}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.scheme_name}
                                      </td>   
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.discount_amount}
                                    </td>                                  
                                     <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.total_payable}
                                      </td>  
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.vat}
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                      {item.sub_total}
                                      </td>
                                    </tr>
                                  );
                                })}
                                 <tr>                                  
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <th
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    Total
                                  </th>                                  
                                <td>{formatPrice(discount)}</td>
                                <td></td>
                                <td></td>
                                <td
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                    <strong>
                                        {formatPrice(totalAmount)}
                                    </strong>
                                </td>                                
                                 </tr>
                                </div>
                                );
                              }
                              )
                             }
                          </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                           </div>
                         </div>
                       </>
                     )}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       {/* print */}
       <div className="d-none">
      <div ref={componentRefThermal}>
          <div className="fk-print">
          <div className="container">
              <div className="row">
              <div className="col-12">
                <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    {siteName}
                  </span>
                  </div>
                <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                   FROM&nbsp;
                   <Moment format="LL">
                      {startDate}
                    </Moment>
                    &nbsp;TO&nbsp;
                    <Moment format="LL">{endDate}</Moment>
                  </span>
                  </div>
                  <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Print Time:{" "} 
                    <Moment format="LLL">
                      {new Date()}
                    </Moment>
                  </span>
                  </div>
                <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                  Discount Report
                </span>
            {/*catg wise report */}
            <div className="col-12">
            {
              reportData &&
              reportData !== null && (
             <div>
              <p className="mx-1 xsm-text table-text_pos text-capitalize border border-dark py-1 d-flex justify-content-between">
               <span className="font-weight-bold pl-1" style={{width:"20.5%"}}>
                {
                "Token"}
               </span>
               <span className="font-weight-bold" style={{width:"19%"}}>
                {"Branch"}
                </span>

                <span className="font-weight-bold" style={{width:"20%"}}>
                {
                  "Scheme"}
                </span>

                <span className="font-weight-bold" style={{width:"22.5%"}}>
                {
                  "Discount"}
                </span>

                <span className="font-weight-bold pr-1" style={{width:"18%",textAlign:"right"}}>
                {
                  "Amount"}
                </span>
              </p>
            </div>
            )}
          {
             reportData &&
             reportData !== null &&
              Object.keys(reportData)?.map((group)=>{
                  let menuGrpdiscount = 0;
                  let menuGroupPrice = 0;
                  return(
                  <div>
                      <div className="w-100 col-12">
                      <span className="mx-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline table-text">{group}</span>
                      </div> 
                      {
                        reportData[group]?.map((item)=>{
                          menuGrpdiscount += parseFloat(item.discount_amount)
                          menuGroupPrice += parseFloat(item.sub_total);
                          return(
                            <div className="text-capitalize w-100 d-flex justify-content-between py-1">
                              <span className="mx-1 sm-text font-weight-bold" style={{width:"20.2%"}}>{item?.token_no}</span>
                              <span className="mx-1 sm-text font-weight-bold" style={{width:"20%"}}>{item?.branch_name}</span>
                              <span className="mx-1 sm-text font-weight-bold" style={{width:"17%"}}>{item.scheme_name}</span>
                              <span className="mx-1 sm-text font-weight-bold"  style={{width:"24%"}}>{parseFloat(item.discount_amount)?.toFixed(2)}</span>
                              <span className="mx-1 sm-text font-weight-bold" style={{width:"18%",textAlign:"right"}}>{parseFloat(item.sub_total)?.toFixed(2)}</span>
                            </div>
                          )
                      })
                      }
                      <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                        <span style={{width:"58.5%"}}>Total</span>
                        <span style={{width:"23%"}}>{menuGrpdiscount?.toFixed(2)}</span>
                        <span className="float-right" style={{width:"18%",textAlign:"right"}}>{menuGroupPrice?.toFixed(2)}</span>
                      </div>
                  </div>  
                  )
              })
              }
             
           </div>
          </div> 
         </div> 
        </div> 
       </div> 
      </div> 
    </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     
                      {/* spin loading ends */}
  
                      {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}
                      
                        <div key="smtp-form">
                          <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="row t-mb-15 mb-md-0" >
                              <ul className="t-list fk-breadcrumb col-6">
                                <li className="fk-breadcrumb__list">
                                  <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Discount Report"))}
                                  </span>
                                </li>
                              </ul>
                              <div className="col-6 d-flex justify-content-end p-0">
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="excel_report"
                                  name="excel_report"
                                  value="excel"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "excel"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="excel_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                                </label>
                              </div>

                              <div className="d-inline mr-2 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="print_report"
                                  name="print_report"
                                  value="print"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "print"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="print_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-print" aria-hidden="true"></i> {_t(t("Print"))}
                                </label>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>

                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>                                                                                 
  
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={branch}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>


                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected()}
                              >
                               DISCOUNT REPORT
                              </button>
                            </div>                             
                          </div> 

                          {/* <div className="row d-flex justify-content-end mt-3">
                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected("excel")}
                              >
                               Discount REPORT
                              </button>
                            </div>
                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected("thermal")}
                              >
                               Discount PDF
                              </button>
                            </div>
                          </div>                        */}
                          
                        </div>                                          
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
  
      </>
    );
}

export default DiscountReport
